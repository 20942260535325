import { types } from "mobx-state-tree";

const Cost = types.model("Cost", {
  id: types.optional(types.number, 0),
  commission_id: types.optional(types.number, 0),
  expense_type_id: types.optional(types.number, 0),
  currency_id: types.optional(types.number, 0),
  cost: types.optional(types.string, ""),
  vat_rate_id: types.optional(types.number, 0),
  tax: types.optional(types.string, ""),
  vat: types.optional(types.string, ""),
  total: types.optional(types.string, ""),
  created_at: types.optional(types.string, ""),
  updated_at: types.optional(types.string, ""),
  expense_type: types.frozen({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, "")
  }),
  currency: types.frozen({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, "")
  }),
  vat_rate: types.frozen({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    rate: types.optional(types.string, ""),
    code: types.optional(types.string, "")
  })
});

export default Cost;
