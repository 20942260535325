import { types } from "mobx-state-tree";

const Details = types.model("Details", {
  id: types.optional(types.number, 0),
  created_at: types.optional(types.string, ""),
  entity_id: types.optional(types.number, 0),
  entity: types.optional(types.string, ""),
  agent_id: types.optional(types.number, 0),
  agent_email: types.maybeNull(types.string),
  agent_ip: types.optional(types.string, ""),
  request_url: types.optional(types.string, ""),
  old_values: types.optional(types.string, ""),
  new_values: types.optional(types.string, ""),
  reference: types.optional(types.string, ""),
  event: types.optional(types.string, ""),
  assignment_id: types.maybeNull(types.number)
});

export default Details;
