import React from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, Icon, Row } from "antd";
import moment from "moment";
import { asyncReactor } from "async-reactor";
import EditableStatus from "../../common/EditableStatus";
import { toOptionArray } from "../../helpers/array-utils";
import AccessControl from "../../models/AccessControl";
import {
  commissionLockedSet,
  noAbilityToChangeStatusSet
} from "./commissionSets";

const IsAbleToChangeCommissionStatus = asyncReactor(
  async ({ store, store: { commissionInfo, options } }) => {
    const isAbleToChangeCommissionStatus = await AccessControl.can(
      "commission:change-commission-status",
      { commissionInfo }
    );

    const canChangeCommissionStatus = () => {
      if (commissionLockedSet.has(commissionInfo.commission_status.slug)) {
        return false;
      }
      return !noAbilityToChangeStatusSet.has(
        commissionInfo.commission_status.slug
      );
    };

    if (!isAbleToChangeCommissionStatus || !canChangeCommissionStatus()) {
      return (
        <EditableStatus
          status={commissionInfo.commission_status}
          editable={false}
          context="commission"
        />
      );
    }

    return (
      <EditableStatus
        status={commissionInfo.commission_status}
        availableStatuses={toOptionArray(options.statuses)}
        editable
        context="commission"
        onChange={statusId => {
          const targetStatus = options.statuses.find(
            status => status.id === statusId
          );
          store.updateStatus(commissionInfo.id, targetStatus.id);
        }}
      />
    );
  }
);

function CommissionDetailsView({ store, store: { commissionInfo } }) {
  return (
    <Card
      className="story-panel commission-details"
      bordered={false}
      title="Commission Details"
    >
      <Row gutter={70}>
        <Col md={8} xs={24}>
          <div className="label-description-wrapper">
            <span className="label">Status</span>
            <div className="description">
              {commissionInfo.approver_user_id && !store.loadingOptions && (
                <IsAbleToChangeCommissionStatus store={store} />
              )}
            </div>
          </div>

          <div className="label-description-wrapper">
            <span className="label">Contributor Email</span>
            <div className="description">
              {commissionInfo.contributor_user.email}
            </div>
          </div>

          <div className="label-description-wrapper">
            <span className="label">Rights</span>
            <div className="description">
              <a
                href={commissionInfo.commission_right.file_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="file" className="file_icon" />
                {commissionInfo.commission_right.name}
              </a>
            </div>
          </div>
        </Col>

        <Col md={8} xs={24}>
          <div className="label-description-wrapper creator-email">
            <span className="label">Creator Email</span>
            <div className="description">
              {commissionInfo.creator_user.email}
            </div>
          </div>

          <div className="label-description-wrapper creator-email">
            <span className="label">Approver Email</span>
            <div className="description">
              {commissionInfo.approver_user.email}
            </div>
          </div>
        </Col>

        <Col md={8} xs={24}>
          <Row className="automated-story-details-row">
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Date</span>
                <div className="description">
                  {moment(commissionInfo.deadline).format("DD.MM.YYYY")}
                </div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Assignment Type</span>
                <div className="description">
                  {commissionInfo.assignment_type.name}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="automated-story-details-row">
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Commissioner</span>
                <div className="description">
                  {commissionInfo.creator_user.name}
                </div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Brand</span>
                {commissionInfo.brands &&
                  commissionInfo.brands.map(brand => {
                    return (
                      <div className="description" key={brand.name}>
                        {brand.name}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Story Name</span>
                <div className="description">{commissionInfo.story_name}</div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Category</span>
                {commissionInfo.story_category &&
                  commissionInfo.story_category.map(category => {
                    return (
                      <div className="description" key={category.name}>
                        {category.name}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>

          <Row>
            <div className="rights-document">
              <Icon type="file" />
              <a
                href={commissionInfo.terms_and_conditions?.file_url}
                target="_blank"
                className="rights-text-file-name"
                rel="noopener noreferrer"
              >
                <span>{commissionInfo.terms_and_conditions?.name}</span>
              </a>
            </div>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default inject(stores => ({
  store: stores.CommissionViewStore
}))(observer(CommissionDetailsView));
