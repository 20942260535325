import React from "react";
import { Route, Switch } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoginPage from "../LoginPage";
import ResetPasswordPage from "../ResetPasswordPage";
import ConfirmResetPasswordPage from "../ConfirmResetPasswordPage";
import Container from "../Container";
import PrivateRoute from "../../common/PrivateRoute";
import AuthorizingScreen from "../LoginPage/AuthorizingScreen";
import "./styles.less";

function App({ AuthStore: { authorizing } }) {
  if (authorizing) {
    return <AuthorizingScreen />;
  }
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/password/reset" exact component={ResetPasswordPage} />
      <Route
        path="/password/reset/:token"
        component={ConfirmResetPasswordPage}
      />
      <PrivateRoute path="/" component={Container} />
    </Switch>
  );
}

export default inject("AuthStore")(observer(App));
