import { types } from "mobx-state-tree";

const Account = types.model("Account", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  level: types.optional(types.number, 0),
  code: types.optional(types.string, "")
});

export default Account;
