import axios from "axios/index";

const LogDetailsService = {
  getActionPreviewData: id => {
    return axios
      .get(`/action-log/preview/${id}`)
      .then(({ data: { entity } }) => entity);
  }
};

export default LogDetailsService;
