import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import App from "./containers/App";
import "./config/bootstrap";
import stores from "./models";
import "./styles/index.less";
import PlatformsModal from "./containers/PlatformsModal";

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <LastLocationProvider>
        <App />
        <PlatformsModal />
      </LastLocationProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
