import { types } from "mobx-state-tree";
import Category from "./category";
import Company from "./company";
import ContentType from "./content-type";
import Platform from "./platform";
import StoryStatus from "./story-status";

const LinkedStoryContent = types
  .model("LinkedStoryContent", {
    company: types.maybeNull(Company),
    category: types.maybeNull(Category),
    category_id: types.maybeNull(types.number),
    content_type: types.maybeNull(ContentType),
    content_type_id: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    note: types.maybeNull(types.string),
    parent_id: types.maybeNull(types.number),
    platforms: types.optional(types.array(Platform), []),
    story_status: types.optional(StoryStatus, () => StoryStatus.create()),
    story_status_id: types.maybeNull(types.number),
    user_id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string)
  })
  .views(self => ({
    get listOfPlatforms() {
      return self.platforms.map(
        ({
          platform_name: { brand, platform_type },
          publication_date,
          publication_time,
          issue
        }) => {
          return [
            `${brand.name} / ${platform_type.name}`,
            issue && issue.title,
            [publication_date, publication_time].filter(Boolean).join(", ")
          ]
            .filter(Boolean)
            .join(" - ");
        }
      );
    },

    get listOfPlatformsWithCompanies() {
      return self.platforms.map(
        ({
          platform_name: { brand, platform_type },
          publication_date,
          publication_time,
          issue
        }) => {
          return [
            `${brand.name} (${self.company.name}) / ${platform_type.name}`,
            issue && issue.title,
            [publication_date, publication_time].filter(Boolean).join(", ")
          ]
            .filter(Boolean)
            .join(" - ");
        }
      );
    }
  }));

const LinkedStory = types.model("LinkedStory", {
  story_id: types.number,
  parent_id: types.number,
  story_parent: types.maybeNull(LinkedStoryContent),
  story_child: types.maybeNull(LinkedStoryContent)
});

export default LinkedStory;
