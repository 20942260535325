import { flow, types } from "mobx-state-tree";
import moment from "moment";
import Brand from "./Brand";
import BrandService from "../../services/api/dashboard";
import Status from "../common/story-status";
import ContentType from "../common/content-type";
import StoryService from "../../services/api/stories";
import Filter, { createFilter } from "../common/filter";
import { toOptionArray } from "../../helpers/array-utils";

const DashboardStore = types
  .model("DashboardStore", {
    brands: types.array(Brand),
    loading: types.boolean,
    filtersInitialValues: types.optional(types.frozen(), {}),
    filtersRequestParams: types.optional(types.frozen(), {}),
    statuses: types.array(Status),
    contentTypes: types.array(ContentType),
    filters: types.optional(types.map(Filter), {}),
    loadingFilters: types.boolean
  })
  .actions(self => {
    return {
      afterCreate() {
        this.setAppliedFilters();
      },
      loadFilters: flow(function* loadFilters() {
        try {
          const response = yield BrandService.getDataForBrandCardsFilters();
          self.loadingFilters = true;
          self.filters.set(
            "companies",
            createFilter("Company", toOptionArray(response.companies))
          );
          self.loadingFilters = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
        }
      }),
      setAppliedFilters: function setAppliedFilters() {
        const searchParams = new URLSearchParams(window.location.search);
        const filtersInitialValues = {};
        filtersInitialValues.date = [];
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          switch (key) {
            case "dateFrom":
              filtersInitialValues.date.push(moment(value));
              break;
            case "dateTo":
              filtersInitialValues.date.push(moment(value));
              break;
            default:
              if (Number(value)) {
                filtersInitialValues[key] = [Number(value)];
              } else {
                filtersInitialValues[key] = value
                  .split(",")
                  .map(val => Number(val));
              }
              break;
          }
        });
        self.filtersInitialValues = filtersInitialValues;
        const filtersRequestParams = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          filtersRequestParams[key] = value;
        });
        self.filtersRequestParams = filtersRequestParams;
      },
      loadBrands: flow(function* loadBrands(params) {
        self.loading = true;
        try {
          const response = yield BrandService.getBrands("brands", params);
          self.brands = response;
          self.loading = false;
        } catch (error) {
          self.loading = false;
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
        }
      }),
      loadStatuses: flow(function* loadStatuses() {
        try {
          const response = yield StoryService.getDataForStoryListFilters();
          self.statuses = response.statuses;
          return Promise.resolve();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("Error: ", error);
          return Promise.reject();
        }
      }),
      loadContentTypes: flow(function* loadContentTypes() {
        try {
          const response = yield StoryService.getContentTypes();
          self.contentTypes = response;
          return Promise.resolve();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("Error: ", error);
          return Promise.reject();
        }
      })
    };
  })
  .views(self => ({
    get ideaId() {
      if (self.statuses) {
        return self.statuses.find(status => status.slug === "idea")?.id;
      }
      return null;
    }
  }));

const brandStore = DashboardStore.create({
  brands: [],
  loading: false,
  filtersInitialValues: {},
  filtersRequestParams: {},
  statuses: [],
  contentTypes: [],
  filters: {},
  loadingFilters: false
});
export default brandStore;
