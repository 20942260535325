import React from "react";
import { Card, Col, Row } from "antd";
import getAddress from "../../helpers/getAddress";

function ContributorView({ commissionInfo }) {
  return (
    <Card className="contributor-panel" title="Contributor" bordered={false}>
      <Row gutter={40}>
        <Col lg={8} md={8}>
          <div>Contributor Name</div>
          <span>{commissionInfo.contributor_user.name}</span>
          <div className="bottom-info">Address</div>
          <span>{getAddress(commissionInfo.vendor?.full_address)}</span>
        </Col>
        <Col lg={16} md={16}>
          <div className="contributor-summary">Contributor Summary</div>
          <div className="summary-item">
            <div className="item-name">Vendor ID</div>
            <span className="bold">
              {commissionInfo.vendor?.vendor_id || "No info"}
            </span>
          </div>
          <div className="summary-item">
            <div className="item-name">Vendor Title</div>
            <span className="bold">
              {commissionInfo.vendor?.title || "No info"}
            </span>
          </div>
          <div className="summary-item">
            <div className="item-name">VAT Status</div>
            <span className="bold">
              {commissionInfo.vendor?.vat ? "Yes" : "No"}
            </span>
          </div>
          <div className="summary-item">
            <div className="item-name">Currency</div>
            <span className="bold">
              {commissionInfo.vendor?.currency || "No info"}
            </span>
          </div>
          <div className="summary-item">
            <div className="item-name">All Rights</div>
            <span className="bold">
              {commissionInfo.contributor_user.rights_signed
                ? "Signed"
                : "Unsigned"}
            </span>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default ContributorView;
