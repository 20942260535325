import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const iconClose = (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1108 1.1875L1.38672 21.9116"
      stroke="#FF9E9E"
      strokeWidth="2"
    />
    <path
      d="M22.1108 21.9111L1.38672 1.18706"
      stroke="#FF9E9E"
      strokeWidth="2"
    />
  </svg>
);
