import { types } from "mobx-state-tree";
import BrandAccess from "./BrandAccess";

const Details = types.model("Details", {
  [`User Type`]: types.optional(types.string, ""),
  [`Additional Permissions`]: types.maybeNull(types.array(types.string)),
  Approver: types.optional(types.boolean, true),
  [`Brand Access`]: types.optional(types.array(BrandAccess), []),
  [`Job Role`]: types.maybeNull(types.string),
  [`Job Title`]: types.maybeNull(types.optional(types.string, ""))
});

export default Details;
