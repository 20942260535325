// eslint-disable-next-line import/prefer-default-export
export const mimeExtensionMap = new Map([
  ["video/3gpp", ".3gp"],
  ["video/3gpp2", ".3g2"],
  ["application/pdf", ".pdf"],
  ["audio/x-aiff", ".aif"],
  ["video/x-msvideo", ".avi"],
  ["image/bmp", ".bmp"],
  ["image/prs.btif", ".btif"],
  ["image/vnd.dvb.subtitle", ".sub"],
  ["text/csv", ".csv"],
  ["image/cgm", ".cgm"],
  ["image/x-cmx", ".cmx"],
  ["text/vnd.curl", ".curl"],
  ["audio/vnd.dece.audio", ".uva"],
  ["application/x-dvi", ".dvi"],
  ["image/vnd.djvu", ".djvu"],
  ["video/x-flv", ".flv"],
  ["image/vnd.fpx", ".fpx"],
  ["image/vnd.net-fpx", ".npx"],
  ["image/g3fax", ".g3"],
  ["image/gif", ".gif"],
  ["text/html", ".html"],
  ["image/jpeg", ".jpeg"],
  ["image/x-citrix-jpeg", ".jpeg"],
  ["image/pjpeg", ".pjpeg"],
  ["video/jpeg", ".jpgv"],
  ["video/x-m4v", ".m4v"],
  [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".docx"
  ],
  [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    ".dotx"
  ],
  ["video/x-ms-wmv", ".wmv"],
  ["application/msword", ".doc"],
  ["application/vnd.ms-word.document.macroenabled.12", ".docm"],
  ["application/x-mswrite", ".wri"],
  ["video/mpeg", ".mpeg"],
  ["video/mp4", ".mp4"],
  ["application/mp4", ".mp4"],

  ["video/webm", ".webm"],
  ["image/vnd.adobe.photoshop", ".psd"],
  ["image/x-pict", ".pic"],
  ["image/png", ".png"],
  ["image/x-citrix-png", ".png"],
  ["image/x-png", ".png"],
  ["video/x-sgi-movie", ".movie"],
  ["text/plain", ".txt"],
  ["audio/x-wav", ".wav"]
]);
