import { types } from "mobx-state-tree";

const Settings = types.model("Settings", {
  url: types.optional(types.string, ""),
  url_authentication: types.optional(types.string, ""),
  port: types.optional(types.string, ""),
  login: types.optional(types.string, ""),
  catalog: types.optional(types.string, "")
});

export default Settings;
