import { types } from "mobx-state-tree";
import { reaction } from "mobx";
import { removeAuthHeader, setUpAuthHeader } from "../services/common";

const CommonStore = types
  .model("CommonStore", {
    token: types.string,
    tokenType: types.string
  })
  .actions(self => ({
    afterCreate() {
      reaction(
        () => self.token,
        accessToken => {
          const tokenType = self.tokenType || "Bearer";
          if (accessToken) {
            window.localStorage.setItem("jwt", accessToken);
            window.localStorage.setItem("token-type", tokenType);
            setUpAuthHeader({ accessToken, tokenType });
          } else {
            window.localStorage.removeItem("jwt");
            window.localStorage.removeItem("token-type");
            removeAuthHeader();
          }
        }
      );
      if (self.token) {
        setUpAuthHeader({ accessToken: self.token, tokenType: self.tokenType });
      }
    },

    setToken(metadata) {
      const { tokenType = "", accessToken = "" } = metadata;
      self.tokenType = tokenType;
      self.token = accessToken;
    }
  }));

const commonStore = CommonStore.create({
  token: window.localStorage.getItem("jwt") || "",
  tokenType: window.localStorage.getItem("token-type") || ""
});
export default commonStore;
