// these functions are intended to replace native antd select filtering function
import rolesTitlesArr from "../common/rolesTitles";

const filterOptions = (inputValue, option) => {
  const oneOption = option.props.children;
  let matchFound = false;
  if (oneOption.search(new RegExp(inputValue, "i")) !== -1) {
    matchFound = true;
  }
  return matchFound;
};

const filterOptionsForMultipleEntities = (inputValue, option) => {
  const oneSelectOptionArr = option.props.children;
  let matchFound = false;
  oneSelectOptionArr.forEach(opt => {
    if (opt.search(new RegExp(inputValue, "i")) !== -1) {
      matchFound = true;
    }
  });
  return matchFound;
};

const filterOptionsForMultipleEntitiesWithExceptions = (
  inputValue,
  option,
  exceptionsArray = rolesTitlesArr
) => {
  const oneSelectOptionArr = option.props.children;
  let matchFound = false;
  oneSelectOptionArr.forEach(word => {
    if (exceptionsArray.includes(word)) {
      // user shouldn't search by roles
      return;
    }
    if (word.search(new RegExp(inputValue, "i")) !== -1) {
      matchFound = true;
    }
  });
  return matchFound;
};

export {
  filterOptions,
  filterOptionsForMultipleEntities,
  filterOptionsForMultipleEntitiesWithExceptions
};
