import { applySnapshot, flow, types } from "mobx-state-tree";
import CommissionInfo from "./CommissionInfo";
import CommissionService from "../../services/api/commissions";
import MetaData from "./MetaData";
import showErrorMessage from "../../helpers/showErrorMessage";

const initialSnapshot = {
  loadingCommission: false,
  changingCommissionStatus: false,
  commissionInfo: {
    creator_user: {},
    contributor_user: {
      user_addition: {}
    },
    assignment_type: {},
    commission_status: {},
    approver_user: {},
    commission_right: {},
    costs: [],
    cost_allocations: []
  },
  options: MetaData.create()
};

const CommissionViewStore = types
  .model("CommissionViewStore", {
    loadingOptions: types.optional(types.boolean, false),
    loadingCommission: types.optional(types.boolean, false),
    changingCommissionStatus: types.optional(types.boolean, false),
    commissionInfo: types.maybe(CommissionInfo),
    options: types.maybeNull(MetaData)
  })
  .actions(self => ({
    load: flow(function* load(id) {
      self.loadingCommission = true;
      try {
        const data = yield CommissionService.getCommissionInfo(id);
        self.commissionInfo = CommissionInfo.create(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error: ", error);
        self.loadingCommission = false;
        return Promise.reject();
      }
      self.loadingCommission = false;
      return Promise.resolve();
    }),

    loadMetaData: flow(function* loadMetaData(assignmentId) {
      try {
        self.loadingOptions = true;
        const data = yield CommissionService.getMetadata(assignmentId);
        self.options = data;
        self.loadingOptions = false;
        return Promise.resolve();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error: ", error);
        self.loadingOptions = false;
        return Promise.reject();
      }
    }),

    reset() {
      applySnapshot(self, initialSnapshot);
    },

    updateStatus: flow(function* updateStatus(commissionId, statusId) {
      try {
        const status = yield CommissionService.updateStatus(
          commissionId,
          statusId
        );
        self.commissionInfo.commission_status = status;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error: ", error);
      }
    }),

    updateCommissionStatus: flow(function* updateCommissionStatus(
      commissionId,
      params
    ) {
      self.changingCommissionStatus = true;
      try {
        const commission = yield CommissionService.updateStatusForInternalUsers(
          commissionId,
          params
        );
        self.commissionInfo = commission;
      } catch (error) {
        showErrorMessage(error);
        self.changingCommissionStatus = false;
        return Promise.reject();
      }
      self.changingCommissionStatus = false;
      return Promise.resolve();
    })
  }))
  .views(self => ({
    get loading() {
      return self.loadingCommission || self.changingCommissionStatus;
    }
  }));

const commissionViewStore = CommissionViewStore.create(initialSnapshot);

export default commissionViewStore;
