import messenger from "antd/lib/message";

function showMessage({ message, status, data }) {
  if (status) {
    // eslint-disable-next-line no-unused-expressions
    data
      ? messenger.success(message || data.message || "Operation successful!")
      : messenger.success(message || "Operation successful!");
  } else {
    messenger.error(
      message || "Something went wrong! Please, try again later."
    );
  }
}

export default showMessage;
