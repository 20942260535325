import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "../../../../common/Button";
import ModalComponent from "../../../../common/Modal";

function ApproveButtonWithModal({ store, assignment }) {
  const [showModal, setShowModal] = useState(false);

  const approveButtonClickHandler = e => {
    e.stopPropagation();
    setShowModal(true);
  };

  const onApproveButtonSubmit = async e => {
    e.stopPropagation();
    setShowModal(false);
    await store.approveContent(assignment.id);
  };

  const handleCancel = e => {
    e.stopPropagation();
    setShowModal(false);
  };

  return (
    <>
      <Button
        theme="green-outline"
        size="small"
        htmlType="button"
        text="Approve Content"
        onClick={approveButtonClickHandler}
        className="assignment-button"
      />
      <ModalComponent
        title="Are you sure?"
        showModal={showModal}
        onCancel={handleCancel}
        onSubmit={onApproveButtonSubmit}
      />
    </>
  );
}

export default observer(ApproveButtonWithModal);
