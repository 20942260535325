import { types } from "mobx-state-tree";

const Filter = types.model("Filter", {
  name: types.string,
  select_options: types.array(types.frozen({})),
  raw_values: types.optional(types.array(types.frozen({})), [])
});

/**
 * create a filter instance
 * @param name
 * @param select_options
 * @param raw_values raw values which possible could be as for the patches
 * @returns {STNValue<ModelInstanceTypeProps<ModelPropertiesDeclarationToProperties<{select_options: IArrayType<IType<{} | undefined | null, {}, {}>>, name: ISimpleType<string>, raw_values: IOptionalIType<IArrayType<IType<{} | undefined | null, {}, {}>>, [undefined]>}>> & {}, IModelType<ModelPropertiesDeclarationToProperties<{select_options: IArrayType<IType<{} | undefined | null, {}, {}>>, name: ISimpleType<string>, raw_values: IOptionalIType<IArrayType<IType<{} | undefined | null, {}, {}>>, [undefined]>}>, {}>>}
 */
export const createFilter = (name, select_options, raw_values = []) =>
  Filter.create({ name, select_options, raw_values });

export default Filter;
