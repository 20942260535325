import { flow, types } from "mobx-state-tree";
import { message } from "antd";
import Assignment from "./Assignment";
import SignatureData from "./SignatureData";
import ManageContentService from "../../services/api/content";
import Confirmation from "./Confirmation";

const ManageContentStore = types
  .model("ManageContentStore", {
    loadingAssignmentInfo: types.optional(types.boolean, false),
    loadingS3SignatureData: types.optional(types.boolean, false),
    uploadingFile: types.optional(types.boolean, false),
    deletingFile: types.optional(types.boolean, false),
    sendingUploadedFileInfo: types.optional(types.boolean, false),
    settingDescription: types.optional(types.boolean, false),
    assignment: types.maybe(Assignment),
    S3SignatureData: types.frozen(SignatureData),
    serverUploadConfirmation: types.frozen(Confirmation)
  })
  .actions(self => ({
    loadAssignment: flow(function* loadAssignment(id) {
      self.loadingAssignmentInfo = true;
      try {
        const responseData = yield ManageContentService.getAssignment(id);
        self.assignment = responseData;
        self.loadingAssignmentInfo = false;
      } catch (error) {
        self.loadingAssignmentInfo = false;
        console.error("Error: ", error);
      }
    }),
    loadS3SignatureData: flow(function* loadS3SignatureData(assignment_id) {
      self.loadingS3SignatureData = true;
      try {
        const responseData = yield ManageContentService.getSignatureData(
          assignment_id
        );
        self.S3SignatureData = responseData;
        self.loadingS3SignatureData = false;
      } catch (error) {
        self.loadingS3SignatureData = false;
        console.error("Error: ", error);
      }
    }),
    uploadFile: flow(function* uploadFile(params) {
      self.uploadingFile = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const responseData = yield ManageContentService.uploadFileToS3(params);
        self.uploadingFile = false;
      } catch (error) {
        self.uploadingFile = false;
        console.error("Error: ", error);
      }
    }),
    sendFileInfoToTheServer: flow(function* sendFileInfoToTheServer(
      params,
      assignmentId,
      type = "file"
    ) {
      self.sendingUploadedFileInfo = true;
      try {
        const responseData = yield ManageContentService.sendUploadedFileInfo(
          params,
          assignmentId
        );
        self.serverUploadConfirmation = responseData;
        if (type === "file") {
          self.assignment.addFile(responseData);
        }
        if (type === "link") {
          self.assignment.addLink(responseData);
        }
        message.success(`The ${type} has been uploaded successfully!`);
        self.sendingUploadedFileInfo = false;
      } catch (error) {
        message.error(`${error}`);
        console.error("Error: ", error);
        self.sendingUploadedFileInfo = false;
      }
    }),
    deleteFile: flow(function* deleteFile(id, type = "file") {
      self.deletingFile = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const responseData = yield ManageContentService.deleteFileFromServer(
          id
        );
        message.success(`The ${type} has been removed successfully!`);
        self.deletingFile = false;
      } catch (error) {
        message.error(`${error}`);
        console.error("Error: ", error);
        self.deletingFile = false;
      }
    }),
    setDescriptionToAssignment: flow(function* setDescriptionToAssignment(
      assignment_id,
      description
    ) {
      self.settingDescription = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const responseData = yield ManageContentService.setAssignmentDescription(
          assignment_id,
          description
        );
        message.success("The description has been changed successfully!");
        self.settingDescription = false;
      } catch (error) {
        message.error(`${error}`);
        console.error("Error: ", error);
        self.settingDescription = false;
      }
    })
  }))
  .views(self => ({
    get loading() {
      return (
        self.loadingUserInfo ||
        self.loadingS3SignatureData ||
        self.uploadingFile ||
        self.sendingUploadedFileInfo ||
        self.deletingFile ||
        self.settingDescription
      );
    }
  }));

const manageContentStore = ManageContentStore.create({
  loadingUserInfo: false,
  loadingS3SignatureData: false,
  uploadingFile: false,
  sendingUploadedFileInfo: false,
  deletingFile: false,
  settingDescription: false,
  assignment: { story: {}, type: {}, files: [] },
  S3SignatureData: {},
  serverUploadConfirmation: {}
});

export default manageContentStore;
