import { types } from "mobx-state-tree";

const EditableModel = types.model("EditableModel").actions(self => ({
  setValue(patch) {
    const [field, value] = Object.entries(patch)[0];
    self[field] = value;
  }
}));

export default EditableModel;
