import axios from "axios";

window.axios = axios;

export const setUpAuthHeader = ({ accessToken, tokenType }) => {
  axios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
};

export const removeAuthHeader = () =>
  delete axios.defaults.headers.Authorization;
