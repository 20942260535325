import React, { useContext, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, DatePicker, Icon, Radio, Row, Select, Tooltip } from "antd";
import SVG from "react-inlinesvg";
import TextArea from "antd/es/input/TextArea";
import { ReactComponent as IconTrash } from "../../images/icons/trash.svg";
import { DATE_FORMAT, ASSIGNMENTS_MAX_COUNT } from "../../config/constants";
import Button from "../../common/Button";
import Field from "../../common/FieldContainer";
import EditableStatus from "../../common/EditableStatus";
import {
  toOptionArray,
  toOptionArrayWithEmailAndUserType
} from "../../helpers/array-utils";
import FormContext from "../../models/FormContext";
import Switch from "../../common/Switch";
import ModalComponent from "../../common/Modal";
import { filterOptionsForMultipleEntitiesWithExceptions } from "../../helpers/filterOptions";

const AssignmentForm = observer(
  ({
    assignments,
    field,
    types = [],
    assignees = [],
    assignmentStatuses = [],
    addAssignmentToRemoveList
  }) => {
    const [showModal, setShowModal] = useState(false);

    const onRemoveAssignmentHandler = () => {
      setShowModal(true);
    };

    const onSubmitHandler = () => {
      addAssignmentToRemoveList(field.value.id);
      assignments.del(field.key);
      setShowModal(false);
    };

    const onCancelHandler = () => {
      setShowModal(false);
    };

    const isNew = !field.value.id;
    const userCreator = field.$("user_creator").value;

    const canBeDeleted = () => {
      // check if this field was created earlier
      if (!isNew) {
        return !(
          field.$("content_exist").value || field.$("commissions").value.length
        );
      }
      // always return true for new fields
      return true;
    };
    return (
      <div key={`assignment-${field.id}`} className="assignments-form">
        <Row className="row">
          <Col span={24} className="assignment-types-col">
            <div className="label">Types of Tasks / Assignments</div>
            <Field field={field.$("type_id")}>
              <Radio.Group>
                {types.map(({ name, id, icon }) => (
                  <Radio.Button key={`assignment-type-${id}`} value={id}>
                    <span className="assgiment-container">
                      <SVG src={icon} className="assignment-icon" />
                      <span>{name}</span>
                    </span>
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Field>
          </Col>
        </Row>
        <Row gutter={70}>
          <Col lg={8} md={24}>
            <Field field={field.$("user_id")}>
              <Select
                suffixIcon={<Icon type="caret-down" theme="filled" />}
                allowClear
                showSearch
                dropdownMatchSelectWidth={false}
                filterOption={filterOptionsForMultipleEntitiesWithExceptions}
                disabled={
                  field.value.commissions && !!field.value.commissions.length
                }
              >
                {assignees.map(({ label, value, email, role }) => (
                  <Select.Option key={value} value={value}>
                    {label} {email} / {role}
                  </Select.Option>
                ))}
              </Select>
            </Field>
            {userCreator && (
              <div className="user-creator">
                <Field field={field.$("user_creator")}>
                  <span>
                    {userCreator.name} / {userCreator.roles[0].title}
                  </span>
                </Field>
              </div>
            )}

            <EditableStatus
              status={field.$("assignment_status_id").value}
              availableStatuses={assignmentStatuses}
              type="dot"
              editable={!isNew}
              context="assignment"
              onChange={status => {
                field.update({
                  assignment_status_id: status
                });
              }}
            />
          </Col>
          <Col lg={8} md={24}>
            <Field field={field.$("task_brief")}>
              <TextArea rows={4} />
            </Field>
          </Col>
          <Col className="custom-col" lg={8} md={24}>
            <Field field={field.$("task_start_date")}>
              <DatePicker format={DATE_FORMAT} />
            </Field>
          </Col>
          <Col className="custom-col" lg={8} md={24}>
            <Field field={field.$("deadline")}>
              <DatePicker format={DATE_FORMAT} />
            </Field>
            <div className="notify-user-and-delete-container">
              <Tooltip
                placement="top"
                title="Assignee will not receive the notification if his global notification settings is switched off"
              >
                <div>
                  <Field field={field.$("notify_user")}>
                    <Switch />
                  </Field>
                </div>
              </Tooltip>
              {canBeDeleted() && (
                <Icon
                  component={IconTrash}
                  onClick={onRemoveAssignmentHandler}
                  data-testid="create-story-assignments-remove-button"
                />
              )}
            </div>
          </Col>
        </Row>

        <ModalComponent
          showModal={showModal}
          onCancel={onCancelHandler}
          onSubmit={onSubmitHandler}
        />
      </div>
    );
  }
);

function Assignments({
  assignmentTypes,
  assignees,
  assignmentStatuses,
  onChangeStatus,
  addAssignmentToRemoveList
}) {
  const formState = useContext(FormContext);

  const assignments = formState.has("assignments")
    ? formState.$("assignments")
    : [];
  const className =
    assignments.value.length >= ASSIGNMENTS_MAX_COUNT ? "hidden" : "visible";
  return (
    <Card
      className="story-panel assignments-container no-padding"
      bordered={false}
      title="Assignments"
      extra="Add Assignments for all internal and external team members working on the Story"
      actions={[
        <Button
          className={className}
          text="Add Assignment"
          size="small"
          theme="green-outline"
          icon="add"
          onClick={() => assignments.add()}
        />
      ]}
    >
      {assignments.map((assignment, i) => (
        <AssignmentForm
          key={`assignment-${i}`}
          field={assignment}
          assignments={assignments}
          types={assignmentTypes}
          assignees={assignees}
          onChangeStatus={onChangeStatus}
          assignmentStatuses={assignmentStatuses}
          addAssignmentToRemoveList={addAssignmentToRemoveList}
        />
      ))}
    </Card>
  );
}

export default inject(stores => ({
  story: stores.StoryEditStore.story,
  onChangeStatus: stores.StoryEditStore.changeAssignmentStatus,
  assignmentTypes: stores.StoryEditStore.selectOptions.assignmentTypes,
  assignmentStatuses: toOptionArray(
    stores.StoryEditStore.selectOptions.assignmentStatuses
  ),
  assignees: toOptionArrayWithEmailAndUserType(
    stores.StoryEditStore.selectOptions.assignees
  )
}))(observer(Assignments));
