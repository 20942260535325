import React from "react";
import "./styles.less";

export default function FooterContainer() {
  return (
    <div className="footer-container">
      <div className="footer-container-item">
        &copy; storytrek Limited. All rights reserved.
      </div>
      {/* <div className="footer-container-item">Terms of Service</div> */}
      {/* <div className="footer-container-item">Privacy Policy</div> */}
      {/* <div className="footer-container-item">Security</div> */}
    </div>
  );
}
