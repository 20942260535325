import { types } from "mobx-state-tree";

const UserAddition = types.model("UserAddition", {
  address: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  currency_id: types.optional(types.number, 0),
  rights_signed: types.optional(types.boolean, false),
  created_at: types.optional(types.string, ""),
  updated_at: types.optional(types.string, ""),
  vat: types.maybeNull(types.string)
});

export default UserAddition;
