export const DATE_FORMAT = "DD.MM.YYYY";
export const ASSIGNMENTS_MAX_COUNT = 30;
export const PLATFORMS_MAX_COUNT = 15;
export const COMMISSION_COSTS_MAX_COUNT = 15;

export default {
  DATE_FORMAT,
  ASSIGNMENTS_MAX_COUNT,
  PLATFORMS_MAX_COUNT,
  COMMISSION_COSTS_MAX_COUNT
};
