import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute(props) {
  const {
    AuthStore: { isLoggedIn },
    ...restProps
  } = props;
  if (isLoggedIn) return <Route {...restProps} />;
  return <Redirect to="/login" />;
}

export default inject("AuthStore")(observer(PrivateRoute));
