import {
  USER_ROLE_ADMIN,
  USER_ROLE_CLIENT_ADMIN,
  USER_ROLE_CONTRIBUTOR
} from "../config/roles";

const canChangeAssignmentStatus = (userRole, assignmentStatusSlug) => {
  if (assignmentStatusSlug === "complete") {
    return userRole === USER_ROLE_ADMIN || userRole === USER_ROLE_CLIENT_ADMIN;
  }
  return userRole !== USER_ROLE_CONTRIBUTOR;
};

export default canChangeAssignmentStatus;
