import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const burger = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.5" stroke="#9CA1AC" strokeLinecap="round" />
    <path d="M5 5H11" stroke="#9CA1AC" strokeLinecap="round" />
    <path d="M5 8H11" stroke="#9CA1AC" strokeLinecap="round" />
    <path d="M5 11H11" stroke="#9CA1AC" strokeLinecap="round" />
  </svg>
);
