import { applySnapshot, flow, types } from "mobx-state-tree";
import BatchDetailsInfo from "./BatchDetailsInfo";
import BatchDetailsService from "../../services/api/batchDetails";
import Filter, { createFilter } from "../common/filter";
import { toOptionArray } from "../../helpers/array-utils";
import showErrorMessage from "../../helpers/showErrorMessage";

const initialSnapshot = {
  loadingBatchDetails: false,
  loadingStatuses: false,
  changingStatus: false,
  batchDetailsInfo: { status: {}, invoice_amounts: [] }
};

const BatchDetailsStore = types
  .model("BatchDetailsStore", {
    loadingBatchDetails: types.optional(types.boolean, false),
    loadingStatuses: types.optional(types.boolean, false),
    changingStatus: types.optional(types.boolean, false),
    batchDetailsInfo: types.maybeNull(BatchDetailsInfo),
    statuses: types.maybeNull(Filter)
  })
  .actions(self => {
    return {
      loadBatchDetailsInfo: flow(function* loadBatchDetailsInfo(id) {
        self.loadingBatchDetails = true;
        try {
          self.batchDetailsInfo = yield BatchDetailsService.getBatchPaymentPreviewData(
            id
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingBatchDetails = false;
        }
      }),
      loadStatusOptions: flow(function* loadStatusOptions() {
        self.loadingStatuses = true;
        try {
          const { statuses } = yield BatchDetailsService.getStatuses();
          self.statuses = createFilter(
            "statuses",
            toOptionArray(statuses),
            statuses
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingStatuses = false;
        }
      }),
      changeBatchPaymentStatus: flow(function* changeBatchPaymentStatus(
        batchPaymentId,
        params
      ) {
        self.changingStatus = true;
        try {
          self.batchDetailsInfo.status = yield BatchDetailsService.changeBatchStatus(
            batchPaymentId,
            params
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.changingStatus = false;
        }
      }),
      reset() {
        applySnapshot(self, initialSnapshot);
      }
    };
  })
  .views(self => ({
    get loading() {
      return (
        self.loadingBatchDetails || self.loadingStatuses || self.changingStatus
      );
    }
  }));

const batchDetailsStore = BatchDetailsStore.create(initialSnapshot);

export { batchDetailsStore, BatchDetailsStore, initialSnapshot };
