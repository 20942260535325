import React, { Component } from "react";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { reaction } from "mobx";
import { Table, Tooltip } from "antd";
import getUrlParams from "../../helpers/getUrlParams";
import createSearchString from "../../helpers/createSearchString";
import arrow from "../../common/paginationIcons";
import ActionLogFilters from "./ActionLogFilters";
import ReadMore from "../../common/ReadMore";
import AccessControl from "../../models/AccessControl";
import "./styles.less";
import { pageSizeOptions } from "../../constants/pagination";

class ActionLog extends Component {
  state = {
    isAbleToView: false
  };

  columns = [
    {
      title: "action date",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .actionDate,
      className: "action-date-column",
      render: text => {
        return <div>{text}</div>;
      }
    },
    {
      title: "entity",
      dataIndex: "auditable_type",
      key: "auditable_type",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .entity,
      className: "entity-column",
      render: title => (
        <span className="table-data-text">
          <ReadMore text={title} length={10} />
        </span>
      )
    },
    {
      title: "entity id",
      dataIndex: "auditable_id",
      key: "auditable_id",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .entityId,
      className: "entity-id-column",
      render: text => {
        return <span className="table-data-text">{text}</span>;
      }
    },
    {
      title: "event",
      key: "event",
      dataIndex: "event",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .event,
      render: text => <span className="table-data-text">{text}</span>
    },
    {
      title: <span className="ant-table-column-title">Agent Account</span>,
      dataIndex: "user_email",
      key: "user_email",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .agentAccount,
      render: (text, row) => {
        return (
          <Tooltip
            title={`Agent id: ${row.user_id}`}
            placement="bottom"
            arrowPointAtCenter
          >
            <span className="table-data-text">
              <ReadMore text={text} length={10} mode="cutEnd" />
            </span>
          </Tooltip>
        );
      }
    },
    {
      title: <span className="ant-table-column-title">Agent IP</span>,
      dataIndex: "ip_address",
      key: "ip_address",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.ActionLogStore.filtersInitialValues.sorting
        .agentIP,
      render: (text, row) => {
        return (
          <Tooltip title={row.user_agent} placement="bottom" arrowPointAtCenter>
            <span className="table-data-text">{text}</span>
          </Tooltip>
        );
      }
    },
    {
      title: "request",
      dataIndex: "url",
      key: "url",
      render: text => {
        return (
          <span className="table-data-text">
            <ReadMore text={text} length={12} mode="cutStart" />
          </span>
        );
      }
    },
    {
      title: "old values",
      dataIndex: "origin_old_values",
      key: "origin_old_values",
      render: text => {
        return (
          <span className="table-data-text">
            <ReadMore text={text} length={11} mode="cutEnd" />
          </span>
        );
      }
    },
    {
      title: "new values",
      dataIndex: "origin_new_values",
      key: "origin_new_values",
      render: text => {
        return (
          <span className="table-data-text">
            <ReadMore text={text} length={11} mode="cutEnd" />
          </span>
        );
      }
    }
  ];

  componentDidMount = async () => {
    const { ActionLogStore } = this.props;
    const isAbleToView = await AccessControl.can("action-log:view-action-log");
    this.setState({
      isAbleToView
    });
    if (isAbleToView) {
      ActionLogStore.loadFilters();
      ActionLogStore.setAppliedFilters();
      disposeOnUnmount(
        this,
        reaction(
          () => ActionLogStore.filtersRequestParams,
          () => {
            ActionLogStore.loadActionLogList(
              ActionLogStore.filtersRequestParams
            );
          },
          {
            fireImmediately: true
          }
        )
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      ActionLogStore: { setAppliedFilters },
      location: { search }
    } = this.props;
    if (prevProps.location.search !== search) {
      setAppliedFilters();
    }
  }

  tableSortingAndPaginationHandler = (pagination, tableFilters, sorter) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    urlParams.page = pagination.current;

    const namesMap = new Map([
      ["updated_at", "actionDate"],
      ["auditable_type", "entity"],
      ["auditable_id", "entityId"],
      ["event", "event"],
      ["user_email", "agentAccount"],
      ["ip_address", "agentIP"]
    ]);

    switch (sorter.order) {
      case "ascend":
        urlParams.sortByAsc = namesMap.get(sorter.field);
        delete urlParams.sortByDesc;
        break;
      case "descend":
        urlParams.sortByDesc = namesMap.get(sorter.field);
        delete urlParams.sortByAsc;
        break;
      default:
        delete urlParams.sortByAsc;
        delete urlParams.sortByDesc;
    }
    history.push(createSearchString(urlParams));
  };

  tableRowClickHandler = record => {
    const { history } = this.props;
    return {
      onClick: () => {
        history.push({
          pathname: `/log-details/${record.id}`,
          from: "action-log"
        });
      }
    };
  };

  paginationItemRender = (page, type) => {
    if (type === "prev") {
      return (
        <span className="pagination-arrow-back">
          <span>{arrow}</span>
          Back
        </span>
      );
    }
    if (type === "next") {
      return (
        <span className="pagination-arrow-next">
          Next
          <span>{arrow}</span>
        </span>
      );
    }
    return null;
  };

  showTotal = (totalItems, fromToArr) => {
    const {
      ActionLogStore: { total }
    } = this.props;
    return `${fromToArr[0]}-${fromToArr[1]} of ${total}`;
  };

  onItemsPerPageChange = (currentPage, newSize) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    history.push(createSearchString({ ...urlParams, limit: newSize }));
  };

  render() {
    const {
      ActionLogStore: {
        loadingActionLogList,
        loadingFilters,
        filters,
        filtersInitialValues,
        currentPageNumber,
        perPage,
        total
      },
      ActionLogStore: store,
      history
    } = this.props;
    const { isAbleToView } = this.state;
    return (
      <>
        {isAbleToView && (
          <div className="action-log-wrapper">
            <ActionLogFilters
              history={history}
              filters={filters}
              loadingFilters={loadingFilters}
              filtersInitialValues={filtersInitialValues}
            />
            <Table
              columns={this.columns}
              dataSource={store.actionLogList}
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: pageSizeOptions.high,
                onShowSizeChange: this.onItemsPerPageChange,
                pageSize: perPage,
                size: "large",
                total,
                showTotal: this.showTotal,
                itemRender: this.paginationItemRender,
                current: currentPageNumber
              }}
              onChange={this.tableSortingAndPaginationHandler}
              onRow={this.tableRowClickHandler}
              loading={loadingActionLogList}
            />
          </div>
        )}
        {!isAbleToView && (
          <div>
            Sorry, you don&prime;t have the permission to view this page.
          </div>
        )}
      </>
    );
  }
}

export default inject("ActionLogStore")(observer(ActionLog));
