import React, { Component } from "react";
import { Col, Icon, Row, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { asyncReactor } from "async-reactor";
import { Link } from "react-router-dom";
// import subcomponents and helpers
import AccessControl from "../../models/AccessControl";
import CommissionDetailsView from "./CommissionDetailsView";
import Button from "../../common/Button";
import ContributorView from "./ContributorView";
import SpecialTermsView from "./SpecialTermsView";
import CostsView from "./CostsView";
import CostAllocationView from "./CostAllocationView";
import EditCommissionModal from "./components/Modals/EditCommissionModal";
import ApproveRejectModal from "./components/Modals/ApproveRejectModal";
import {
  noAbilityToApproveSet,
  noAbilityToRejectSet,
  noAbilityToEditSet,
  commissionLockedSet
} from "./commissionSets";
// import styles
import "./styles.less";

const API_ROOT = process.env.REACT_APP_ASSETS_BASE_BATH;

const IsAbleToApproveCommission = asyncReactor(
  async ({ handleApproveReject, store: { commissionInfo } }) => {
    const IsAbleToApprove = await AccessControl.can(
      "commission:approve-commission",
      { commissionInfo }
    );
    if (!IsAbleToApprove) {
      return null;
    }
    return (
      <Button
        text="Approve"
        onClick={handleApproveReject}
        theme="green-outline"
        className="approve-button"
      />
    );
  }
);

const IsAbleToRejectCommission = asyncReactor(
  async ({ handleApproveReject, store: { commissionInfo } }) => {
    const IsAbleToReject = await AccessControl.can(
      "commission:reject-commission",
      { commissionInfo }
    );
    if (!IsAbleToReject) {
      return null;
    }
    return (
      <Button
        text="Reject"
        onClick={handleApproveReject}
        theme="red-outline"
        className="reject-button"
      />
    );
  }
);

class CommissionView extends Component {
  state = {
    isAbleToViewCommission: false,
    isAbleToRejectSentToContributorCommission: false,
    isAbleToApproveManagerApprovedCommission: false,
    showEditModal: false,
    showApproveModal: false,
    showRejectModal: false
  };

  componentDidMount = async () => {
    const {
      CommissionViewStore: store,
      match: {
        params: { commissionId, assignmentId }
      }
    } = this.props;

    const isAbleToView = await AccessControl.can(
      "commission:read-write-commission"
    );

    const isAbleToRejectSentToContributorCommission = await AccessControl.can(
      "commission:reject-sent-to-contributor-commission"
    );

    const isAbleToApproveManagerApprovedCommission = await AccessControl.can(
      "commission:approve-manager-approved-commission"
    );

    this.setState({
      isAbleToViewCommission: isAbleToView,
      isAbleToRejectSentToContributorCommission,
      isAbleToApproveManagerApprovedCommission
    });

    if (isAbleToView) {
      store.load(commissionId);
      store.loadMetaData(assignmentId);
    }
  };

  componentWillUnmount() {
    const { CommissionViewStore: store } = this.props;
    store.reset();
  }

  openFile = link => () => {
    window.open(`${API_ROOT}${link}`, "_blank");
  };

  // edit button handlers

  handleEdit = () => {
    const { CommissionViewStore: store } = this.props;

    const statusSlugsRejectedSet = new Set([
      "manager_rejected",
      "contributor_rejected"
    ]);

    if (
      statusSlugsRejectedSet.has(store.commissionInfo.commission_status.slug)
    ) {
      this.setState({
        showEditModal: true
      });
    } else {
      this.onEditSubmit();
    }
  };

  onEditCancel = () => {
    this.setState({
      showEditModal: false
    });
  };

  onEditSubmit = () => {
    const {
      match: {
        params: { commissionId, assignmentId }
      },
      history
    } = this.props;
    history.push(`/assignment/${assignmentId}/commission/${commissionId}/edit`);
  };

  // approve and reject button handlers

  handleApproveReject = actionType => () => {
    this.setState({
      [`show${actionType}Modal`]: true
    });
  };

  onApproveRejectCancel = actionType => () => {
    this.setState({
      [`show${actionType}Modal`]: false
    });
  };

  onApproveRejectSubmit = async values => {
    const {
      CommissionViewStore: store,
      match: {
        params: { commissionId }
      }
    } = this.props;

    await store.updateCommissionStatus(commissionId, values);
    this.setState({
      showApproveModal: false,
      showRejectModal: false
    });
  };

  render() {
    const {
      CommissionViewStore: { commissionInfo },
      CommissionViewStore: store,
      CommissionViewStore: { loading },
      match: {
        params: { commissionId }
      }
    } = this.props;
    const {
      showEditModal,
      showApproveModal,
      showRejectModal,
      isAbleToRejectSentToContributorCommission,
      isAbleToViewCommission,
      isAbleToApproveManagerApprovedCommission
    } = this.state;

    const isAbleToReject = () => {
      if (commissionLockedSet.has(commissionInfo.commission_status.slug)) {
        return false;
      }
      if (
        commissionInfo.commission_status.slug === "sent_to_contributor" &&
        isAbleToRejectSentToContributorCommission
      ) {
        return true;
      }
      return !noAbilityToRejectSet.has(commissionInfo.commission_status.slug);
    };

    const isAbleToApprove = () => {
      if (commissionLockedSet.has(commissionInfo.commission_status.slug)) {
        return false;
      }
      if (
        commissionInfo.commission_status.slug === "manager_approved" &&
        isAbleToApproveManagerApprovedCommission
      ) {
        return true;
      }
      return !noAbilityToApproveSet.has(commissionInfo.commission_status.slug);
    };

    const isAbleToEdit = () => {
      if (commissionLockedSet.has(commissionInfo.commission_status.slug)) {
        return false;
      }
      return !noAbilityToEditSet.has(commissionInfo.commission_status.slug);
    };

    return (
      <>
        {isAbleToViewCommission && (
          <React.Fragment>
            <div className="create-story-container">
              <Link
                className="back-link"
                to={`/story/${commissionInfo.story_id}`}
              >
                <Icon type="arrow-left" />
                Back to the Story
              </Link>
              <Row>
                <Col xl={24}>
                  <h2>Commission {commissionInfo.id}</h2>
                </Col>
              </Row>
              <Spin spinning={loading}>
                <CommissionDetailsView
                  commissionId={commissionId}
                  openFile={this.openFile}
                />
                <ContributorView commissionInfo={commissionInfo} />
                <SpecialTermsView />
                <CostsView />
                <CostAllocationView />
                <div className="commission-view-buttons-container">
                  {/* commissionInfo.approver_user_id is necessary for access control check */}
                  {commissionInfo.approver_user_id && isAbleToReject() && (
                    <IsAbleToRejectCommission
                      store={store}
                      handleApproveReject={this.handleApproveReject("Reject")}
                    />
                  )}
                  {commissionInfo.approver_user_id && isAbleToApprove() && (
                    <IsAbleToApproveCommission
                      handleApproveReject={this.handleApproveReject("Approve")}
                      store={store}
                    />
                  )}
                  {commissionInfo.commission_status.slug && isAbleToEdit() && (
                    <Button
                      text="Edit"
                      onClick={this.handleEdit}
                      theme="black-outline"
                      className="edit-button"
                    />
                  )}
                </div>
              </Spin>
            </div>
            {/* modals */}
            <EditCommissionModal
              onCancel={this.onEditCancel}
              onSubmit={this.onEditSubmit}
              showModal={showEditModal}
            />
            <ApproveRejectModal
              onCancel={this.onApproveRejectCancel("Approve")}
              onSubmit={this.onApproveRejectSubmit}
              showModal={showApproveModal}
              text="Approve the commission?"
              loading={loading}
            />
            <ApproveRejectModal
              onCancel={this.onApproveRejectCancel("Reject")}
              onSubmit={this.onApproveRejectSubmit}
              showModal={showRejectModal}
              text="Reject the commission?"
              validation
              type="reject"
              loading={loading}
            />
          </React.Fragment>
        )}
        {!isAbleToViewCommission && (
          <div>
            Sorry, you don&prime;t have the permission to view this page.
          </div>
        )}
      </>
    );
  }
}

export default inject("CommissionViewStore")(observer(CommissionView));
