import axios from "axios";

const userProfileService = {
  loadUserInfo: () => {
    return axios.get("user/profile").then(({ data: { entity } }) => {
      return entity;
    });
  },
  updateUser: params => {
    return axios.post("user/profile", params).then(({ data: { entity } }) => {
      return entity;
    });
  }
};

export default userProfileService;
