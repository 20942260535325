import { inject, observer } from "mobx-react";
import React from "react";
import "./styles.less";

const LinkedStoriesPopupContent = ({ status, story }) => {
  return (
    <div key={story.id} className="story-list-modal-content-paragraph">
      <div>
        <div className="status">{status}</div>
        <div>{`${story.title} / ${story.content_type.name}`}</div>
      </div>
    </div>
  );
};

export default inject("StoryListStore")(observer(LinkedStoriesPopupContent));
