import axios from "axios";

const BatchDetailsService = {
  getBatchPaymentPreviewData: id => {
    return axios
      .get(`/batch-payment/preview/${id}`)
      .then(({ data: { entity } }) => entity);
  },
  getStatuses: () => {
    return axios
      .get(`/batch-payment/data-related/scope`)
      .then(({ data: { entity } }) => entity);
  },
  changeBatchStatus: (batchPaymentId, params) => {
    return axios
      .patch(`/batch-payment/update/${batchPaymentId}`, { ...params })
      .then(({ data: { entity } }) => entity);
  }
};

export default BatchDetailsService;
