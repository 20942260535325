function highlightTextParts(
  text,
  regExpression = /@[\w]*\s[\w-']*/g,
  className = "mentioned-name"
) {
  return text.replace(regExpression, match => {
    return `<span class="${className}">${match}</span>`;
  });
}

export default highlightTextParts;
