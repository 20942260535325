import { types } from "mobx-state-tree";
import Brand from "./brand";
import PlatformType from "./platform-type";

const PlatformName = types
  .model("PlatformName", {
    id: types.number,
    brand_id: types.number,
    platform_type_id: types.number,
    brand: Brand,
    platform_type: PlatformType
  })
  .views(self => ({
    get brandAndPlatformName() {
      const { brand, platform_type } = self;

      return `${brand.name} / ${platform_type.name}`;
    }
  }));

export default PlatformName;
