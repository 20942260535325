import axios from "axios";

const ManageContentService = {
  getAssignment: id => {
    return axios
      .get(`/content/assignment/${id}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  getSignatureData: id => {
    return axios
      .get(`/content/s3/assignment/${id}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  uploadFileToS3: params => {
    return axios
      .post(`https://hermes-bucket-test.s3-eu-west-2.amazonaws.com/`, params, {
        transformRequest: (data, headers) => {
          delete headers.Authorization;
          return data;
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  sendUploadedFileInfo: (params, assignmentId) => {
    return axios
      .post(`/content/store/assignment/${assignmentId}`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  deleteFileFromServer: id => {
    return axios
      .delete(`/content/delete/${id}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  setAssignmentDescription: (assignment_id, description) => {
    return axios
      .patch(`/content/assignment/${assignment_id}/description`, {
        description
      })
      .then(({ data: { entity } }) => {
        return entity;
      });
  }
};

export default ManageContentService;
