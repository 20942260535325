import { types } from "mobx-state-tree";
import UserAddition from "./UserAddition";

const ContributorUser = types.model("ContributorUser", {
  name: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  email: types.optional(types.string, ""),
  job_title: types.optional(types.string, ""),
  can_approve: types.optional(types.boolean, false),
  user_addition_id: types.optional(types.number, 0),
  user_addition: types.maybeNull(types.frozen(UserAddition))
});

export default ContributorUser;
