import { types } from "mobx-state-tree";
import Brand from "../common/brand";
import StoryCategory from "./commissionData/StoryCategory";
import AssignmentType from "./commissionData/AssignmentType";
import ContributorUser from "./commissionData/ContributorUser";
import CreatorUser from "./commissionData/CreatorUser";
import CommissionStatus from "./commissionData/CommissionStatus";
import Vendor from "./commissionInfo/Vendor";

const TermsAndConditions = types.model("termsAndConditions", {
  file_url: types.optional(types.string, ""),
  name: types.optional(types.string, "")
});

const CommissionData = types.model("CommissionData", {
  id: types.optional(types.number, 0),
  creator_user: types.frozen(CreatorUser),
  contributor_user: types.frozen(ContributorUser),
  deadline: types.maybeNull(types.string),
  assignment_type: types.frozen(AssignmentType),
  brands: types.array(Brand),
  story_name: types.optional(types.string, ""),
  story_id: types.optional(types.number, 0),
  story_category: types.array(types.frozen(StoryCategory)),
  commission_status: types.maybe(CommissionStatus),
  vendor: types.maybe(Vendor),
  terms_and_conditions: types.maybe(TermsAndConditions)
});

export default CommissionData;
