import { types } from "mobx-state-tree";

const User = types
  .model("User", {
    id: types.identifierNumber,
    allPermissions: types.array(types.string),
    name: types.string,
    email: types.string,
    roles: types.array(
      types.model({
        id: types.identifierNumber,
        name: types.string,
        title: types.string
      })
    ),
    // fields for tasks list
    user_addition_id: types.maybeNull(types.number),
    job_title: types.maybeNull(types.string)
  })
  .views(self => ({
    get primaryRole() {
      return self.roles[0];
    }
  }));

export default User;
