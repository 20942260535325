import React from "react";
import PropTypes from "prop-types";
import "./styles.less";
import { Form, Input } from "antd";
import { observer } from "mobx-react";
import Button from "../Button";

class InputTextButton extends React.Component {
  state = {
    inEdit: false
  };

  iconClickHandler = event => {
    const { inEdit } = this.state;
    const { form } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        inEdit: !inEdit
      },
      () => {
        form.validateFields();
      }
    );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    const { onSubmit, form } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        onSubmit(values);
      }
    });
  };

  cancelHandler = () => {
    const { inEdit } = this.state;
    this.setState({
      inEdit: !inEdit
    });
  };

  render() {
    const { text, rules, form, placeholder, disabled } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;
    const { inEdit } = this.state;
    const fieldError = isFieldTouched("field") && getFieldError("field");

    return (
      <>
        {inEdit && (
          <Form
            layout="inline"
            onSubmit={this.handleSubmit}
            className="custom-editable-form-wrapper"
          >
            <Form.Item
              validateStatus={fieldError ? "error" : ""}
              help={fieldError || ""}
            >
              {getFieldDecorator("field", {
                rules
              })(<Input placeholder={placeholder} />)}
            </Form.Item>
            <Form.Item>
              <Button
                text="Save"
                htmlType="submit"
                disabled={this.hasErrors(getFieldsError())}
                theme="solid-blue"
                size="small"
              />
            </Form.Item>
            <Form.Item>
              <Button
                text="Cancel"
                htmlType="button"
                theme="solid-blue"
                size="small"
                onClick={this.cancelHandler}
              />
            </Form.Item>
          </Form>
        )}
        {!inEdit && (
          <Button
            text={text}
            theme="blue-outline"
            size="small"
            onClick={this.iconClickHandler}
            className="add-text-content-button add-video-link-button"
            disabled={disabled}
          />
        )}
      </>
    );
  }
}

InputTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

InputTextButton.defaultProps = {
  rules: [],
  placeholder: "",
  disabled: false
};

export default Form.create()(observer(InputTextButton));
