import React from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, Row } from "antd";

function SpecialTermsView({ store: { commissionInfo } }) {
  return (
    <>
      {commissionInfo.special_terms ? (
        <Card
          className="story-panel commission-details"
          title="Special Terms"
          bordered={false}
        >
          <Row gutter={40}>
            <Col lg={8} md={8}>
              <div className="label-description-wrapper">
                <span className="label">Special Terms</span>
                <div className="description">
                  {commissionInfo.special_terms_description}
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="label-description-wrapper">
                <span className="label">Reuse, %</span>
                <div className="description">
                  {commissionInfo.special_terms_reuse} %
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="label-description-wrapper">
                <span className="label">Reuse Delay</span>
                <div className="description">
                  {commissionInfo.special_terms_reuse_delay} day(s)
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
}

export default inject(stores => ({ store: stores.CommissionViewStore }))(
  observer(SpecialTermsView)
);
