import { types } from "mobx-state-tree";
import moment from "moment";
import PlatformName from "./platform-name";
import Issue from "./issue";
import StoryStatus from "./story-status";
import { getColorForStoryStatus } from "../../helpers/getColorForStatus";
import PublishType from "./publish-type";

const Platform = types
  .model("Category", {
    id: types.optional(
      types.union(types.identifierNumber, types.identifier),
      0
    ),
    platform_name_id: types.maybeNull(types.number),
    story_id: types.maybeNull(types.number),
    issue_id: types.maybeNull(types.maybeNull(types.number)),
    idea: types.maybeNull(types.boolean),
    publication_date: types.maybeNull(types.string),
    publication_time: types.maybeNull(types.string),
    platform_status_id: types.maybeNull(types.number),
    platform_name: types.maybeNull(PlatformName),
    issue: types.maybeNull(Issue),
    platform_status: types.maybeNull(StoryStatus),
    platform_publish_type_id: types.maybeNull(types.number),
    platform_publish_type: types.maybeNull(PublishType)
  })
  .views(self => ({
    get platformIssueDate() {
      return self.publication_date
        ? moment(self.publication_date, "YYYY-MM-DD").format("DD.MM.YYYY")
        : "N/A";
    },
    get platformIssueTime() {
      return self.publication_time || "N/A";
    },
    get platformIssue() {
      const { issue } = self;
      if (!issue) {
        return false;
      }
      return `${issue.title} — ${moment(issue.publication_date).format()}`;
    },
    get statusColor() {
      if (!self.platform_status) {
        return getColorForStoryStatus("not_defined");
      }
      return getColorForStoryStatus(self.platform_status.slug, "dot");
    },
    get publishTypeName() {
      if (!self.platform_publish_type) {
        return "N/A";
      }
      return self.platform_publish_type.name;
    }
  }));

export default Platform;
