import React from "react";
import { Form, Icon, Select } from "antd";
import "./styles.less";
import RangePickerComponent from "../../../common/RangePicker";
import getUrlParams from "../../../helpers/getUrlParams";
import createSearchString from "../../../helpers/createSearchString";

function BatchPaymentsListFilter({ form, filtersInitialValues, options }) {
  const { getFieldDecorator } = form;
  const { Option } = Select;

  const changeHandler = () => {};

  return (
    <React.Fragment>
      <div className="batch-payments-filters-wrapper">
        <Form>
          <div className="batch-payments-filters">
            <Form.Item>
              {getFieldDecorator("date", {})(
                <RangePickerComponent onChange={changeHandler} />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("status", {
                initialValue: filtersInitialValues.status
              })(
                <Select
                  size="large"
                  dropdownMatchSelectWidth={false}
                  className="batch-payments-status-select"
                  placeholder="Status"
                  suffixIcon={<Icon type="caret-down" theme="filled" />}
                  allowClear
                >
                  {options.statuses &&
                    options.statuses.map(({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
}

const searchEngine = (props, changedFields, allFilterFields) => {
  const { history } = props;
  const usedFilters = {};
  const urlParams = getUrlParams();
  Object.keys(allFilterFields).forEach(fieldName => {
    delete urlParams[fieldName];
    switch (fieldName) {
      case "date":
        delete urlParams.dateFrom;
        delete urlParams.dateTo;
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters.dateFrom = allFilterFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.dateTo = allFilterFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      default:
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFilterFields[fieldName].value;
        }
        break;
    }
  });
  history.push(createSearchString({ ...usedFilters, ...urlParams, page: 1 }));
};

const formFieldChangeHandler = (...args) => {
  searchEngine(...args);
};

export default Form.create({ onFieldsChange: formFieldChangeHandler })(
  BatchPaymentsListFilter
);
