import { types } from "mobx-state-tree";

const VatRate = types.model("VatRate", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  rate: types.optional(types.string, ""),
  code: types.optional(types.string, "")
});

export default VatRate;
