const noAbilityToApproveSet = new Set([
  "manager_approved",
  "sent_to_contributor",
  "contributor_rejected",
  "awaiting_invoice",
  "invoice_approved",
  "invoice_rejected",
  "payment_approved",
  "payment_requested"
]);

const noAbilityToRejectSet = new Set([
  "manager_approved",
  "sent_to_contributor",
  "contributor_rejected",
  "awaiting_invoice",
  "invoice_approved",
  "invoice_rejected",
  "payment_approved",
  "payment_requested"
]);

const noAbilityToEditSet = new Set([
  "manager_approved",
  "sent_to_contributor",
  "contributor_approved",
  "contributor_rejected",
  "awaiting_invoice",
  "invoice_approved",
  "invoice_rejected",
  "payment_approved",
  "payment_requested"
]);

const noAbilityToChangeStatusSet = new Set([
  "manager_rejected",
  "creation_in_progress",
  "sent_to_manager",
  "manager_approved",
  "sent_to_contributor",
  "contributor_rejected",
  "payment_requested"
]);

const commissionLockedSet = new Set(["complete"]);

export {
  noAbilityToApproveSet,
  noAbilityToRejectSet,
  noAbilityToEditSet,
  commissionLockedSet,
  noAbilityToChangeStatusSet
};
