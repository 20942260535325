import * as shortid from "shortid";

function getFileNameWithHash(name) {
  // By default shortid generates 7-14 url-friendly characters: A-Z, a-z, 0-9, _-
  // Use this hash to make names unique
  const hash = shortid.generate().slice(0, 5);
  const nameArr = name.split(".");
  const ext = nameArr[nameArr.length - 1];
  const fullName = name.substr(0, name.length - ext.length - 1);
  return `${fullName}-${hash}.${ext}`;
}

export default getFileNameWithHash;
