import { applySnapshot, flow, types } from "mobx-state-tree";
import moment from "moment";
import CommissionService from "../../services/api/commissions";
import CommissionData from "./CommissionData";
import MetaData from "./MetaData";
import modificationToFormData from "../../helpers/modificationToFormData";
import showMessage from "../../helpers/showMessage";
import CommissionStatus from "./commissionData/CommissionStatus";
import CommissionInfo from "./CommissionInfo";
import showErrorMessage from "../../helpers/showErrorMessage";

const CommissionStore = types
  .model("CommissionStore", {
    loadingOptions: types.optional(types.boolean, false),
    loadingCreateCommissionData: types.optional(types.boolean, false),
    loadingCommissionData: types.optional(types.boolean, false),
    updatingStatusForExternalUsers: types.optional(types.boolean, false),
    savingCommission: types.optional(types.boolean, false),
    options: types.frozen(MetaData),
    createCommissionData: types.maybe(CommissionData),
    commission: types.maybeNull(CommissionInfo)
  })
  .actions(self => {
    return {
      initializeCostAllocationLevelSelects() {
        const { chart_of_accounts_count_levels } = self.options;
        let index = 0;
        const costAllocationLevelsArray = [];
        while (index < chart_of_accounts_count_levels) {
          costAllocationLevelsArray.push({ level: index + 1 });
          index += 1;
        }
        if (self.commission.cost_allocations.length === 0) {
          self.commission.cost_allocations.push({
            coa_levels: costAllocationLevelsArray
          });
        }
      },

      /**
       * load options for dropdowns
       */
      loadMetadata: flow(function* loadMetadata(assignmentId) {
        self.loadingOptions = true;
        try {
          const response = yield CommissionService.getMetadata(assignmentId);
          self.options = response;
          self.initializeCostAllocationLevelSelects();
          self.loadingOptions = false;
        } catch (error) {
          showErrorMessage(error);
          self.loadingOptions = false;
        }
      }),
      loadCreateCommissionData: flow(function* loadCreateCommissionData(id) {
        self.loadingCreateCommissionData = true;
        try {
          const response = yield CommissionService.getCreateCommissionData(id);
          self.createCommissionData = response;
          self.loadingCreateCommissionData = false;
        } catch (error) {
          showErrorMessage(error);
          self.loadingCreateCommissionData = false;
        }
      }),
      loadCommissionData: flow(function* loadCommissionData(id) {
        self.loadingCommissionData = true;
        try {
          const response = yield CommissionService.getCommissionInfo(id);
          self.commission = response;
          self.loadingCommissionData = false;
          return Promise.resolve();
        } catch (error) {
          showErrorMessage(error);
          self.loadingCommissionData = false;
          return Promise.reject();
        }
      }),
      load: flow(function* load(assignmentId, commissionId) {
        if (commissionId) {
          yield self.loadCommissionData(commissionId);
        }
        yield self.loadMetadata(assignmentId);
        yield self.loadCreateCommissionData(assignmentId);
      }),
      clear: () => {
        self.commission = {};
      },
      saveCommission: flow(function* saveCommission(values, assignmentId) {
        self.savingCommission = true;
        if (!values.commission_status_id) {
          delete values.commission_status_id;
        }
        if (
          values.id &&
          (values.commission_status.slug === "manager_rejected" ||
            values.commission_status.slug === "contributor_rejected")
        ) {
          values.commission_reopen = true;
        }
        if (values.id) {
          delete values.commission_status_id;
          // eslint-disable-next-line no-underscore-dangle
          values._method = "PUT";
        }
        const modifiedValues = modificationToFormData(values);
        try {
          const response = yield CommissionService.save(
            modifiedValues,
            assignmentId
          );
          applySnapshot(self.commission, response.entity);
          showMessage(response);
          self.savingCommission = false;
          return Promise.resolve();
        } catch (error) {
          showErrorMessage(error);
          self.savingCommission = false;
          return Promise.reject();
        }
      }),
      changeStatus(newStatus) {
        self.createCommissionData.commission_status = CommissionStatus.create(
          newStatus
        );
      },
      updateStatusForExternalUsers: flow(function* updateStatusForExternalUsers(
        commissionId,
        params
      ) {
        self.updatingStatusForExternalUsers = true;
        try {
          yield CommissionService.updateStatusForExternalUsers(
            commissionId,
            params
          );
          self.updatingStatusForExternalUsers = false;
          return Promise.resolve();
        } catch (error) {
          showErrorMessage(error);
          self.updatingStatusForExternalUsers = false;
          return Promise.reject();
        }
      })
    };
  })
  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    get loading() {
      return (
        self.loadingOptions ||
        self.loadingCreateCommissionData ||
        self.loadingCommissionData ||
        self.updatingStatusForExternalUsers ||
        self.savingCommission
      );
    },

    get defaultRight() {
      const right = self.options.commission_rights.find(
        option => option.name === "All Rights"
      );
      return right && right.id;
    },

    get formConfig() {
      return {
        fields: [
          "commission_right_id",
          "approver_user_id",
          "commission_status_id",
          "special_terms",
          "special_terms_description",
          "special_terms_reuse",
          "special_terms_reuse_delay",
          "costs_description",
          "currency_id",
          "currency",
          "costs[].expense_type_id",
          "costs[].cost",
          "costs[].vat_rate_id",
          "costs[].tax",
          "costs[].vat",
          "costs[].total",
          "costs[].currency",
          "invoice_number",
          "invoice_date",
          "cost_allocations[].coa_levels[].id",
          "cost_allocations[].percentage",
          "cost_allocations[].amount"
        ],
        defaults: {
          "costs[].tax": 0,
          "costs[].vat": 0,
          "costs[].total": 0
        },
        types: {
          "costs[].tax": "number",
          "costs[].vat": "number",
          "costs[].total": "number"
        },
        placeholders: {
          approver_user_id: "Type Admin or Employee email",
          special_terms_description:
            "e.g. we should pay the photographer 50 if we ever use his content again",
          special_terms_reuse: "e.g. 100%",
          special_terms_reuse_delay: "e.g. 10 days",
          costs_description: "Insert Text Here",
          currency_id: "Select",
          "costs[].expense_type_id": "Select Expense Type",
          "costs[].cost": "eg 100",
          "costs[].vat_rate_id": "Select VAT Rate",
          invoice_number: "Insert text here",
          invoice_date: "e.g. 01.01.2020",
          "cost_allocations[].coa_levels[].id": "Select",
          "cost_allocations[].percentage": "eg 100",
          "cost_allocations[].amount": "eg 10"
        },
        rules: {
          approver_user_id: "required",
          commission_right_id: "required",
          special_terms_description: [
            { required_if: ["special_terms", true] },
            "string",
            "between:5,500"
          ],
          special_terms_reuse: [
            { required_if: ["special_terms", true] },
            "integer",
            "between: 0,100"
          ],
          special_terms_reuse_delay: [
            { required_if: ["special_terms", true] },
            "integer",
            "between:0,360"
          ],
          costs_description: "required|between:1,200",
          currency_id: "required",
          "costs[].expense_type_id": "required",
          "costs[].cost": "required|numeric|between:0.01,1000000",
          "costs[].vat_rate_id": "required",
          "costs[].tax": "required|numeric|between:0,100",
          "costs[].vat": "required|numeric|min:0",
          "costs[].total": "required|numeric|min:0",
          "cost_allocations[].coa_levels[].id": "required",
          "cost_allocations[].percentage": [
            "required",
            "numeric",
            "between:0.01,100"
          ],
          "cost_allocations[].amount": "required|numeric|min:0.01",
          invoice_number: "between:1,100"
        },
        input: {
          approver_user_id: v => v || undefined,
          special_terms: v => v || false,
          currency_id: v => v || undefined,
          "costs[].expense_type_id": v => v || undefined,
          "costs[].vat_rate_id": v => v || undefined,
          "costs[].currency": v => v || undefined,
          "cost_allocations[].coa_levels[].id": v => v || undefined,
          invoice_date: v => (v ? moment(v) : null)
        },
        output: {
          invoice_date: v => v && v.format("YYYY-MM-DD")
        },
        labels: {
          commission_right_id: "Select Rights",
          approver_user_id: "Approver Email",
          special_terms_description: "Special Terms",
          special_terms_reuse: "Reuse, %",
          special_terms_reuse_delay: "Reuse Delay",
          costs_description: "Description",
          currency_id: "Currency",
          "costs[].expense_type_id": "Expense Type",
          "costs[].cost": "Cost",
          "costs[].vat_rate_id": "VAT Rate",
          "costs[].tax": "Tax, %",
          "costs[].vat": "VAT",
          "costs[].total": "Total",
          "costs[].currency": "Currency",
          invoice_number: "Invoice Number",
          invoice_date: "Invoice Date",
          "cost_allocations[].percentage": "Percentage",
          "cost_allocations[].amount": "Amount"
        },
        observers: {},
        hooks: {
          costs: {
            onInit: field => {
              if (field.fields.size === 0) {
                // todo check the case of load commission data on edit
                // there shouldn't be an empty row
                field.add();
              }
            }
          },
          currency_id: {
            onChange: field => {
              const container = field.container();
              const costs = container.$("costs");
              costs.fields.forEach(cost => {
                cost.$("currency").set("value", field.value);
              });
            }
          },
          "costs[].cost": {
            onChange: field => {
              const container = field.container();
              const vatField = container.$("vat_rate_id");
              if (!vatField.value) {
                return;
              }

              const targetVatRateObj = self.options.cost_vat_rates.find(
                vatRateObj => vatRateObj.id === vatField.value
              );
              if (!targetVatRateObj) {
                return;
              }
              const rate = Number(targetVatRateObj.rate);
              const vat = field.value * 0.01 * rate;
              const total = +field.value + vat;

              container.$("tax").set("value", rate);
              container.$("vat").set("value", vat);
              container.$("total").set("value", total);
            }
          },
          "costs[].vat_rate_id": {
            onChange: field => {
              const container = field.container();
              const costField = container.$("cost");
              if (!costField.value) {
                return;
              }

              const targetVatRateObj = self.options.cost_vat_rates.find(
                vatRateObj => vatRateObj.id === field.value
              );
              const rate = Number(targetVatRateObj.rate);
              const vat = costField.value * 0.01 * rate;
              const total = +costField.value + vat;

              container.$("tax").set("value", rate);
              container.$("vat").set("value", vat);
              container.$("total").set("value", total);
            }
          },
          commission_right_id: {
            onInit: field => {
              if (!field.value) {
                field.value = self.defaultRight;
              }
            }
          },
          cost_allocations: {
            onInit: field => {
              if (field.fields.size === 0) {
                field.add();
              }
            },
            onAdd: fieldset => {
              const [levelPrototype] = fieldset.fields.values();
              const [lastFieldset] = [...fieldset.fields.values()].reverse();
              const chart_of_accounts_count_levels = levelPrototype.$(
                "coa_levels"
              ).size;
              while (
                lastFieldset.$("coa_levels").size <
                chart_of_accounts_count_levels
              ) {
                lastFieldset.$("coa_levels").add({
                  level: lastFieldset.$("coa_levels").size
                });
              }
            }
          },
          "cost_allocations[].percentage": {
            onChange: field => {
              const costSum = field.state.form
                .$("costs")
                .values()
                .reduce((sum, current) => {
                  if (!current.cost) {
                    return sum;
                  }
                  return sum + +current.cost;
                }, 0);
              const newAmount = costSum * 0.01 * field.value;
              field
                .container()
                .$("amount")
                .set("value", newAmount);
            }
          },
          "cost_allocations[].amount": {
            onChange: field => {
              const costSum = field.state.form
                .$("costs")
                .values()
                .reduce((sum, current) => {
                  if (!current.cost) {
                    return sum;
                  }
                  return sum + +current.cost;
                }, 0);

              const newPercentage = (field.value / costSum) * 100;
              field
                .container()
                .$("percentage")
                .set("value", newPercentage);
            }
          }
        }
      };
    }
  }));

const commissionStore = CommissionStore.create({
  loadingOptions: false,
  loadingCreateCommissionData: false,
  loadingCommissionData: false,
  updatingStatusForExternalUsers: false,
  savingCommission: false,
  options: { commission_rights: [] },
  createCommissionData: {
    creator_user: {},
    contributor_user: {},
    assignment_type: {},
    commission_status: {}
  },
  commission: CommissionInfo.create()
});

export default commissionStore;
