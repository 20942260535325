import React from "react";
import { Icon } from "antd";
import Button from "../../common/Button";

function ConnectionCheck({
  handleCheckConnection,
  checkConnectionInProgress,
  checked,
  connectionCheckedSuccessfully
}) {
  return (
    <>
      <Button
        text="Check connection"
        htmlType="button"
        onClick={handleCheckConnection}
        theme="black-outline"
        size="small"
        className="connection-button"
        disabled={checkConnectionInProgress}
      />

      {checkConnectionInProgress && (
        <div className="check-connection">
          <Icon type="sync" spin />
        </div>
      )}
      {checked && (
        <div className="check-connection">
          {connectionCheckedSuccessfully ? (
            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
          ) : (
            <Icon type="warning" theme="twoTone" twoToneColor="red" />
          )}
        </div>
      )}
    </>
  );
}

export default ConnectionCheck;
