import React from "react";
import { ReactComponent as Logo } from "../../images/auth-storytrek-logo.svg";
import "./styles.less";

const AuthLogotype = () => (
  <div className="logotype-container">
    <Logo />
  </div>
);

export default AuthLogotype;
