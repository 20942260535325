import { types } from "mobx-state-tree";

const Confirmation = types.model("Confirmation", {
  extension: types.optional(types.string, ""),
  filename: types.optional(types.string, ""),
  id: types.maybeNull(types.number),
  mime: types.optional(types.string, ""),
  s3_filename: types.optional(types.string, ""),
  s3_link: types.optional(types.string, ""),
  s3_path: types.optional(types.string, ""),
  size: types.maybeNull(types.number)
});

export default Confirmation;
