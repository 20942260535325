import axios from "axios";

const Brand = {
  getBrands: (type, params) => {
    return axios
      .get(`/user/dashboard/${type}`, { params })
      .then(({ data: { entity } }) => entity);
  },
  getDataForBrandCardsFilters: () => {
    return axios
      .get("/user/dashboard/data-related/scope")
      .then(({ data: { entity } }) => entity);
  }
};

export default Brand;
