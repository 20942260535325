const API_ROOT = process.env.REACT_APP_ASSETS_BASE_BATH;

function getCorrectUrl(url, protocolAndHost = API_ROOT) {
  const regex =
    "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)";
  if (String(url).match(regex)) {
    return url;
  }
  return `${protocolAndHost}${url}`;
}

export default getCorrectUrl;
