import React from "react";
import { Card, Col, Row, Spin, Switch } from "antd";
import { observer } from "mobx-react";
import EditableField from "../../common/EditableField";
import FullNameEditableFields from "./FullNameEditableFields";
import ChangePassword from "./ChangePassword";

const Settings = ({
  UserProfileStore: { settings, loading },
  onNotifySwitchChange,
  onEmailChange,
  onFullNameChange,
  onPasswordChange
}) => {
  return (
    <Spin spinning={loading}>
      <Card className="story-panel" bordered={false} title="Settings">
        <Row gutter={20} type="flex" className="profile-details-row">
          <Col sm={24}>
            <div className="label-description-wrapper">
              <span className="label">Forename/ Surname</span>
              <FullNameEditableFields
                fullName={settings[`Forename/Surname`]}
                onChange={onFullNameChange}
              />
            </div>

            <div className="label-description-wrapper">
              <span className="label">Email</span>
              <EditableField
                text={settings.Email}
                onChange={onEmailChange}
                fieldClassName="small-field"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!"
                  },
                  {
                    min: 1,
                    max: 60,
                    message:
                      "The length of the email must be between 1 and 60. "
                  },
                  {
                    type: "email",
                    message: "This is not a valid email!"
                  }
                ]}
              />
            </div>
            <ChangePassword onChange={onPasswordChange} />
            <div className="switch-wrapper">
              <div className="switch-container notify-switch">
                <Switch
                  checked={settings[`Notify by Email`]}
                  onChange={onNotifySwitchChange}
                  data-testid="user-profile-notify-by-email-switch-button"
                />
              </div>
              <span>Notify by Email</span>
            </div>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default observer(Settings);
