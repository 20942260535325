import React from "react";
import { Form, Icon, Input } from "antd";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import FooterContainer from "../Footer";
import AuthLogotype from "../../common/AuthLogotype";
import withErrorHandler from "../../hoc/withErrorHandler";
import Button from "../../common/Button";
import "./styles.less";

class ConfirmResetPasswordPage extends React.Component {
  componentDidMount() {
    const {
      form: { setFieldsValue }
    } = this.props;
    const result = this.parseEmail();
    setFieldsValue({ email: result });
  }

  parseEmail = () => {
    const {
      location: { search }
    } = this.props;
    const parts = search.split("=");
    return parts[parts.length - 1].replace("%40", "@");
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Field 'Confirm Password' isn't equal to the field 'Password'");
    } else {
      callback();
    }
  };

  submitHandler = event => {
    const {
      AuthStore,
      match: {
        params: { token }
      },
      form: { validateFields },
      history
    } = this.props;
    event.preventDefault();
    validateFields((error, values) => {
      if (!error) {
        values.token = token;
        AuthStore.confirmResetPassword(values).then(() => {
          history.push("/login");
        });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      AuthStore: { loading }
    } = this.props;
    return (
      <div className="confirm-reset-password-page-wrapper">
        <div className="confirm-reset-password-page-container">
          <AuthLogotype />
          <Form
            onSubmit={this.submitHandler}
            className="confirm-reset-password-form"
            hideRequiredMark
          >
            <Link to="/login" className="back-to-login-link">
              <Icon type="arrow-left" />
              Back to Login
            </Link>
            <div className="reset-password-form-title">
              <h2>Reset Password</h2>
            </div>
            <Form.Item label="Email Address">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The entered value isn't valid email"
                  },
                  {
                    required: true,
                    message: "Please input your email address"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your new password"
                  },
                  {
                    min: 8,
                    max: 20,
                    type: "string",
                    message: "The length of password must be between 8 and 20"
                  }
                ]
              })(<Input type="password" />)}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {getFieldDecorator("password_confirmation", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your new password"
                  },
                  {
                    validator: this.compareToFirstPassword
                  }
                ]
              })(<Input type="password" />)}
            </Form.Item>
            <Button
              htmlType="submit"
              text="Reset Password"
              size="large"
              onClick={this.submitHandler}
              disabled={loading}
            />
          </Form>
        </div>
        <FooterContainer />
      </div>
    );
  }
}

export default Form.create()(
  inject("AuthStore")(withErrorHandler(observer(ConfirmResetPasswordPage)))
);
