import React from "react";
import { Avatar, Menu, Layout, Dropdown, Button } from "antd";
import { inject, observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import "./styles.less";
import storytrek from "../../images/storytrek-logo.svg";
import { mobileMenuBurger } from "./icons/mobile-menu-burger";
import { mobileMenuClose } from "./icons/mobile-menu-close";
import AccessControl from "../../models/AccessControl";

class HeaderContainer extends React.Component {
  state = {
    activePage: "dashboard",
    collapsed: true,
    isAbleToViewSettings: false,
    isAbleToViewActionLog: false,
    isAbleToViewBatchPayments: false
  };

  componentDidMount = async () => {
    const {
      location: { pathname }
    } = this.props;
    if (pathname.slice(1)) {
      this.setState({ activePage: pathname.slice(1) });
    }
    const isAbleToViewSettings = await AccessControl.can(
      "settings:view-settings"
    );
    const isAbleToViewActionLog = await AccessControl.can(
      "action-log:view-action-log"
    );
    const isAbleToViewBatchPayments = await AccessControl.can(
      "batch-payments:view-batch-payments"
    );
    this.setState({
      isAbleToViewActionLog,
      isAbleToViewSettings,
      isAbleToViewBatchPayments
    });
  };

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props;
    if (prevProps.location.pathname !== pathname) {
      if (pathname.slice(1)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ activePage: pathname.slice(1) });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ activePage: "dashboard" });
      }
    }
  }

  get letters() {
    const {
      AuthStore: { user }
    } = this.props;
    if (!user) {
      return null;
    }
    const parts = user.name.split(" ");
    return (
      parts[0].charAt(0).toUpperCase() +
      parts[parts.length - 1].charAt(0).toUpperCase()
    );
  }

  get userName() {
    const {
      AuthStore: { user }
    } = this.props;
    if (!user) {
      return null;
    }
    return user.name;
  }

  searchHandler = event => {
    event.preventDefault();
    /* eslint-disable-next-line no-console */
    console.log(event.target.search.value);
  };

  logoutHandler = () => {
    const { AuthStore } = this.props;
    AuthStore.logout();
    AuthStore.reset();
  };

  toggleCollapsed = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  };

  onMobileMenuItemSelect = ({ key }) => {
    this.setState({ collapsed: true, activePage: key });
  };

  onDesktopMenuSelect = ({ key }) => {
    this.setState({ activePage: key });
  };

  onDropdownMenuItemClick = ({ key }) => {
    const { history } = this.props;
    switch (key) {
      case "profile":
        history.push("/profile");
        this.setState({ activePage: "profile" });
        break;
      case "logout":
        this.logoutHandler();
        break;
      case "help":
        history.push("/help");
        this.setState({ activePage: "help" });
        break;
      case "settings":
        history.push("/settings");
        this.setState({ activePage: "settings" });
        break;
      default:
        break;
    }
  };

  DropdownMenu = () => {
    const { isAbleToViewSettings } = this.state;
    return (
      <Menu onClick={this.onDropdownMenuItemClick}>
        <Menu.Item key="profile">My Profile</Menu.Item>
        {isAbleToViewSettings && <Menu.Item key="settings">Settings</Menu.Item>}
        <Menu.Item key="help">Help</Menu.Item>
        <Menu.Item key="logout">Log Out</Menu.Item>
      </Menu>
    );
  };

  render() {
    const { Header } = Layout;
    const { collapsed, activePage } = this.state;
    const { isAbleToViewActionLog, isAbleToViewBatchPayments } = this.state;
    return (
      <Header>
        <div className="desktop-menu">
          <div className="logo">
            <Link to="/">
              <img
                className="header-image"
                src={storytrek}
                alt="storytrek-logo"
              />
            </Link>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={["dashboard"]}
            selectedKeys={[`${activePage}`]}
            onSelect={this.onDesktopMenuSelect}
          >
            <Menu.Item key="dashboard">
              dashboard
              <Link to="/" />
            </Menu.Item>
            <Menu.Item key="story-list">
              story list
              <Link to="/story-list" />
            </Menu.Item>
            <Menu.Item key="tasks-list">
              tasks list
              <Link to="/tasks-list" />
            </Menu.Item>
            {isAbleToViewActionLog && (
              <Menu.Item key="action-log">
                actions log
                <Link to="/action-log" />
              </Menu.Item>
            )}
            {isAbleToViewBatchPayments && (
              <Menu.Item key="batch-payments">
                batch payments
                <Link to="/batch-payments" />
              </Menu.Item>
            )}
            {/* <Menu.Item key="schedule"> */}
            {/*  schedule */}
            {/*  <Link to="/schedule" /> */}
            {/* </Menu.Item> */}
            {/* <Menu.Item key="commissions"> */}
            {/*  commissions */}
            {/*  <Link to="/commissions" /> */}
            {/* </Menu.Item> */}
          </Menu>
          <Dropdown
            overlay={this.DropdownMenu}
            trigger={["click"]}
            className="header-dropdown"
            overlayClassName="header-dropdown-overlay"
          >
            <div className="ant-dropdown-link">
              <span className="header-user-name">{this.userName}</span>
              <div className="user-circle">
                <Avatar className="header-avatar" size="large">
                  {this.letters}
                </Avatar>
              </div>
            </div>
          </Dropdown>
        </div>

        {/* mobile menu */}

        <div className="mobile-menu">
          <div>
            <Button
              onClick={this.toggleCollapsed}
              className="mobile-menu-button"
            >
              {collapsed ? mobileMenuBurger : mobileMenuClose}
            </Button>
            <Menu
              defaultSelectedKeys={["dashboard"]}
              selectedKeys={[`${activePage}`]}
              mode="inline"
              theme="light"
              inlineCollapsed={collapsed}
              onSelect={this.onMobileMenuItemSelect}
            >
              <Menu.Item key="dashboard">
                <span>dashboard</span>
                <Link to="/" />
              </Menu.Item>
              <Menu.Item key="story-list">
                <span>story list</span>
                <Link to="/story-list" />
              </Menu.Item>
              <Menu.Item key="tasks-list">
                <span>tasks list</span>
                <Link to="/tasks-list" />
              </Menu.Item>
              {isAbleToViewActionLog && (
                <Menu.Item key="action-log">
                  <span>actions log</span>
                  <Link to="/action-log" />
                </Menu.Item>
              )}
              {isAbleToViewBatchPayments && (
                <Menu.Item key="batch-payments">
                  <span>batch payments</span>
                  <Link to="/batch-payments" />
                </Menu.Item>
              )}
              {/* <Menu.Item key="schedule"> */}
              {/*  <span>schedule</span> */}
              {/*  <Link to="/schedule" /> */}
              {/* </Menu.Item> */}
              {/* <Menu.Item key="commissions"> */}
              {/*  <span>commissions</span> */}
              {/*  <Link to="/commissions" /> */}
              {/* </Menu.Item> */}
            </Menu>
          </div>

          <div className="logo">
            <Link to="/">
              <img
                className="header-image"
                src={storytrek}
                alt="storytrek-logo"
              />
            </Link>
          </div>

          <Dropdown
            overlay={this.DropdownMenu}
            trigger={["click"]}
            className="header-dropdown"
            overlayClassName="header-dropdown-overlay"
          >
            <div className="ant-dropdown-link">
              <span className="header-user-name">Jimmy Ainsworth</span>
              <div className="user-circle">
                <Avatar className="header-avatar" size="large">
                  {this.letters}
                </Avatar>
              </div>
            </div>
          </Dropdown>
        </div>
      </Header>
    );
  }
}

export default withRouter(inject("AuthStore")(observer(HeaderContainer)));
