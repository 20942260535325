import React, { Component } from "react";
import { Card, Icon } from "antd";
import "./styles.less";
import { ReactComponent as iconArrow } from "../../../../images/icons/arrow.svg";

class ExpandableCard extends Component {
  state = {
    isExpanded: false
  };

  componentDidUpdate(prevProps) {
    const { expandedByDefault } = this.props;
    if (expandedByDefault !== prevProps.expandedByDefault) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isExpanded: true });
    }
  }

  onClickHandler = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded
    });
  };

  render() {
    const { isExpanded } = this.state;
    const { cardContent, header, cardContentClass, extra } = this.props;

    const getTitle = () => {
      const style = isExpanded ? "expanded" : "not-expanded";

      return (
        <div className="title" onClick={this.onClickHandler}>
          <span className="title-text">{header}</span>
          <span className="arrow-wrapper">
            <Icon component={iconArrow} className={`arrow ${style}`} />
          </span>
        </div>
      );
    };

    return (
      <Card
        className={`story-panel ${cardContentClass} ${
          isExpanded ? "expanded" : "not-expanded"
        }`}
        bordered={false}
        title={getTitle()}
        extra={extra}
      >
        {isExpanded && cardContent}
      </Card>
    );
  }
}

export default ExpandableCard;
