/**
 * return a publication date of the story
 * @returns {string}
 */

function getPublicationDate(story) {
  const { platforms } = story;
  if (!platforms) {
    return "N/A";
  }
  const platformWithIssue = platforms.find(platform => platform.issue !== null);

  // if an issue is available then use the issue publication date
  if (platformWithIssue) {
    const {
      issue: { title, publication_date }
    } = platformWithIssue;

    return [title, publication_date].join(", ");
  }

  const firstAvailablePublicationDate = platforms.find(
    platform => platform.publication_date !== null
  );

  if (!firstAvailablePublicationDate) {
    return "N/A";
  }

  const { publication_date, publication_time } = firstAvailablePublicationDate;

  return [publication_date, publication_time].filter(Boolean).join(", ");
}

export default getPublicationDate;
