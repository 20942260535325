import React, { Component } from "react";
import { Form, Icon, Input } from "antd";
import { observer } from "mobx-react";
import Button from "../../common/Button";
import { ReactComponent as Pencil } from "../../images/icons/pencil.svg";

class FullNameEditableFields extends Component {
  state = {
    inEdit: false
  };

  iconClickHandler = event => {
    const { inEdit } = this.state;
    const { form } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        inEdit: !inEdit
      },
      () => {
        form.validateFields();
      }
    );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        onChange(values);
      }
    });
  };

  handleCancel = () => {
    const { inEdit } = this.state;
    this.setState({
      inEdit: !inEdit
    });
  };

  render() {
    const { inEdit } = this.state;
    const { form, fullName } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;

    let namesArr;
    let forename;
    let lastname;
    if (fullName) {
      namesArr = fullName.split(" ");
      [forename, lastname] = namesArr;
    }

    const forenameError =
      isFieldTouched("forename") && getFieldError("forename");
    const surnameError = isFieldTouched("surname") && getFieldError("surname");

    return (
      <>
        {inEdit && (
          <div className="custom-editable-form-wrapper">
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              className="editable-form-wrapper"
            >
              <Form.Item
                validateStatus={forenameError ? "error" : ""}
                help={forenameError || ""}
                className="small-field"
              >
                {getFieldDecorator("forename", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your forename!"
                    },
                    {
                      max: 20,
                      min: 1,
                      type: "string",
                      message:
                        "The length of the forename must be between 1 and 20."
                    },
                    {
                      pattern: /^\S+$/,
                      message:
                        "The forename must contain only one word without spaces."
                    }
                  ],
                  initialValue: forename
                })(<Input placeholder="forename" />)}
              </Form.Item>
              <Form.Item
                validateStatus={surnameError ? "error" : ""}
                help={surnameError || ""}
                className="small-field"
              >
                {getFieldDecorator("surname", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your surname!"
                    },
                    {
                      max: 20,
                      min: 1,
                      type: "string",
                      message:
                        "The length of the surname must be between 1 and 20."
                    },
                    {
                      pattern: /^\S+$/,
                      message:
                        "The surname must contain only one word without spaces."
                    }
                  ],
                  initialValue: lastname
                })(<Input placeholder="surname" />)}
              </Form.Item>
              <Form.Item>
                <Button
                  text="Save"
                  htmlType="submit"
                  disabled={this.hasErrors(getFieldsError())}
                  theme="solid-blue"
                  size="small"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  text="Cancel"
                  htmlType="button"
                  theme="blue-outline"
                  size="small"
                  onClick={this.handleCancel}
                />
              </Form.Item>
            </Form>
          </div>
        )}

        {!inEdit && (
          <div className="custom-editable-field-wrapper">
            <div className="description">{fullName}</div>
            <Icon
              className="hoverable"
              onClick={this.iconClickHandler}
              component={Pencil}
            />
          </div>
        )}
      </>
    );
  }
}

export default Form.create()(observer(FullNameEditableFields));
