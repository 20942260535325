import { types } from "mobx-state-tree";
import Approver from "./metaData/Approver";
import CommissionRight from "./metaData/CommissionRight";
import CostExpenseType from "./metaData/CostExpenseType";
import Currency from "./metaData/Currency";
import VatRate from "./metaData/VatRate";
import Account from "./metaData/Account";
import CommissionStatus from "./commissionData/CommissionStatus";

const MetaData = types.model("MetaData", {
  approvers: types.array(Approver),
  commission_rights: types.array(CommissionRight),
  cost_expense_types: types.array(CostExpenseType),
  currencies: types.array(Currency),
  cost_vat_rates: types.array(VatRate),
  chart_of_accounts: types.array(Account),
  chart_of_accounts_count_levels: types.maybeNull(types.number),
  statuses: types.array(CommissionStatus)
});

export default MetaData;
