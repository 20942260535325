import React, { useContext, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, DatePicker, Icon, Row, Select, TimePicker } from "antd";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import { ReactComponent as IconTrash } from "../../images/icons/trash.svg";
import Field from "../../common/FieldContainer";
import Button from "../../common/Button";
import FormContext from "../../models/FormContext";
import SwitchComponent from "../../common/Switch";
import { DATE_FORMAT, PLATFORMS_MAX_COUNT } from "../../config/constants";
import EditableStatus from "../../common/EditableStatus";
import { toOptionArray } from "../../helpers/array-utils";
import { filterOptions } from "../../helpers/filterOptions";
import ModalComponent from "../../common/Modal";

// Issue/Idea select
const IssueSelect = observer(({ platform }) => {
  const field = platform.$("issue_id");
  const issues = field.extra || [];
  return (
    <Col lg={8} xs={24} className="issue-col">
      <Field field={field}>
        <Select showSearch allowClear filterOption={filterOptions}>
          {issues.map(issue => (
            <Select.Option key={issue.id} value={issue.id}>
              {`${issue.title} — ${moment(issue.publication_date).format()}`}
            </Select.Option>
          ))}
        </Select>
      </Field>
    </Col>
  );
});

// Issue Date, Issue Time selects
const DatesSelect = observer(({ platform }) => {
  return (
    <>
      <Col lg={4} md={8} className="publication_date-col">
        <Field field={platform.$("publication_date")}>
          <DatePicker format={DATE_FORMAT} className="issue-date-picker" />
        </Field>
      </Col>
      <Col lg={4} md={6} className="publication_time-col">
        <Field field={platform.$("publication_time")}>
          <TimePicker format="HH:mm" className="issue-time-picker" />
        </Field>
      </Col>
    </>
  );
});

const PlatformForm = ({
  platform,
  platformNames,
  platformStatuses,
  platforms,
  store,
  addPlatformToRemoveList,
  publishTypes
}) => {
  const [showModal, setShowModal] = useState(false);

  const onRemovePlatformHandler = () => {
    setShowModal(true);
  };

  const onSubmitHandler = () => {
    addPlatformToRemoveList(platform.value.id);
    platforms.del(platform.key);
    setShowModal(false);
  };

  const onCancelHandler = () => {
    setShowModal(false);
  };

  const isIdea = platform.$("idea").value;
  const isPrint = store.isPrint(platform.$("platform_name_id").value);

  return (
    <div className="platforms-form">
      <Row gutter={70} className="platform-row">
        {/* brands & platforms */}
        <Col lg={8} md={24} className="platform-col">
          <Field field={platform.$("platform_name_id")}>
            <Select>
              {platformNames &&
                platformNames.map(({ brand, platform_type, id }) => (
                  <Select.Option key={id} value={id}>
                    {`${brand.name} / ${platform_type.name}`}
                  </Select.Option>
                ))}
            </Select>
          </Field>
        </Col>

        {/* Publish Type */}
        <Col lg={4} md={12} className="publish-type-col">
          <Field field={platform.$("platform_publish_type_id")}>
            <Select allowClear>
              {publishTypes &&
                publishTypes.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {`${name}`}
                  </Select.Option>
                ))}
            </Select>
          </Field>
        </Col>

        {/* idea */}
        <Col lg={1} md={1} className="idea-col">
          <Field field={platform.$("idea")}>
            <SwitchComponent />
          </Field>
        </Col>
        {/* Issue/Idea select */}
        {!isIdea && isPrint && <IssueSelect platform={platform} />}
        {/* issue date and issue time selects */}
        {!isIdea && !isPrint && <DatesSelect platform={platform} />}
        {/* editable status */}
        {!isIdea && (
          <Col lg={4} md={4} xs={6} className="status-col">
            <FormItem colon={false} label="" className="platforms-status">
              <EditableStatus
                context="platform"
                customPlaceholder="Change to"
                status={platform.$("platform_status_id").value}
                availableStatuses={toOptionArray(
                  platformStatuses,
                  "color_code"
                )}
                type="dot"
                onChange={status => {
                  platform.update({
                    platform_status_id: status
                  });
                }}
              />
            </FormItem>
          </Col>
        )}
        {/* dustbin */}
        <Col lg={1} md={4} className="delete-col">
          <div className="platform-delete-container">
            <Icon
              component={IconTrash}
              onClick={onRemovePlatformHandler}
              data-testid="create-story-platforms-remove-button"
            />
          </div>
        </Col>
      </Row>
      {/* modal */}
      <ModalComponent
        showModal={showModal}
        onCancel={onCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </div>
  );
};

function Platforms(props) {
  const {
    store: {
      selectOptions: { platformNames, platformStatuses, platformPublishTypes }
    },
    store,
    addPlatformToRemoveList
  } = props;
  const formState = useContext(FormContext);
  const platforms = formState.$("platforms");
  const className =
    platforms.value.length >= PLATFORMS_MAX_COUNT ? "hidden" : "visible";

  return (
    <Card
      className="story-panel platforms-container no-padding"
      bordered={false}
      title="Platforms"
      extra="Add Platforms to show when and where the Story will be completed"
      actions={[
        <Button
          className={className}
          text="Add Platform"
          size="small"
          theme="green-outline"
          icon="add"
          onClick={() => platforms.add()}
        />
      ]}
    >
      {platforms.map((platform, i) => {
        return (
          <PlatformForm
            key={i}
            platform={platform}
            platforms={platforms}
            platformNames={platformNames}
            platformStatuses={platformStatuses}
            store={store}
            addPlatformToRemoveList={addPlatformToRemoveList}
            publishTypes={platformPublishTypes}
          />
        );
      })}
    </Card>
  );
}

export default inject(stores => ({ store: stores.StoryEditStore }))(
  observer(Platforms)
);
