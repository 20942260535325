import React from "react";
import { DatePicker, Form, Icon, Select } from "antd";
import getUrlParams from "../../../../helpers/getUrlParams";
import createSearchString from "../../../../helpers/createSearchString";
import "./styles.less";

function BrandCardsFilters(props) {
  const {
    form,
    filters,
    loadingFilters,
    filtersInitialValues,
    isAbleToViewCompanies
  } = props;
  const { getFieldDecorator } = form;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  return (
    <React.Fragment>
      {!loadingFilters && filters.size && (
        <div className="brand-cards-filters-wrapper">
          <Form>
            <div className="brand-card-filters">
              <Form.Item>
                {getFieldDecorator("date", {
                  initialValue: filtersInitialValues.date
                })(
                  <RangePicker
                    className="custom-filter-date-picker"
                    placeholder={["Date from", "Date to"]}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                  />
                )}
              </Form.Item>
              {isAbleToViewCompanies && (
                <Form.Item>
                  {getFieldDecorator("company", {
                    initialValue: filtersInitialValues.company
                  })(
                    <Select
                      size="large"
                      mode="multiple"
                      className="brand-cards-filters-company-select"
                      placeholder={filters.get("companies").name}
                      suffixIcon={<Icon type="caret-down" theme="filled" />}
                      showArrow
                    >
                      {filters
                        .get("companies")
                        .select_options.map(({ value, label }) => (
                          <Option value={value} key={value}>
                            {label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

const searchEngine = (props, changedFields, allFilterFields) => {
  const { history } = props;
  const usedFilters = {};
  const urlParams = getUrlParams();
  Object.keys(allFilterFields).forEach(fieldName => {
    delete urlParams[fieldName];
    switch (fieldName) {
      case "date":
        delete urlParams.dateFrom;
        delete urlParams.dateTo;
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters.dateFrom = allFilterFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.dateTo = allFilterFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      default:
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFilterFields[fieldName].value.join(",");
        }
        break;
    }
  });
  history.push(createSearchString({ ...usedFilters, ...urlParams }));
};

const formFieldChangeHandler = (...args) => {
  searchEngine(...args);
};

export default Form.create({ onFieldsChange: formFieldChangeHandler })(
  BrandCardsFilters
);
