import React from "react";
import { Upload } from "antd";
import Button from "../Button";
import "./styles.less";

class UploadComponent extends React.Component {
  state = {
    fileList: null
  };

  beforeUpload = () => false;

  changeHandler = value => {
    const { fileList, file } = value;
    const { onFileUploadChange } = this.props;
    this.setState({ fileList });
    onFileUploadChange(file);
  };

  removeHandler = value => {
    const { onRemove } = this.props;
    if (!value.uid.toString().includes("rc-upload")) {
      onRemove(value.uid);
    }
  };

  render() {
    const {
      placeholder,
      uploadButtonLabel,
      uploadButtonSize,
      uploadButtonTheme,
      attachedFiles
    } = this.props;
    const { fileList } = this.state;
    const list = fileList || attachedFiles;
    return (
      <Upload
        beforeUpload={this.beforeUpload}
        onRemove={this.removeHandler}
        onChange={this.changeHandler}
        fileList={list}
      >
        <div className="uploader-container">
          <span className="uploader-placeholder">{placeholder}</span>
          <Button
            text={uploadButtonLabel}
            size={uploadButtonSize}
            theme={uploadButtonTheme}
          />
        </div>
      </Upload>
    );
  }
}
export default UploadComponent;
