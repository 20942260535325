import { types } from "mobx-state-tree";

const Inputs = types.model("Inputs", {
  [`Content-Type`]: types.optional(types.string, ""),
  acl: types.optional(types.string, ""),
  success_action_redirect: types.optional(types.string, ""),
  success_action_status: types.optional(types.string, ""),
  policy: types.optional(types.string, ""),
  [`X-amz-credential`]: types.optional(types.string, ""),
  [`X-amz-algorithm`]: types.optional(types.string, ""),
  [`X-amz-date`]: types.optional(types.string, ""),
  [`X-amz-signature`]: types.optional(types.string, ""),
  key: types.optional(types.string, "")
});

export default Inputs;
