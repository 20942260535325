import { types } from "mobx-state-tree";

const Story = types.model("Story", {
  id: types.optional(types.number, 0),
  category_id: types.optional(types.number, 0),
  content_type_id: types.optional(types.number, 0),
  note: types.maybeNull(types.string),
  parent_id: types.maybeNull(types.number),
  story_status_id: types.optional(types.number, 0),
  title: types.optional(types.string, ""),
  user_id: types.optional(types.number, 0)
});

export default Story;
