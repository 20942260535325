import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Card, Col, Row } from "antd";

const CostAllocationForm = observer(({ costAllocation, commissionInfo }) => {
  const getPercentage = () => {
    let costSum = 0;
    // eslint-disable-next-line no-return-assign
    commissionInfo.costs.forEach(costItem => (costSum += +costItem.cost));
    return ((+costAllocation.amount / costSum) * 100).toFixed(2);
  };
  return (
    <div
      key={`cost-allocation-${costAllocation.id}`}
      className="cost-allocation-form"
    >
      <Row gutter={20}>
        {costAllocation.coa_levels
          .sort((a, b) => a.level - b.level)
          .map(({ level, name }) => (
            <Col lg={4} md={8} key={`cost-allocation-level-${level}`}>
              <div className="label-description-wrapper">
                <span className="label">{`Level ${level}`}</span>
                <div className="description">{name}</div>
              </div>
            </Col>
          ))}
        <Col lg={2} md={4}>
          <div className="label-description-wrapper">
            <span className="label percentage">Percentage</span>
            <div className="description">{getPercentage()}%</div>
          </div>
        </Col>
        <Col lg={2} md={4}>
          <div className="label-description-wrapper">
            <span className="label">Amount</span>
            <div className="description">
              {Number(costAllocation.amount).toFixed(2)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
});

function CostAllocationView({ store: { commissionInfo } }) {
  return (
    <Card
      title="Cost Allocation"
      className="story-panel commission-details"
      bordered={false}
    >
      <Row
        className="costs-description-row cost-allocation-row cost-description-row-view"
        gutter={20}
      >
        <Col lg={6} md={6}>
          <div className="label-description-wrapper">
            <span className="label">Invoice Number</span>
            <div className="description">
              {commissionInfo.invoice_number || "No info"}
            </div>
          </div>
        </Col>
        <Col lg={5} md={5}>
          <div className="label-description-wrapper">
            <span className="label">Invoice Date</span>
            <div className="description">
              {commissionInfo.invoice_date
                ? moment(commissionInfo.invoice_date).format("DD.MM.YYYY")
                : "No info"}
            </div>
          </div>
        </Col>
      </Row>
      {commissionInfo.cost_allocations.map((costAllocation, i) => {
        return (
          <CostAllocationForm
            field={costAllocation}
            key={`cost-allocation-form-${i}`}
            costAllocation={costAllocation}
            commissionInfo={commissionInfo}
          />
        );
      })}
    </Card>
  );
}

export default inject(stores => ({ store: stores.CommissionViewStore }))(
  observer(CostAllocationView)
);
