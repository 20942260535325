import axios from "axios";

const ActionLogService = {
  getFilters: () => {
    return axios
      .get(`/action-log/filters/scope`)
      .then(({ data: { entity } }) => entity);
  },
  getActions: params => {
    return axios
      .get(`/action-log`, { params })
      .then(({ data: { entity } }) => entity);
  }
};

export default ActionLogService;
