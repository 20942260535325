import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Icon } from "antd";
import Details from "./Details";
import Settings from "./Settings";
import "./styles.less";

class UserProfile extends Component {
  componentDidMount() {
    const { UserProfileStore } = this.props;
    UserProfileStore.load();
  }

  onNotifySwitchChange = async value => {
    const { UserProfileStore } = this.props;
    const params = { notify: value };
    await UserProfileStore.changeUserInfo(params);
  };

  onEmailChange = async value => {
    const { UserProfileStore } = this.props;
    const email = value.field;
    const params = { email };
    await UserProfileStore.changeUserInfo(params);
  };

  onFullNameChange = async values => {
    const { UserProfileStore, AuthStore } = this.props;
    const name = `${values.forename} ${values.surname}`;
    const params = { name };
    await UserProfileStore.changeUserInfo(params);
    AuthStore.changeUserName(name);
  };

  onPasswordChange = async values => {
    const { UserProfileStore } = this.props;
    await UserProfileStore.changeUserInfo(values);
  };

  onBackButtonClick = () => {
    const { history } = this.props;
    if (history.length > 1) {
      history.goBack();
    }
  };

  render() {
    const { UserProfileStore } = this.props;
    return (
      <>
        <div className="back-link" onClick={this.onBackButtonClick}>
          <Icon type="arrow-left" />
          Back
        </div>
        <h1 className="page-heading">My profile</h1>
        <Details UserProfileStore={UserProfileStore} />
        <Settings
          UserProfileStore={UserProfileStore}
          onNotifySwitchChange={this.onNotifySwitchChange}
          onEmailChange={this.onEmailChange}
          onFullNameChange={this.onFullNameChange}
          onPasswordChange={this.onPasswordChange}
        />
      </>
    );
  }
}

export default inject("UserProfileStore", "AuthStore")(observer(UserProfile));
