function getHighestKeyFromMap(map) {
  let max = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const index of map.keys()) {
    if (index > max) {
      max = index;
    }
  }
  return max;
}

export default getHighestKeyFromMap;
