import axios from "axios";

const SettingsService = {
  getIntegrationsInfo: () => {
    return axios
      .get("/integration/data-related/scope")
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  getIntegrationList: params => {
    return axios
      .get("/integration/index", { params })
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  saveIntegration: params => {
    const brandId = params.brand_id;
    return axios
      .post(`/integration/save/brand/${brandId}`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  updateIntegration: (id, params) => {
    return axios
      .put(`/integration/update/${id}`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  switchIntegration: (id, params) => {
    return axios
      .patch(`/integration/update/${id}`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  checkConnection: integrationId => {
    return axios
      .patch(`/integration/check-connection/${integrationId}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  }
};

export default SettingsService;
