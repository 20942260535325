import { types } from "mobx-state-tree";

const CommissionRight = types.model("CommissionRight", {
  name: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  file_name: types.optional(types.string, ""),
  file_url: types.optional(types.string, "")
});

export default CommissionRight;
