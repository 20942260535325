import axios from "axios";

const BatchPayment = {
  getMetaData: () => {
    return axios
      .get("/batch-payment/data-related/scope")
      .then(({ data: { entity } }) => entity);
  },
  getBatchPayments: params => {
    return axios
      .get("/batch-payment", { params })
      .then(({ data: { entity } }) => entity);
  },
  createNewBatch: () => {
    return axios.post("/batch-payment/save").then(({ data }) => data);
  },
  changeBatchPaymentStatus: (batchPaymentId, batchPaymentStatusId) => {
    return axios
      .patch(`/batch-payment/update/${batchPaymentId}`, {
        batch_payment_status_id: batchPaymentStatusId
      })
      .then(({ data: { entity } }) => entity);
  }
};

export default BatchPayment;
