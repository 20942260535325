import { types } from "mobx-state-tree";
import UserRole from "./UserRole";

const CreatorUser = types.model("CreatorUser", {
  name: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  email: types.optional(types.string, ""),
  job_title: types.maybeNull(types.optional(types.string, "")),
  job_role: types.maybeNull(types.optional(types.string, "")),
  can_approve: types.optional(types.boolean, false),
  user_addition_id: types.maybeNull(types.number),
  roles: types.array(types.frozen(UserRole))
});

export default CreatorUser;
