/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { asyncReactor } from "async-reactor";
import { Table, Tooltip } from "antd";
import { observer, inject, Observer, disposeOnUnmount } from "mobx-react";
import "./styles.less";
import "../../styles/variables.less";
import { reaction } from "mobx";
import createSearchString from "../../helpers/createSearchString";
import arrow from "../../common/paginationIcons";
import EditableStatus from "../../common/EditableStatus";
import getUrlParams from "../../helpers/getUrlParams";
import Button from "../../common/Button";
import AccessControl from "../../models/AccessControl";
import ApproveButtonWithModal from "../StoryViewPage/components/ApproveButtonWithModal";
import { getColorForCommissionStatus } from "../../helpers/getColorForStatus";
import Filters from "./Filters";
import canChangeAssignmentStatus from "../../helpers/accessControl";
import { pageSizeOptions } from "../../constants/pagination";
import { ViewContentButton } from "../StoryViewPage/Assignments";

const ManageContentButton = asyncReactor(async ({ assignment, status }) => {
  const isAbleToManageContent = await AccessControl.can(
    "assignments:manage-content",
    { assignment }
  );
  if (!isAbleToManageContent || status.slug === "complete") {
    return null;
  }
  return (
    <div className="assignment-button manage-content-button">
      <Button
        href={`/manage-content/${assignment.id}`}
        theme="primary-link"
        htmlType="link"
        size="small"
        text="Manage Content"
        icon="upload"
        onClick={e => e.stopPropagation()}
      />
    </div>
  );
});

const ApproveContentButton = asyncReactor(
  async ({ assignment, store, status, profile }) => {
    await profile.load();
    const canApproveContent = await AccessControl.can(
      "assignments:approve-content",
      { assignment, approver: profile.details.Approver }
    );

    if (!canApproveContent || status.slug === "complete") {
      return null;
    }

    return <ApproveButtonWithModal store={store} assignment={assignment} />;
  }
);

const CommissionButtons = ({ assignment }) => {
  if (assignment.commissions) {
    return assignment.commissions.map(commission => {
      const commissionCode = `0000${commission.id}`.slice(-5);
      const buttonText = (
        <div className="button-text-container">
          <span>Commission {commissionCode}</span>
          <div className="status-container">
            <span
              className="status-dot"
              style={{
                backgroundColor: getColorForCommissionStatus(
                  commission.status_slug
                )
              }}
            />
            <span className="status-name">{commission.status}</span>
          </div>
        </div>
      );
      return (
        <Button
          key={commission.id}
          text={buttonText}
          theme="black-outline"
          size="small"
          htmlType="link"
          href={`/assignment/${assignment.id}/commission/${commission.id}`}
          className="commission-button"
          onClick={e => e.stopPropagation()}
        />
      );
    });
  }
  return null;
};

const CreateCommissionButton = asyncReactor(async ({ assignment }) => {
  const canCreateCommission = await AccessControl.can(
    "assignments:create-commission",
    { assignment }
  );
  if (!canCreateCommission) {
    return null;
  }
  return (
    <Button
      theme="black-outline"
      size="small"
      htmlType="link"
      href={`/assignment/${assignment.id}/create-commission`}
      text="Create Commission"
      className="create-commission-button assignment-button"
      onClick={e => e.stopPropagation()}
    />
  );
});

class TasksList extends Component {
  componentDidMount = async () => {
    const { TasksListStore } = this.props;
    TasksListStore.loadFilters();
    TasksListStore.setAppliedFilters();
    TasksListStore.loadStatuses();
    disposeOnUnmount(
      this,
      reaction(
        () => TasksListStore.filtersRequestParams,
        () => TasksListStore.loadTasks(TasksListStore.filtersRequestParams),
        { fireImmediately: true }
      )
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      TasksListStore: { setAppliedFilters },
      location: { search }
    } = this.props;
    if (prevProps.location.search !== search) {
      setAppliedFilters();
    }
  }

  getColumns(UserProfileStore) {
    return [
      {
        title: "story title",
        dataIndex: "story.title",
        key: "story_title",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: this.props.TasksListStore.filtersInitialValues.sorting
          .storyTitle,
        render: (title, row) => {
          const availableStatuses = this.props.TasksListStore.storyStatuses
            ?.select_options;
          return (
            <>
              <span className="table-data-text">{title}</span>
              <Observer>
                {() => (
                  <EditableStatus
                    context="story"
                    status={row.story.story_status}
                    availableStatuses={availableStatuses}
                    onChange={this.handleSingleStoryStatusChange(row)}
                  />
                )}
              </Observer>
            </>
          );
        }
      },
      {
        title: "story type",
        dataIndex: "story.content_type.name",
        key: "content_type",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: this.props.TasksListStore.filtersInitialValues.sorting
          .contentType,
        render: (type, row) => {
          const { category } = row.story;
          return (
            <div className="content-type-column">
              <span className="table-data-text">{type}</span>
              <span className="table-data-text category-name">
                {`${category.parent.name} > ` || ""}
                {category.name || ""}
              </span>
            </div>
          );
        }
      },
      {
        title: "task type",
        dataIndex: "type",
        key: "type",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: this.props.TasksListStore.filtersInitialValues.sorting
          .taskType,
        render: (type, row) => (
          <div className="assignment-type">
            <span className={`assignment-icon ${row.typeKey}`} />
            <span>{type.name}</span>
          </div>
        )
      },
      {
        title: "assignee",
        key: "user",
        dataIndex: "user",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: this.props.TasksListStore.filtersInitialValues.sorting
          .assignee,
        render: (user, row) => {
          const task_creator = row.user_creator?.name
            ? `Task Creator: ${row.user_creator.name} / ${row.user_creator.job_title}`
            : "";
          return (
            <Tooltip placement="top" title={task_creator}>
              <div className="content-type-column">
                <span className="table-data-text">{user.name}</span>
                <span className="table-data-text category-name">
                  {user.job_title}
                </span>
              </div>
            </Tooltip>
          );
        }
      },
      {
        title: "task brief",
        dataIndex: "task_brief",
        key: "task_brief",
        render: text => {
          return <span className="table-data-text">{text}</span>;
        }
      },
      {
        title: "task deadline",
        dataIndex: "deadline",
        key: "deadline",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        width: "200px",
        defaultSortOrder: this.props.TasksListStore.filtersInitialValues.sorting
          .taskDeadline,
        render: (deadline, row) => {
          const assigmentStatusObj = row.assignment_status;
          const userRole = this.props.AuthStore?.user?.roles?.[0]?.name;
          const isEditable = canChangeAssignmentStatus(
            userRole,
            assigmentStatusObj.slug
          );
          const {
            TasksListStore: { assignmentStatuses }
          } = this.props;

          return (
            <>
              <span>{row.deadlineDate || "No date"}</span>
              <div>
                <Observer>
                  {() => (
                    <EditableStatus
                      status={assigmentStatusObj}
                      type="dot"
                      context="assignment"
                      availableStatuses={assignmentStatuses?.select_options}
                      editable={isEditable}
                      onChange={statusId => {
                        this.props.TasksListStore.changeAssignmentStatus(
                          row.id,
                          statusId
                        );
                      }}
                    />
                  )}
                </Observer>
              </div>
            </>
          );
        }
      },
      {
        title: "actions",
        dataIndex: "assignment_status",
        key: "assignment_status",
        className: "story-list-table-th-assignments",
        render: (status, assignment) => {
          return (
            <div className="tasks-list-actions-wrapper">
              {status.slug === "complete" ? (
                <ViewContentButton assignment={assignment} />
              ) : (
                <ManageContentButton assignment={assignment} status={status} />
              )}
              {status.slug !== "complete" && (
                <ApproveContentButton
                  assignment={assignment}
                  store={this.props.TasksListStore}
                  status={status}
                  profile={UserProfileStore}
                />
              )}
              <CommissionButtons assignment={assignment} />
              {assignment.assignment_status.slug !== "complete" && (
                <CreateCommissionButton assignment={assignment} />
              )}
            </div>
          );
        }
      }
    ];
  }

  handleSingleStoryStatusChange = row => status => {
    const { TasksListStore } = this.props;
    TasksListStore.changeStoryStatus(
      TasksListStore.statusPatch(status),
      row.story_id
    );
  };

  tableSortingAndPaginationHandler = (pagination, tableFilters, sorter) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    urlParams.page = pagination.current;

    const namesMap = new Map([
      ["story.title", "storyTitle"],
      ["story.content_type.name", "contentType"],
      ["type", "taskType"],
      ["deadline", "taskDeadline"],
      ["user", "assignee"]
    ]);

    switch (sorter.order) {
      case "ascend":
        urlParams.sortByAsc = namesMap.get(sorter.field);
        delete urlParams.sortByDesc;
        break;
      case "descend":
        urlParams.sortByDesc = namesMap.get(sorter.field);
        delete urlParams.sortByAsc;
        break;
      default:
        delete urlParams.sortByAsc;
        delete urlParams.sortByDesc;
    }
    history.push(createSearchString(urlParams));
  };

  paginationItemRender = (page, type) => {
    if (type === "prev") {
      return (
        <span className="pagination-arrow-back">
          <span>{arrow}</span>
          Back
        </span>
      );
    }
    if (type === "next") {
      return (
        <span className="pagination-arrow-next">
          Next
          <span>{arrow}</span>
        </span>
      );
    }
    return null;
  };

  showTotal = (totalItems, fromToArr) => {
    const {
      TasksListStore: { total }
    } = this.props;
    return `${fromToArr[0]}-${fromToArr[1]} of ${total}`;
  };

  onItemsPerPageChange = (currentPage, newSize) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    history.push(createSearchString({ ...urlParams, limit: newSize }));
  };

  tableRowClickHandler = record => {
    const { history } = this.props;
    return {
      onClick: () => {
        history.push({
          pathname: `/story/${record.story_id}`,
          search: `taskId=${record.id}`,
          from: "task-list"
        });
      }
    };
  };

  render() {
    const {
      TasksListStore: store,
      TasksListStore: {
        perPage,
        total,
        currentPageNumber,
        filters,
        loadingFilters,
        filtersInitialValues
      },
      UserProfileStore,
      history
    } = this.props;

    return (
      <div className="tasks-list">
        <Filters
          filters={filters}
          loadingFilters={loadingFilters}
          filtersInitialValues={filtersInitialValues}
          history={history}
        />
        <Table
          columns={this.getColumns(UserProfileStore)}
          dataSource={store.tasks}
          rowKey="id"
          onChange={this.tableSortingAndPaginationHandler}
          loading={store.loading}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions.low,
            onShowSizeChange: this.onItemsPerPageChange,
            pageSize: perPage,
            size: "large",
            total,
            showTotal: this.showTotal,
            itemRender: this.paginationItemRender,
            current: currentPageNumber
          }}
          onRow={this.tableRowClickHandler}
        />
      </div>
    );
  }
}

export default inject("TasksListStore", "AuthStore", "UserProfileStore")(
  observer(TasksList)
);
