import { types } from "mobx-state-tree";
import File from "./File";
import Link from "./Link";
import AssignmentType from "./AssignmentType";
import Story from "./Story";
import StoryStatus from "../common/story-status";

const Assignment = types
  .model("Assignment", {
    id: types.optional(types.number, 0),
    story_id: types.optional(types.number, 0),
    user_id: types.optional(types.number, 0),
    type_id: types.optional(types.number, 0),
    notify_user: types.optional(types.boolean, false),
    task_brief: types.optional(types.string, ""),
    deadline: types.maybeNull(types.string),
    assignment_status_id: types.optional(types.number, 0),
    description: types.maybeNull(types.string),
    files: types.optional(types.array(File), []),
    links: types.optional(types.array(Link), []),
    assignment_status: types.optional(StoryStatus, () => StoryStatus.create()),
    type: types.optional(types.frozen(AssignmentType), {}),
    story: types.optional(types.frozen(Story), {})
  })
  .actions(self => ({
    addFile(newFile) {
      self.files.push(newFile);
    },
    addLink(newlink) {
      self.links.push(newlink);
    }
  }));

export default Assignment;
