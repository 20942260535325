import React from "react";
import { debounce } from "throttle-debounce";
import { DatePicker, Form, Icon, Input, Select, Spin } from "antd/lib/index";
import "../../../common/RangePicker/styles.less";
import createSearchString from "../../../helpers/createSearchString";
import getUrlParams from "../../../helpers/getUrlParams";
import { ReactComponent as iconSearch } from "../../../images/icons/icon-search.svg";
// import styles
import "./styles.less";
import "../../../styles/search-input.less";

function ActionLogFilters(props) {
  const { loadingFilters, filters, form, filtersInitialValues } = props;

  const { getFieldDecorator } = form;

  const { RangePicker } = DatePicker;

  const { Option } = Select;

  return (
    <React.Fragment>
      {loadingFilters && (
        <div className="filters-spinner">
          <Spin size="large" />
        </div>
      )}
      {!loadingFilters && filters.entities.length && filters.events.length && (
        <div className="story-filters-wrapper">
          <Form>
            <div className="story-filters">
              <Form.Item>
                {getFieldDecorator("date", {
                  initialValue: filtersInitialValues.date
                })(
                  <RangePicker
                    className="custom-filter-date-picker"
                    placeholder={["Date from", "Date to"]}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("entity", {
                  initialValue: filtersInitialValues.entity
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Entity"
                    className="story-filters-brand-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters.entities.map((entity, index) => (
                      <Option value={entity.entity} key={index}>
                        {entity.entity}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("event", {
                  initialValue: filtersInitialValues.event
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Event"
                    className="story-filters-brand-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters.events.map((event, index) => (
                      <Option value={event.event} key={index}>
                        {event.event}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("entityId", {
                  initialValue: filtersInitialValues.entityId
                })(
                  <Input
                    placeholder="Entity ID"
                    prefix={<Icon component={iconSearch} />}
                    size="large"
                    className="search-input entity-search"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("searchCommon", {
                  initialValue: filtersInitialValues.searchCommon
                })(
                  <Input
                    placeholder="Account, IP, Request and Values"
                    prefix={<Icon component={iconSearch} />}
                    size="large"
                    className="search-input common-search"
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

const searchEngine = (props, changedFields, allFilterFields) => {
  const { history } = props;
  // 1-create object, in which we will add only necessary filters later
  const usedFilters = {};
  // 2-get previous url params from url (including sorting, pagination, limit)
  const urlParams = getUrlParams();
  // 3-iterate over all fields, delete from urlParams fields with old filters, create & set fields only for applied filters
  Object.keys(allFilterFields).forEach(fieldName => {
    delete urlParams[fieldName];
    switch (fieldName) {
      case "entityId":
      case "searchCommon":
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFilterFields[fieldName].value;
        }
        break;
      case "date":
        delete urlParams.dateFrom;
        delete urlParams.dateTo;
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters.dateFrom = allFilterFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.dateTo = allFilterFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      default:
        if (
          allFilterFields[fieldName].value &&
          allFilterFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFilterFields[fieldName].value.join(",");
        }
    }
  });
  history.push(createSearchString({ ...usedFilters, ...urlParams, page: 1 }));
};

const debouncedSearchEngine = debounce(1000, searchEngine);

const formFieldChangeHandler = (...args) => {
  const changedFieldNames = Object.keys(args[1]);

  if (
    changedFieldNames.includes("entityId") ||
    changedFieldNames.includes("searchCommon")
  ) {
    debouncedSearchEngine(...args);
    return;
  }

  searchEngine(...args);
};

export default Form.create({ onFieldsChange: formFieldChangeHandler })(
  ActionLogFilters
);
