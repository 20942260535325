/* eslint-disable class-methods-use-this */
import { Form } from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

export default class CommissionFormState extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs)
    };
  }
}
