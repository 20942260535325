import axios from "axios";

const CommissionService = {
  getCommissionInfo: commissionId => {
    return axios
      .get(`/commission/edit/${commissionId}`)
      .then(({ data: { entity } }) => entity);
  },
  getMetadata: assignmentId => {
    return axios
      .get(`/commission/data-related/scope/assignment/${assignmentId}`)
      .then(({ data: { entity } }) => entity);
  },
  getCreateCommissionData: assignmentId => {
    return axios
      .get(`/commission/assignment/${assignmentId}/create`)
      .then(({ data: { entity } }) => entity);
  },
  save(rawValues, assignmentId) {
    const id = rawValues.get("id");
    if (id) {
      return axios
        .post(`/commission/update/${id}`, rawValues)
        .then(({ data }) => data);
    }
    return axios
      .post(`/commission/assignment/${assignmentId}/save`, rawValues)
      .then(({ data }) => data);
  },
  updateStatus: (commissionId, statusId) => {
    return axios
      .patch(`/commission/update/${commissionId}`, {
        commission_status_id: statusId
      })
      .then(({ data: { entity } }) => entity);
  },
  updateStatusForInternalUsers: (commissionId, params) => {
    return axios
      .patch(`commission/update/${commissionId}/status/internal`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  updateStatusForExternalUsers: (commissionId, params) => {
    return axios
      .patch(`/commission/update/${commissionId}/status/external`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  }
};

export default CommissionService;
