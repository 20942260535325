import React from "react";
import "./styles.less";
import { DatePicker, Icon } from "antd";

function RangePickerComponent({ onChange }) {
  const { RangePicker } = DatePicker;
  return (
    <RangePicker
      className="custom-filter-date-picker"
      placeholder={["Date from", "Date to"]}
      suffixIcon={<Icon type="caret-down" theme="filled" />}
      onChange={onChange}
    />
  );
}

export default RangePickerComponent;
