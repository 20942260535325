import axios from "axios";

const Stories = {
  load: id => {
    return axios
      .get(`/story/edit/${id}`)
      .then(({ data: { entity } }) => entity);
  },

  getStories: params => {
    return axios
      .get("/story", { params })
      .then(({ data: { entity } }) => entity);
  },
  getPlatformNames: () => {
    return axios
      .get("/story/platform/name")
      .then(({ data: { entity } }) => entity);
  },

  getContentTypes: () => {
    return axios
      .get("/story/contenttype")
      .then(({ data: { entity } }) => entity);
  },

  getPlatformStatuses: () => {
    return axios
      .get("/story/platform/status")
      .then(({ data: { entity } }) => entity);
  },

  getDataForStoryListFilters: () => {
    return axios.get(`/story/list/data/scope`).then(({ data: { entity } }) => {
      return entity;
    });
  },

  getAssignmentTypes: () => {
    return axios
      .get("/story/assignment/type")
      .then(({ data: { entity } }) => entity);
  },
  getAssignmentStatuses: () => {
    return axios
      .get("/story/assignment/status")
      .then(({ data: { entity } }) => entity);
  },
  getAssignees: () => {
    return axios
      .get("/user/assignments")
      .then(({ data: { entity } }) => entity);
  },
  getIssuesByBrand: brandId => {
    return axios.get(`/story/platform/issue/brand/${brandId}`).then(
      ({
        data: {
          entity: { data }
        }
      }) => data
    );
  },

  getMetadata: id => {
    if (id) {
      return axios
        .get(`/story/data/scope/${id}`)
        .then(({ data: { entity } }) => entity);
    }
    return axios
      .get("/story/data/scope")
      .then(({ data: { entity } }) => entity);
  },

  changeStoryStatus: (story, status) => {
    const statusId = Number.isInteger(status) ? status : status.id;
    return axios
      .patch(`/story/update/${story.id}/status`, {
        story_status_id: statusId
      })
      .then(({ data: { entity } }) => entity);
  },

  save(rawValues) {
    const id = rawValues.get("id");
    if (id) {
      return axios
        .post(`/story/update/${id}`, rawValues)
        .then(({ data }) => data);
    }
    return axios.post("/story/save", rawValues).then(({ data }) => data);
  },

  deleteAttachment: attachmentId => {
    return axios
      .delete(`/story/attachment/delete/${attachmentId}`)
      .then(({ data }) => data);
  },

  getSignatureData: id => {
    return axios
      .get(`/story/${id}/attachment/s3/credentials`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },

  uploadFileToS3: params => {
    return axios
      .post(`https://hermes-bucket-test.s3-eu-west-2.amazonaws.com/`, params, {
        transformRequest: (data, headers) => {
          delete headers.Authorization;
          return data;
        }
      })
      .then(({ data }) => {
        return data;
      });
  },

  sendUploadedFileInfo: (id, params) => {
    return axios
      .post(`/story/${id}/attachment/save`, params)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },

  approveContent: assignmentId => {
    return axios
      .post(`/content/assignment/${assignmentId}/approve`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },

  getStatuses: () => {
    return axios.get(`/story/edit/data/scope`).then(({ data: { entity } }) => {
      return entity;
    });
  },

  changeAssignmentStatus: (assignmentId, assignment_status_id) => {
    return axios
      .patch(`/story/assignment/update/${assignmentId}`, {
        assignment_status_id
      })
      .then(({ data: { entity } }) => {
        return entity;
      });
  },

  getIssuesByPlatformName: platformNameId => {
    return axios
      .get(`story/platform/issue/platform-name/${platformNameId}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },

  deleteStory: storyId => {
    return axios
      .delete(`/story/delete/${storyId}`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  deleteLinkedStory: (childId, parentId) => {
    return axios
      .delete(`/story/delete/linked/${childId}/${parentId}`)
      .then(res => res.data);
  },
  linkStory: (parentId, childId) => {
    return axios
      .post(`/story/save/linked/${parentId}/${childId}`)
      .then(res => res.data);
  }
};

export default Stories;
