import React, { useContext } from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import Switch from "../../common/Switch";
import Field from "../../common/FieldContainer";
import FormContext from "../../models/FormContext";

function SpecialTerms() {
  const formState = useContext(FormContext);
  const isSpecialTerms = formState.$("special_terms").value;
  const extraContent = (
    <Field name="special_terms">
      <Switch />
    </Field>
  );
  const renderSpecialTermsForm = () => {
    return (
      <Row gutter={40}>
        <Col lg={8} md={8}>
          <Field name="special_terms_description">
            <TextArea rows={3} />
          </Field>
        </Col>
        <Col lg={4} md={4}>
          <Field name="special_terms_reuse">
            <Input />
          </Field>
        </Col>
        <Col lg={4} md={4}>
          <Field name="special_terms_reuse_delay">
            <Input />
          </Field>
        </Col>
      </Row>
    );
  };

  return (
    <Card
      className="special-terms-panel"
      title="Special Terms"
      bordered={false}
      extra={extraContent}
    >
      {isSpecialTerms && renderSpecialTermsForm()}
    </Card>
  );
}

export default inject(stores => ({ store: stores.CommissionStore }))(
  observer(SpecialTerms)
);
