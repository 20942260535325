import axios from "axios";

const Auth = {
  login: params => axios.post("/auth/login", params),
  logout: token => axios.get("/auth/logout", token),
  reset: email => axios.post("/auth/password/email", email),
  confirmReset: params => axios.put("/auth/password/reset", params),
  authorizeByToken: () => axios.get("/user/info")
};

export default Auth;
