import moment from "moment";

export default function getFiltersInitialValues(
  search = window.location.search
) {
  const searchParams = new URLSearchParams(search);
  const filtersInitialValues = {};
  filtersInitialValues.date = [];
  filtersInitialValues.sorting = {};
  Array.from(searchParams.entries()).forEach(entry => {
    const [key, value] = entry;
    switch (key) {
      case "dateFrom":
        filtersInitialValues.date.push(moment(value));
        break;
      case "dateTo":
        filtersInitialValues.date.push(moment(value));
        break;
      case "sortByDesc":
        filtersInitialValues.sorting[value] = "descend";
        break;
      case "sortByAsc":
        filtersInitialValues.sorting[value] = "ascend";
        break;
      default:
        if (Number(value)) {
          filtersInitialValues[key] = [Number(value)];
        } else {
          filtersInitialValues[key] = value.split(",");
        }
        break;
    }
  });
  return filtersInitialValues;
}
