import { Switch } from "antd";
import React from "react";
import "./styles.less";

function SwitchComponent(props, ref) {
  const { onChange, value = false, label } = props;
  return (
    <div className="switch-container">
      <Switch
        checked={value}
        ref={ref}
        onChange={onChange}
        data-testid={`create-story-${label
          .toLowerCase()
          .replace(" ", "-")}-switch`}
      />
    </div>
  );
}

export default React.forwardRef(SwitchComponent);
