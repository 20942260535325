import React, { useContext, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, DatePicker, Icon, Input, Row, Select } from "antd";
import Button from "../../common/Button";
import FormContext from "../../models/FormContext";
import Field from "../../common/FieldContainer";
import { ReactComponent as IconTrash } from "../../images/icons/trash.svg";
import ModalComponent from "../../common/Modal/index";
import { DATE_FORMAT } from "../../config/constants";

const CostAllocationForm = observer(
  ({
    field,
    costAllocation,
    chartOfAccounts,
    costAllocations,
    commissionId,
    costs,
    addCostAllocationToRemoveList
  }) => {
    const getSelectsOptions = selectLevel => {
      if (chartOfAccounts) {
        return chartOfAccounts
          .filter(option => option.level === selectLevel)
          .map(({ id, name }) => {
            return (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            );
          });
      }
      return undefined;
    };

    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
      const amount = +costAllocation.$("amount").value;
      if (commissionId && amount) {
        let costSum = 0;
        costs.forEach(cost => {
          costSum += +cost.$("cost").value;
        });
        const percentage = (amount / costSum) * 100;
        costAllocation.$("percentage").set("value", percentage);
      }
    }, [costs, commissionId, costAllocation]);

    const onRemoveCostAllocationHandler = () => {
      setShowModal(true);
    };
    const onSubmitHandler = () => {
      if (field.value.id) {
        addCostAllocationToRemoveList(+field.value.id);
      }
      costAllocations.del(field.key);
      setShowModal(false);
    };
    const onCancelHandler = () => {
      setShowModal(false);
    };
    costAllocation
      .$("coa_levels")
      .set(
        "value",
        costAllocation.$("coa_levels").value.sort((a, b) => a.level - b.level)
      );
    return (
      <div
        key={`cost-allocation-${costAllocation.id}`}
        className="cost-allocation-form"
      >
        <Row gutter={20}>
          {costAllocation.$("coa_levels").map((costAllocationLevel, index) => {
            const level = costAllocationLevel.value.level || index + 1;
            return (
              <Col
                lg={4}
                md={8}
                key={`cost-allocation-level-${level}`}
                className="level-col"
              >
                <Field
                  field={costAllocationLevel.$(`id`)}
                  label={`Level ${level}`}
                >
                  <Select
                    allowClear
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    dropdownMatchSelectWidth={false}
                  >
                    {getSelectsOptions(level)}
                  </Select>
                </Field>
              </Col>
            );
          })}
          <Col lg={2} md={4} className="percentage-col">
            <Field field={field.$("percentage")}>
              <Input />
            </Field>
          </Col>
          <Col lg={2} md={4}>
            <div className="amount-and-cost-allocation-delete-container">
              <Field field={field.$("amount")}>
                <Input />
              </Field>
              {costAllocations.fields.size !== 1 && (
                <Icon
                  component={IconTrash}
                  onClick={onRemoveCostAllocationHandler}
                />
              )}
              <ModalComponent
                onCancel={onCancelHandler}
                onSubmit={onSubmitHandler}
                showModal={showModal}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
);

function CostAllocation({
  chartOfAccounts,
  customValidationFailed,
  commissionId,
  addCostAllocationToRemoveList
}) {
  const formState = useContext(FormContext);
  const costs = formState.$("costs").fields;
  const costAllocations = formState.$("cost_allocations");

  const onAutoAllocateHandler = () => {
    let costSum = 0;
    costs.forEach(cost => {
      costSum += +cost.$("cost").value;
    });
    let oneAmount = 0;
    const percentage = 100;
    if (costSum) {
      oneAmount = +(costSum / costAllocations.fields.size);
    }
    const onePercentage = +(percentage / costAllocations.fields.size);
    costAllocations.fields.forEach(costAllocation => {
      costAllocation.$("percentage").set("value", onePercentage);
      costAllocation.$("amount").set("value", oneAmount);
    });
  };
  const extraContent = (
    <Button
      text="Auto Allocate"
      size="small"
      theme="black-outline"
      onClick={onAutoAllocateHandler}
    />
  );

  const className = costAllocations.value.length >= 15 ? "hidden" : "visible";
  const validationClassName = customValidationFailed
    ? "custom-validation-failed"
    : "";
  const validationFailExplanation =
    "The amount's sum of all Cost Allocation Items must be equal to the sum of all Cost Items cost fields! ";
  return (
    <div>
      <Card
        title="Cost Allocation"
        className={`cost-allocation-panel ${validationClassName}`}
        bordered={false}
        extra={extraContent}
        actions={[
          <Button
            text="New Cost Allocation"
            className={className}
            size="small"
            theme="green-outline"
            icon="add"
            onClick={costAllocations.onAdd}
          />
        ]}
      >
        <Row className="costs-description-row cost-allocation-row" gutter={20}>
          <Col lg={6} md={6}>
            <Field name="invoice_number">
              <Input />
            </Field>
          </Col>
          <Col lg={5} md={5} className="invoice-date-col">
            <Field name="invoice_date">
              <DatePicker format={DATE_FORMAT} />
            </Field>
          </Col>
        </Row>
        {costAllocations.map((costAllocation, i) => {
          return (
            <CostAllocationForm
              field={costAllocation}
              key={`cost-allocation-form-${i}`}
              costAllocation={costAllocation}
              costAllocations={costAllocations}
              chartOfAccounts={chartOfAccounts}
              costs={costs}
              commissionId={commissionId}
              addCostAllocationToRemoveList={addCostAllocationToRemoveList}
            />
          );
        })}
      </Card>
      {customValidationFailed && (
        <div className="ant-form-explain validation-text-explanation">
          {validationFailExplanation}
        </div>
      )}
    </div>
  );
}

export default inject(stores => ({
  store: stores.CommissionStore,
  chartOfAccounts: stores.CommissionStore.options.chart_of_accounts
}))(observer(CostAllocation));
