import React from "react";
import { observer } from "mobx-react";
import { Card, Col, Row } from "antd";
import getCorrectUrl from "../../helpers/getCorrectUrl";

function Platforms({ story }) {
  return (
    <Card className="story-panel" bordered={false} title="Platforms">
      {story.platforms.map((platform, i) => {
        const issue = platform.platformIssue;
        const hasIssue = !!issue;
        return (
          <Row
            key={`platform-description-${i}`}
            className="bordered platforms-row"
            gutter={20}
            type="flex"
          >
            {/* Brands & Platforms */}
            <Col lg={7} xs={24}>
              <span className="label">Platforms & Platform Types</span>
              <div className="description">
                <div className="brand-icon">
                  <img
                    width={24}
                    height={24}
                    src={getCorrectUrl(platform.platform_name.brand.icon_url)}
                    alt="brand-icon"
                  />
                </div>
                {platform.platform_name.brandAndPlatformName}
              </div>
            </Col>

            {/* Publish Type */}
            <Col lg={3} xs={24}>
              <span className="label">Schedule</span>
              <div className="description">{platform.publishTypeName}</div>
            </Col>

            {/* Issue */}
            {hasIssue && (
              <Col lg={5} xs={24}>
                <span className="label">Issue</span>
                <div className="description">{platform.platformIssue}</div>
              </Col>
            )}
            {/* Issue Date & Issue Time */}
            {!hasIssue && (
              <>
                <Col lg={5} xs={24}>
                  <span className="label">Date</span>
                  <div className="description">
                    {platform.platformIssueDate}
                  </div>
                </Col>
                <Col lg={5} xs={24}>
                  <span className="label">Time</span>
                  <div className="description">
                    {platform.platformIssueTime}
                  </div>
                </Col>
              </>
            )}
            {/* Status */}
            <Col
              lg={4}
              xs={24}
              style={{ marginLeft: "auto", textAlign: "right" }}
            >
              <div className="description status">
                <span
                  className="status-dot"
                  style={{
                    backgroundColor: platform.platform_status?.color_code
                  }}
                />{" "}
                <span className="bold">
                  {platform.platform_status
                    ? platform.platform_status.name
                    : "Idea"}
                </span>
              </div>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
}

export default observer(Platforms);
