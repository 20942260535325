import React from "react";
import Button from "../../../common/Button";

const cardHeadButtons = (id, companyId) => (
  <div className="title-buttons">
    <Button
      pathname={`/story-list/${id}`}
      search={`?company=${companyId}`}
      from={`/story/${id}`}
      htmlType="link"
      text="Link Story"
      size="small"
      icon="link"
      theme="blue-outline"
    />
    <Button
      pathname={`/create-linked-story/${id}`}
      from={`/story/${id}`}
      htmlType="link"
      text="New Story"
      size="small"
      icon="add"
    />
  </div>
);

export default cardHeadButtons;
