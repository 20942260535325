import React from "react";
import { Modal } from "antd/lib/index";
import Button from "../../../../common/Button";

export default function EditCommissionModal({ onCancel, onSubmit, showModal }) {
  return (
    <Modal
      title="Are you sure you want to edit the Commission?"
      visible={showModal}
      centered
      className="modal-container"
      footer={
        // eslint-disable-next-line
        <>
          <Button
            theme="blue-outline"
            text="Cancel"
            size="large"
            onClick={onCancel}
          />
          <Button
            theme="black-outline"
            text="Edit"
            size="large"
            onClick={onSubmit}
            className="modal-edit-button"
          />
        </>
      }
      onCancel={onCancel}
    >
      <div className="modal-text">
        Please note: upon saving the edited commission, the approver will be
        resent the approval email again for review.
        <br />
        If an entirely new commission is required, a new Assignment is required
        on the Story.
      </div>
    </Modal>
  );
}
