import React, { Component } from "react";
import { Row, Col, Table, Icon } from "antd";
import "./styles.less";
import { inject, observer, Observer } from "mobx-react";
import BrandAndPlatformName from "../../../common/BrandAndPlatformName";
import { burger } from "../../StoryList/icons/burger";
import getPublicationDate from "../../../helpers/getPublicationDate";
import EditableStatus from "../../../common/EditableStatus";
import { ReactComponent as Unlink } from "../../../images/icons/unlink.svg";
import AccessControl from "../../../models/AccessControl";
import arrow from "../../../common/paginationIcons";
import { pageSizeOptions } from "../../../constants/pagination";

class LinkedStories extends Component {
  state = {
    perPage: 5,
    currentPage: 0
  };

  columns = [
    {
      title: "brands / platform",
      dataIndex: "platforms",
      key: "platforms",
      sorter: false,
      render: (platforms, row) => {
        const isMoreThenOneBrand = platforms.length > 1;
        return (
          <div className="brand_platform-td-container">
            <BrandAndPlatformName
              platforms={platforms}
              row={row}
              isAbleToViewCompanies
            />
            {isMoreThenOneBrand && (
              <span
                className="show-more-burger"
                onClick={e => {
                  e.stopPropagation();
                  this.showMore(row);
                }}
              >
                {burger}
              </span>
            )}
          </div>
        );
      }
    },
    {
      title: "Story Name",
      dataIndex: "title",
      key: "title",
      sorter: false,
      render: title => <span className="table-data-text">{title}</span>
    },
    {
      title: "Category / Sub-Category",
      dataIndex: "category",
      key: "category",
      sorter: false,
      width: "200px",
      render: ({ name, parent }) => {
        return (
          <span className="table-data-text">
            {parent && `${parent.name} › `}
            {name}
          </span>
        );
      }
    },
    {
      title: "story type",
      key: "content_type.name",
      dataIndex: "content_type.name",
      sorter: false,
      render: text => <span className="table-data-text">{text}</span>
    },
    {
      title: <span className="ant-table-column-title">Publish date</span>,
      dataIndex: "publish_date",
      className: "publish_date_column_title",
      key: "publish_date",
      sorter: false,
      render: (text, row) => {
        const isMoreThenOneBrand = row.platforms.length > 1;
        if (typeof text !== "string") {
          return (
            <div className="publish_date-td-container">
              <span className="table-data-text">{getPublicationDate(row)}</span>
              {isMoreThenOneBrand && (
                <span
                  className="show-more-burger"
                  onClick={e => {
                    e.stopPropagation();
                    this.showMore(row);
                  }}
                >
                  {burger}
                </span>
              )}
            </div>
          );
        }
        return <span className="table-data-text">{text}</span>;
      }
    },
    {
      title: "Status",
      dataIndex: "story_status",
      key: "story_status",
      sorter: false,
      render: status => {
        return (
          <Observer>
            {() => (
              <EditableStatus
                context="story"
                status={status}
                editable={false}
              />
            )}
          </Observer>
        );
      }
    },
    {
      title: "",
      dataIndex: "type",
      key: "type",
      className: "story-list-table-th-linked-stories",
      align: "left",
      render: type => <span className="table-data-text">{type}</span>
    },
    {
      title: "",
      dataIndex: "",
      key: "unlink",
      className: "story-list-table-th-linked-stories",
      align: "left",
      render: (_, row) => {
        const {
          story: { id },
          store
        } = this.props;
        return (
          <>
            <Icon
              className="hoverable"
              onClick={() => {
                const params =
                  row.type === "Child" ? [row.id, id] : [id, row.id];
                store.deleteLinkedStory(...params).then(() => {
                  store.load(id);
                });
              }}
              component={Unlink}
            />
          </>
        );
      }
    }
  ];

  onPaginationChange(currentPage) {
    this.setState({ currentPage: currentPage - 1 });
  }

  paginationItemRender = (_, type) => {
    if (type === "prev") {
      return (
        <span className="pagination-arrow-back">
          <span>{arrow}</span>
          Back
        </span>
      );
    }
    if (type === "next") {
      return (
        <span className="pagination-arrow-next">
          Next
          <span>{arrow}</span>
        </span>
      );
    }
    return null;
  };

  showTotal = (total, fromToArr) => {
    return `${fromToArr[0]}-${fromToArr[1]} of ${total}`;
  };

  handlePageSizeChange(_, pageSize) {
    this.setState({ perPage: pageSize });
  }

  async showMore(row) {
    const isAbleToViewCompanies = await AccessControl.can(
      "story-list:view-companies"
    );
    const { PlatformsModal } = this.props;

    PlatformsModal.setPopupInfo({
      list: isAbleToViewCompanies
        ? "listOfPlatformsWithCompanies"
        : "listOfPlatforms",
      story: row,
      isShowLinkedStories: false,
      isLinkedStory: true
    });
    PlatformsModal.setVisible(true);
  }

  render() {
    const { story, onRow } = this.props;
    const { perPage, currentPage } = this.state;
    const parentsArray = story.parent.map(item => ({
      ...item.story_parent,
      type: "Parent"
    }));
    const childrenArray = story.children.map(item => ({
      ...item.story_child,
      type: "Child"
    }));
    const linkedStoriesArray = [...parentsArray, ...childrenArray];

    return (
      <Row>
        <Col>
          <Table
            columns={this.columns}
            dataSource={linkedStoriesArray}
            rowKey="id"
            onRow={onRow}
            pagination={
              linkedStoriesArray.length > 5
                ? {
                    showSizeChanger: true,
                    pageSizeOptions: pageSizeOptions.low,
                    onChange: this.onPaginationChange.bind(this),
                    pageSize: perPage,
                    size: "medium",
                    showTotal: this.showTotal,
                    onShowSizeChange: this.handlePageSizeChange.bind(this),
                    total: linkedStoriesArray.length,
                    itemRender: this.paginationItemRender,
                    current: currentPage + 1
                  }
                : false
            }
          />
        </Col>
      </Row>
    );
  }
}
export default inject("PlatformsModal")(observer(LinkedStories));
