import React from "react";
import axios from "axios";
import { message } from "antd";
import showMessage from "../../helpers/showMessage";

const withErrorHandler = (
  WrappedComponent,
  allowedMessages = ["resolve", "reject"]
) => {
  return class extends React.Component {
    componentWillMount() {
      this.requestInterceptor = axios.interceptors.request.use(request => {
        return request;
      });
      this.responseInterceptor = axios.interceptors.response.use(
        response => {
          if (!allowedMessages.includes("resolve")) {
            return response;
          }
          const {
            config: { method },
            data: { status: operationStatus }
          } = response;
          const updateMethods = ["post", "put", "patch", "delete"];
          if (updateMethods.includes(method) && !operationStatus) {
            return response;
          }
          showMessage(response.data);
          return response;
        },
        error => {
          if (!allowedMessages.includes("reject")) {
            return Promise.reject(error);
          }
          let messageText;
          if (error.response) {
            messageText = error.response.data.message;
          }
          if (typeof messageText === "object") {
            messageText = Object.values(messageText).join(" ");
          }
          message.error(messageText || "Error occurred!");
          return Promise.reject(error);
        }
      );
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.requestInterceptor);
      axios.interceptors.response.eject(this.responseInterceptor);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withErrorHandler;
