import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./styles.less";
import { iconsSet } from "../icons";

function getIcon(icon = false) {
  if (!icon) {
    return null;
  }
  const IconComponent = iconsSet[icon];
  if (!IconComponent) {
    return null;
  }
  return <IconComponent />;
}

function Button(props) {
  const {
    text,
    theme,
    disabled,
    icon,
    size,
    loading,
    onClick,
    href,
    htmlType,
    className,
    pathname,
    from,
    search
  } = props;

  let buttonClassName;
  let linkTextClassName;

  switch (size) {
    case "small":
      buttonClassName = "small-button";
      linkTextClassName = "small-font-link";
      break;
    case "large":
      buttonClassName = "large-button";
      linkTextClassName = "large-font-link";
      break;
    default:
      buttonClassName = "large-button";
      linkTextClassName = "large-font-link";
      break;
  }

  return htmlType === "link" ? (
    <Link
      to={
        href.length ? href : { pathname, state: { from }, search: search || "" }
      }
      className={`${theme} ${buttonClassName} ${className}`}
      disabled={loading ? true : disabled}
      onClick={onClick}
    >
      {getIcon(icon)}
      <span className={`button-text ${linkTextClassName}`}>{text}</span>
    </Link>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button
      type={htmlType}
      className={`${theme} ${buttonClassName} ${className}`}
      disabled={loading ? true : disabled}
      onClick={onClick}
    >
      {getIcon(icon)}
      <span className="button-text">{text}</span>
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.oneOf([
    "solid-blue",
    "blue-outline",
    "green-outline",
    "black-outline",
    "red-outline",
    "solid-white",
    "grey-outline",
    "primary-link"
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(["", "add", "upload", "download", "link", "view"]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  htmlType: PropTypes.oneOf(["link", "button", "submit"]),
  className: PropTypes.string,
  pathname: PropTypes.string,
  from: PropTypes.string
};

Button.defaultProps = {
  text: "Button",
  theme: "solid-blue",
  icon: "",
  size: "large",
  disabled: false,
  loading: false,
  onClick: () => {},
  href: "",
  htmlType: "button",
  className: "",
  pathname: "",
  from: ""
};

export default Button;
