import React from "react";
import { Icon, Spin } from "antd";
import DescriptionForm from "./DescriptionForm";
import Button from "../../common/Button";
import { iconDelete } from "./icons/delete";
import ContentLinks from "./ContentLinks";
import AddLinkButton from "./AddLinkButton";

function OtherTypes({
  ManageContentStore,
  assignment,
  assignment: { type },
  uploadFileToTheServer,
  downloadFile,
  deleteFile,
  uploadLink,
  completed,
  loading
}) {
  const inputOpenFileRef = React.createRef();

  const handleDescriptionSubmit = values => {
    ManageContentStore.setDescriptionToAssignment(
      assignment.id,
      values.description
    );
  };

  const addFileButtonHandler = () => {
    inputOpenFileRef.current.click();
  };

  const handleAddFile = fileList => {
    Object.keys(fileList).forEach(key => {
      uploadFileToTheServer(fileList[key]);
    });
  };

  const filesAttached = assignment.files.length !== 0;
  const linksAttached = assignment.links.length !== 0;

  const renderInputWithProperSuggest = () => {
    if (type.name === "Audio") {
      return (
        <input
          ref={inputOpenFileRef}
          type="file"
          accept="audio/*"
          multiple
          style={{ display: "none" }}
          onChange={e => handleAddFile(e.target.files)}
        />
      );
    }
    return (
      <input
        ref={inputOpenFileRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={e => handleAddFile(e.target.files)}
      />
    );
  };

  return (
    <Spin spinning={loading}>
      <DescriptionForm
        handleDescriptionSubmit={handleDescriptionSubmit}
        assignment={assignment}
        completed={completed}
      />
      {filesAttached && (
        <div className="label-description-wrapper">
          <span className="label">Files</span>
          <div className="attached-files">
            {assignment.files.map(file => {
              return (
                <div className="uploaded-text-file" key={file.id}>
                  <Icon type="file" />
                  <span
                    className="uploaded-text-file-name"
                    onClick={downloadFile(file.s3_link)}
                  >
                    {file.filename}
                  </span>
                  {!completed && (
                    <span
                      className="uploaded-text-file-delete"
                      onClick={deleteFile(file.id)}
                    >
                      {iconDelete}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {linksAttached && (
        <ContentLinks
          assignment={assignment}
          completed={completed}
          deleteFile={deleteFile}
        />
      )}
      {!completed && (
        <>
          <Button
            text={filesAttached ? "Add Another File" : "Add File"}
            theme="blue-outline"
            size="small"
            onClick={addFileButtonHandler}
            className="add-text-content-button"
            disabled={loading}
          />
          <AddLinkButton
            uploadLink={uploadLink}
            text={linksAttached ? "Add Another Link" : "Add Link"}
            loading={loading}
          />
        </>
      )}
      {renderInputWithProperSuggest()}
    </Spin>
  );
}

export default OtherTypes;
