import { Icon } from "antd";
import React from "react";
import { iconDelete } from "./icons/delete";
import "./styles.less";

const ContentLinks = ({ assignment, deleteFile, completed }) => {
  const openLink = link => () => {
    window.open(link, "_blank");
  };

  return (
    <div className="label-description-wrapper">
      <span className="label">Links</span>
      <div className="attached-files">
        {assignment.links.map(link => {
          return (
            <div className="uploaded-text-file" key={link.id}>
              <Icon type="link" />
              <span
                className="uploaded-text-file-name"
                onClick={openLink(link.link)}
              >
                {link.link}
              </span>
              {!completed && (
                <span
                  className="uploaded-text-file-delete"
                  onClick={deleteFile(link.id, "link")}
                >
                  {iconDelete}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContentLinks;
