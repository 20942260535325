import React from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, Row } from "antd";

const CostForm = observer(({ cost, commissionInfo }) => {
  return (
    <Row gutter={20} className="cost-form-row">
      <Col lg={6} md={6}>
        <div className="label-description-wrapper">
          <span className="label">Expense Type</span>
          <div className="description">{cost.expense_type.name}</div>
        </div>
      </Col>

      <Col lg={3} md={3}>
        <div className="label-description-wrapper">
          <span className="label">Cost</span>
          <div className="description">{cost.cost}</div>
        </div>
      </Col>

      <Col lg={6} md={6}>
        <div className="label-description-wrapper">
          <span className="label">VAT Rate</span>
          <div className="description">{cost.vat_rate.name}</div>
        </div>
      </Col>

      <Col lg={2} md={2} className="cost-small-field">
        <div className="label-description-wrapper">
          <span className="label">Tax, %</span>
          <div className="description">{cost.tax}</div>
        </div>
      </Col>
      <Col lg={2} md={2} className="cost-small-field">
        <div className="label-description-wrapper">
          <span className="label">VAT</span>
          <div className="description">{cost.vat}</div>
        </div>
      </Col>
      <Col lg={2} md={2} className="cost-small-field">
        <div className="label-description-wrapper">
          <span className="label">Total</span>
          <div className="description">{cost.total}</div>
        </div>
      </Col>

      <Col lg={3} md={3}>
        <div className="label-description-wrapper">
          <span className="label">Currency</span>
          <div className="description">{commissionInfo.currency.name}</div>
        </div>
      </Col>
    </Row>
  );
});

function CostsView({ store: { commissionInfo }, store }) {
  const getTotalSum = () => {
    return (
      Math.round(
        commissionInfo.costs.reduce((sum, current) => {
          if (!current.total) {
            return sum;
          }
          return sum + +current.total;
        }, 0) * 100
      ) / 100
    );
  };

  const getCardTitle = () => {
    return (
      <>
        <div>
          Costs
          <span className="costs-title-currency-total">
            {commissionInfo.currency.name} {getTotalSum()}
          </span>
        </div>
      </>
    );
  };

  return (
    <Card
      className="story-panel commission-details"
      title={getCardTitle()}
      bordered={false}
    >
      <div className="special-terms-form">
        <Row className="costs-description-row cost-description-row-view">
          <Col lg={16} md={16}>
            <div className="label-description-wrapper">
              <span className="label">Description</span>
              <div className="description">
                {commissionInfo.costs_description}
              </div>
            </div>
          </Col>
        </Row>
        {commissionInfo.costs.map((cost, i) => {
          return (
            <CostForm
              key={`cost-form-${i}`}
              cost={cost}
              store={store}
              commissionInfo={commissionInfo}
            />
          );
        })}
      </div>
    </Card>
  );
}

export default inject(stores => ({ store: stores.CommissionViewStore }))(
  observer(CostsView)
);
