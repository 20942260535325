import { types } from "mobx-state-tree";

const PlatformsModalModel = types
  .model("PlatformsModal", {
    visible: types.boolean,
    showLinkedStories: types.boolean,
    isLinkedStory: types.boolean,
    storyId: types.maybeNull(types.number),
    list: types.string
  })
  .actions(self => {
    return {
      setVisible(value) {
        self.visible = value;
      },
      setPopupInfo({ isShowLinkedStories, story, list, isLinkedStory }) {
        self.showLinkedStories = !!isShowLinkedStories;
        self.isLinkedStory = !!isLinkedStory;
        self.storyId = story.id;
        self.list = list;
      }
    };
  });

const PlatformsModal = PlatformsModalModel.create({
  visible: false,
  showLinkedStories: false,
  isLinkedStory: false,
  list: ""
});

export default PlatformsModal;
