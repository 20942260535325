import React from "react";

const arrow = (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12L7 7L2 2"
      stroke="#3E3D47"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
export default arrow;
