import { types } from "mobx-state-tree";

const MentionUser = types.model("MentionUser", {
  id: types.number,
  name: types.string,
  email: types.string,
  role: types.string
});

export default MentionUser;
