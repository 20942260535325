import axios from "axios";

const ChatService = {
  saveMessage: params => {
    return axios.post("/story/chat/message/save", { ...params });
  },

  getUserList: (params, companyId) => {
    return axios
      .get(`/user/list?company=${companyId}`, { params })
      .then(({ data: { entity } }) => entity);
  },

  getMessagesList: (story_id, params) => {
    return axios
      .get(`story/${story_id}/chat/message`, { params })
      .then(({ data: { entity } }) => entity);
  },

  deleteMessage: messageId => {
    return axios.delete(`/story/chat/message/delete/${messageId}`);
  }
};

export default ChatService;
