import { types } from "mobx-state-tree";
import TaskType from "./Commission/TaskType";
import UserRole from "./Commission/UserRole";
import AmountCurrency from "./Commission/AmountCurrency";

const Commission = types.model("Commission", {
  id: types.optional(types.number, 0),
  task_type: types.frozen(TaskType),
  user_name: types.optional(types.string, ""),
  user_role: types.frozen(UserRole),
  task_brief: types.optional(types.string, ""),
  amount_currency: types.frozen(AmountCurrency),
  amount_sum: types.optional(types.number, 0)
});

export default Commission;
