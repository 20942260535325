import React from "react";
import { Form, Input } from "antd";
import { disposeOnUnmount, inject, observer } from "mobx-react";
import "./styles.less";
import { Link, Redirect } from "react-router-dom";
import { reaction } from "mobx";
import { withLastLocation } from "react-router-last-location";
import { matchPath } from "react-router";
import FooterContainer from "../Footer";
import AuthLogotype from "../../common/AuthLogotype";
import withErrorHandler from "../../hoc/withErrorHandler";
import Button from "../../common/Button";

class LoginPage extends React.Component {
  disabledForRedirect = [
    { path: "/password/reset" },
    { path: "/password/reset/:token" }
  ];

  componentDidMount() {
    const { AuthStore, history } = this.props;
    disposeOnUnmount(
      this,
      reaction(
        () => AuthStore.isLoggedIn,
        () => {
          history.push(this.lastLocation);
        }
      )
    );
  }

  get lastLocation() {
    const { lastLocation } = this.props;
    let pathname =
      (lastLocation && `${lastLocation.pathname}${lastLocation.search}`) || "/";
    if (!this.canRedirectFromAuthScreen(pathname)) {
      pathname = "/";
    }
    return pathname;
  }

  handleSubmit = event => {
    const {
      form: { validateFields },
      AuthStore
    } = this.props;
    event.preventDefault();
    validateFields((error, values) => {
      if (!error) {
        AuthStore.login(values);
      }
    });
  };

  canRedirectFromAuthScreen(pathname) {
    return !this.disabledForRedirect.find(disabledState =>
      matchPath(pathname, disabledState)
    );
  }

  renderLoginForm() {
    const {
      AuthStore: { loading, isLoggedIn },
      form: { getFieldDecorator }
    } = this.props;

    if (isLoggedIn) {
      return <Redirect to={this.lastLocation} />;
    }
    return (
      <div>
        <Form
          className="login-form"
          onSubmit={this.handleSubmit}
          hideRequiredMark
        >
          {/* <SocialNetworksSection /> */}
          <Form.Item label="Email Address">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The entered value isn't valid email"
                },
                {
                  required: true,
                  message: "Please input your email address"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please input your password"
                }
              ]
            })(<Input type="password" />)}
          </Form.Item>
          <Link className="forgot-password-link" to="/password/reset">
            Forgot your Password?
          </Link>
          <Button
            htmlType="submit"
            text="Login"
            size="large"
            theme="solid-blue"
            onClick={this.handleSubmit}
            disabled={loading}
          />
        </Form>
        {/* <div className="sign-up-section"> */}
        {/*  <span className="text-section">Don&apos;t have an account?</span> */}
        {/*  <Button text="Sign up" size="large" theme="solid-white" /> */}
        {/* </div> */}
      </div>
    );
  }

  render() {
    return (
      <div className="login-page-wrapper">
        <div className="login-page-container">
          <AuthLogotype />
          {this.renderLoginForm()}
        </div>
        <FooterContainer />
      </div>
    );
  }
}

export default Form.create()(
  inject("AuthStore")(withErrorHandler(withLastLocation(observer(LoginPage))), [
    "reject"
  ])
);
