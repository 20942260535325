import { applySnapshot, flow, types } from "mobx-state-tree";
import Story from "./Story";
import StoryService from "../../services/api/stories";
import Filter, { createFilter } from "../common/filter";
import { toOptionArray } from "../../helpers/array-utils";
import showErrorMessage from "../../helpers/showErrorMessage";
import MentionUser from "./MentionUser";
import ChatMessage from "./ChatMessage";
import ChatService from "../../services/api/chat";
import showMessage from "../../helpers/showMessage";
import getFiltersInitialValues from "../../helpers/getFiltersInitialValues";
import getUrlParams from "../../helpers/getUrlParams";

const initialSnapshot = {
  loadingStory: false,
  loadingStatuses: false,
  approveContentInProgress: false,
  changingStatus: false,
  deletingStory: false,
  loadingChatMessages: false,
  deletingMessage: false,
  savingMessage: false,
  loadingUsers: false,
  filtersInitialValues: { sorting: {} },
  filtersRequestParams: {}
};

const StoryViewStore = types
  .model("StoryViewStore", {
    loadingStory: types.optional(types.boolean, false),
    loadingStatuses: types.optional(types.boolean, false),
    approveContentInProgress: types.optional(types.boolean, false),
    deletingStory: types.optional(types.boolean, false),
    changingStatus: types.optional(types.boolean, false),
    loadingChatMessages: types.optional(types.boolean, false),
    deletingMessage: types.optional(types.boolean, false),
    savingMessage: types.optional(types.boolean, false),
    loadingUsers: types.optional(types.boolean, false),
    statuses: types.maybeNull(Filter),
    assignmentStatuses: types.maybeNull(Filter),
    story: types.maybeNull(Story),
    chatMessages: types.array(ChatMessage),
    mentionUsers: types.array(MentionUser),
    totalMessages: types.optional(types.number, 0),
    messages_per_page: types.optional(types.number, 0),
    current_chat_page: types.optional(types.number, 1),
    filtersInitialValues: types.optional(types.frozen(), {}),
    filtersRequestParams: types.optional(types.frozen(), {})
  })
  .actions(self => {
    return {
      afterCreate() {
        self.setAppliedFilters();
      },

      setAppliedFilters() {
        self.filtersInitialValues = getFiltersInitialValues();
        self.filtersRequestParams = getUrlParams();
      },

      loadStatuses: flow(function* loadStatuses() {
        self.loadingStatuses = true;
        try {
          const statuses = yield StoryService.getStatuses();
          self.statuses = createFilter(
            "statuses",
            toOptionArray(statuses.storyStatuses),
            statuses.storyStatuses
          );
          self.assignmentStatuses = createFilter(
            "assignmentStatuses",
            toOptionArray(statuses.assignmentStatuses),
            statuses.assignmentStatuses
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingStatuses = false;
        }
      }),
      load: flow(function* load(id) {
        self.loadingStory = true;
        try {
          const data = yield StoryService.load(id);
          self.story = Story.create(data);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingStory = false;
        }
      }),

      approveContent: flow(function* approveContent(id) {
        self.approveContentInProgress = true;
        try {
          const response = yield StoryService.approveContent(id);
          const target = self.story.assignments.find(
            assignment => assignment.id === response.id
          );
          target.changeStatus(
            response.assignment_status,
            response.assignment_status_id
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.approveContentInProgress = false;
        }
      }),

      changeAssignmentStatus: flow(function* changeAssignmentStatus(
        assignmentId,
        statusId
      ) {
        self.changingStatus = true;
        try {
          const response = yield StoryService.changeAssignmentStatus(
            assignmentId,
            statusId
          );
          const target = self.story.assignments.find(
            assignment => assignment.id === assignmentId
          );
          target.changeStatus(
            response.assignment_status,
            response.assignment_status_id
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.changingStatus = false;
        }
      }),

      acceptRejectAssignment: flow(function* acceptRejectAssignment(
        assignmentId,
        statusId
      ) {
        // this action is used for changing accepting/rejecting assignment by assignee from email link
        self.changingStatus = true;
        try {
          yield StoryService.changeAssignmentStatus(assignmentId, statusId);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.changingStatus = false;
        }
      }),

      reset() {
        applySnapshot(self, initialSnapshot);
      },

      deleteStory: flow(function* deleteStory(id) {
        self.deletingStory = true;
        try {
          yield StoryService.deleteStory(id);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.deletingStory = false;
        }
      }),

      deleteLinkedStory: flow(function* deleteLinkedStory(childId, parentId) {
        self.deletingStory = true;
        try {
          const response = yield StoryService.deleteLinkedStory(
            childId,
            parentId
          );

          showMessage(response);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.deletingStory = false;
        }
      }),

      // chat
      saveChatMessage: flow(function* saveChatMessage(params) {
        self.savingMessage = true;
        try {
          const response = yield ChatService.saveMessage(params);
          showMessage(response);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.savingMessage = false;
        }
      }),

      getUserList: flow(function* getUserList(params, companyId) {
        self.loadingUsers = true;
        try {
          self.mentionUsers = yield ChatService.getUserList(params, companyId);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingUsers = false;
        }
      }),

      resetUserList() {
        self.mentionUsers = [];
      },

      getChatMessages: flow(function* getChatMessages(storyId, params) {
        self.loadingChatMessages = true;
        try {
          const response = yield ChatService.getMessagesList(storyId, params);
          self.chatMessages = response.data;
          self.totalMessages = response.total;
          self.messages_per_page = response.per_page;
          self.current_chat_page = response.current_page;
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingChatMessages = false;
        }
      }),

      deleteChatMessage: flow(function* deleteChatMessage(messageId) {
        self.deletingMessage = true;
        try {
          const response = yield ChatService.deleteMessage(messageId);
          const targetMessage = self.chatMessages.find(
            message => message.id === messageId
          );
          self.chatMessages.remove(targetMessage);
          self.totalMessages -= 1;
          showMessage(response);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.deletingMessage = false;
        }
      })
    };
  })
  .views(self => ({
    get statusPatch() {
      return statusId => {
        const { raw_values } = self.statuses;
        return raw_values.find(statusEntry => statusEntry.id === statusId);
      };
    },

    get loading() {
      return (
        self.approveContentInProgress ||
        self.loadingStory ||
        self.loadingStatuses ||
        self.changingStatus ||
        self.deletingStory
      );
    },

    get chatLoading() {
      return (
        self.savingMessage || self.deletingMessage || self.loadingChatMessages
      );
    }
  }));

const storyViewStore = StoryViewStore.create(initialSnapshot);

export default storyViewStore;
