import { types } from "mobx-state-tree";

const CommissionStatus = types.model("CommissionStatus", {
  description: types.optional(types.string, ""),
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  priority: types.optional(types.number, 0),
  slug: types.optional(types.string, "")
});

export default CommissionStatus;
