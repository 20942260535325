import React, { useEffect, useState } from "react";
import {
  Select,
  Spin,
  TreeSelect,
  Form,
  DatePicker,
  Icon,
  Tooltip,
  Input
} from "antd";
import { observer } from "mobx-react";
import { debounce } from "throttle-debounce";
import createSearchString from "../../../helpers/createSearchString";
import getUrlParams from "../../../helpers/getUrlParams";
import { ReactComponent as iconSearch } from "../../../images/icons/icon-search.svg";
import "./styles.less";
import "../../../styles/search-input.less";
import "../../../common/RangePicker/styles.less";
import { filterOptionsForMultipleEntitiesWithExceptions } from "../../../helpers/filterOptions";

function TasksListFilters({
  loadingFilters,
  filters,
  form,
  filtersInitialValues
}) {
  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {
    const filtersNamesArr = [
      "type",
      "storyStatus",
      "category",
      "brand",
      "platformType",
      "publishTypes",
      "assignee",
      "creator",
      "contentType",
      "date",
      "status",
      "search",
      "task_start_date"
    ];
    setShowResetButton(false);
    Object.keys(filtersInitialValues).forEach(key => {
      if (
        filtersNamesArr.includes(key) &&
        filtersInitialValues[`${key}`].length
      ) {
        setShowResetButton(true);
      }
    });
  }, [filtersInitialValues, showResetButton]);

  const { getFieldDecorator, setFieldsValue } = form;

  const { RangePicker } = DatePicker;

  const { Option } = Select;

  const resetFilters = () => {
    setFieldsValue({
      type: [],
      storyStatus: [],
      brand: [],
      platformType: [],
      category: [],
      publishTypes: [],
      contentType: [],
      date: [],
      status: [],
      assignee: [],
      creator: [],
      search: "",
      task_start_date: []
    });
  };
  return (
    <React.Fragment>
      {loadingFilters && (
        <div className="filters-spinner">
          <Spin size="large" />
        </div>
      )}
      {!loadingFilters && filters.size && (
        <div className="story-filters-wrapper">
          <Form>
            <div className="story-filters">
              {/* Reset btn */}
              {showResetButton && (
                <Tooltip placement="top" title="Reset all filters">
                  <div className="reset-wrapper" onClick={resetFilters}>
                    <Icon className="reset-icon" type="reload" />
                    <span className="reset-text">Reset</span>
                  </div>
                </Tooltip>
              )}

              <Form.Item>
                {getFieldDecorator("brand", {
                  initialValue: filtersInitialValues.brand
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Platform"
                    className="task-filters-story-status-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters
                      .get("brands")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("platformType", {
                  initialValue: filtersInitialValues.platformType
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("platforms").name}
                    className="task-filters-content_type-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters
                      .get("platforms")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("type", {
                  initialValue: filtersInitialValues.type
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("task_types").name}
                    className="task-filters-type-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters
                      .get("task_types")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("storyStatus", {
                  initialValue: filtersInitialValues.storyStatus
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("story_statuses").name}
                    className="task-filters-story-status-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters
                      .get("story_statuses")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              {/* Channel */}

              <Form.Item>
                {getFieldDecorator("category", {
                  initialValue: filtersInitialValues.category
                })(
                  <TreeSelect
                    className="tree-select"
                    dropdownClassName="filters-tree-select-dropdown"
                    treeData={filters.get("categories").select_options}
                    size="large"
                    placeholder={filters.get("categories").name}
                    treeIcon
                    multiple
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("publishTypes", {
                  initialValue: filtersInitialValues.publishTypes
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("publishTypes").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="task-filters-content_type-select"
                  >
                    {filters
                      .get("publishTypes")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("assignee", {
                  initialValue: filtersInitialValues.assignee
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Assignee"
                    className="task-filters-user-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    optionFilterProp="children"
                    filterOption={
                      filterOptionsForMultipleEntitiesWithExceptions
                    }
                  >
                    {filters.get("users").select_options.map(assignee => {
                      const { id, name, email, roles } = assignee;
                      return (
                        <Option value={id} key={id}>
                          {name} {email} / {roles[0].title}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("creator", {
                  initialValue: filtersInitialValues.creators
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Task Creator"
                    className="task-filters-creator-select"
                    dropdownClassName="filters-tree-select-dropdown"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    optionFilterProp="children"
                    filterOption={
                      filterOptionsForMultipleEntitiesWithExceptions
                    }
                  >
                    {filters.get("creators").select_options.map(creator => {
                      const { id, name, email, roles } = creator;
                      return (
                        <Option value={id} key={id}>
                          {name} {email} / {roles[0].title}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("contentType", {
                  initialValue: filtersInitialValues.contentType
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("content_types").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="task-filters-content_type-select"
                  >
                    {filters
                      .get("content_types")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("task_start_date", {
                  initialValue: filtersInitialValues.task_start_date
                })(
                  <RangePicker
                    className="custom-filter-date-picker"
                    placeholder={["Start from", "Start to"]}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("date", {
                  initialValue: filtersInitialValues.date
                })(
                  <RangePicker
                    className="custom-filter-date-picker"
                    placeholder={["Deadline from", "Deadline to"]}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("status", {
                  initialValue: filtersInitialValues.status
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("task_statuses").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="task-filters-status-select"
                  >
                    {filters
                      .get("task_statuses")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("search", {
                  initialValue: filtersInitialValues.search
                })(
                  <Input
                    placeholder="Story Title, Task Brief"
                    prefix={<Icon component={iconSearch} />}
                    size="large"
                    className="search-input common-search"
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

const searchEngine = (props, changedFields, allFields) => {
  const { history } = props;

  const usedFilters = {};

  const urlParams = getUrlParams();

  Object.keys(allFields).forEach(fieldName => {
    delete urlParams[fieldName];
    switch (fieldName) {
      case "search":
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value;
        }
        break;
      case "category":
        delete urlParams.category;
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value;
        }
        break;
      case "date":
        delete urlParams.deadlineFrom;
        delete urlParams.deadlineTo;
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters.deadlineFrom = allFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.deadlineTo = allFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      case "task_start_date":
        delete urlParams.taskStartFrom;
        delete urlParams.taskStartTo;
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters.taskStartFrom = allFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.taskStartTo = allFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      default:
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value.join(",");
        }
        break;
    }
  });
  history.push(createSearchString({ ...usedFilters, ...urlParams, page: 1 }));
};

const debouncedSearchEngine = debounce(1000, searchEngine);

const formFieldChangeHandler = (...args) => {
  const changedFieldNames = Object.keys(args[1]);

  if (changedFieldNames.includes("search")) {
    debouncedSearchEngine(...args);
    return;
  }

  searchEngine(...args);
};

export default Form.create({ onFieldsChange: formFieldChangeHandler })(
  observer(TasksListFilters)
);
