import { types } from "mobx-state-tree";

const Approver = types.model("Approver", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  job_title: types.maybeNull(types.optional(types.string, "")),
  job_role: types.maybeNull(types.optional(types.string, "")),
  can_approve: types.optional(types.boolean, false),
  user_addition_id: types.maybeNull(types.number)
});

export default Approver;
