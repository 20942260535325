import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Icon, Tag, Select } from "antd";
import { getColorForStatus } from "../../helpers/getColorForStatus";
import { ReactComponent as Pencil } from "../../images/icons/pencil.svg";

import "./styles.less";

const findStatusById = (statuses, id) =>
  statuses.find(status => status.value === id);

const getSlug = (name = "") => name.replace(/\s/g, "_").toLowerCase();

function EditableStatus({
  status,
  context,
  onChange,
  customPlaceholder,
  availableStatuses = [],
  editable = true,
  type = "tag"
}) {
  const [inEdit, setIsEdit] = useState(false);
  let statusLabel = "No Status";
  let statusSlug = "no_status";
  let colorCode = "";
  if (Number.isInteger(status)) {
    const foundStatus = findStatusById(availableStatuses, status);
    if (foundStatus) {
      statusSlug = getSlug(foundStatus.label);
      statusLabel = foundStatus.label;
      colorCode = foundStatus.color_code;
    }
  } else if (status) {
    statusSlug = status.slug;
    statusLabel = status.name;
  }

  function handleSelectChange(value) {
    onChange(value);
    setIsEdit(!inEdit);
  }

  if (inEdit) {
    return (
      <div
        className="editable-status-select-wrapper"
        onClick={e => e.stopPropagation()}
      >
        <Select
          placeholder={customPlaceholder || "Change status to"}
          onSelect={handleSelectChange}
          suffixIcon={<Icon type="caret-down" theme="filled" />}
          className="editable-status-select"
          defaultOpen
        >
          {availableStatuses.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }

  const iconClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsEdit(!inEdit);
  };

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="editable-status editable-status-story"
    >
      {type === "tag" && (
        <Tag
          color={
            status.color_code || getColorForStatus(statusSlug, context, type)
          }
        >
          <span className="tag-text">{statusLabel}</span>
        </Tag>
      )}
      {type === "dot" && (
        <div className="editable-status dot">
          <span
            className="status-dot"
            style={{
              backgroundColor:
                colorCode || getColorForStatus(statusSlug, context, type)
            }}
          />{" "}
          <span className="tag-text">{statusLabel}</span>
        </div>
      )}

      {editable && (
        <Icon
          className="hoverable"
          onClick={iconClickHandler}
          component={Pencil}
          data-testid={`create-story-${statusLabel
            ?.toLocaleLowerCase()
            .replace(" ", "-")}`}
        />
      )}
    </div>
  );
}

EditableStatus.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      priority: PropTypes.number,
      slug: PropTypes.string
    }),
    PropTypes.number
  ]).isRequired,
  onChange: PropTypes.func,
  availableStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  editable: PropTypes.bool,
  type: PropTypes.string,
  context: PropTypes.oneOf([
    "story",
    "platform",
    "assignment",
    "batch-payment",
    "commission"
  ]).isRequired
};

EditableStatus.defaultProps = {
  onChange: () => {},
  availableStatuses: [],
  editable: true,
  type: "tag"
};

export default observer(EditableStatus);
