import { applySnapshot, flow, getSnapshot, types } from "mobx-state-tree";
import AuthService from "../services/api/auth";
import CommonStore from "./CommonStore";
import showMessage from "../helpers/showMessage";
import User from "./common/user";
import AccessControl from "./AccessControl";

const AuthStore = types
  .model("AuthStore", {
    authorizing: types.boolean,
    loading: types.boolean,
    isLoggedIn: types.boolean,
    response: types.frozen({}),
    user: types.maybeNull(User)
  })
  .actions(self => {
    let initialState = {};

    return {
      afterCreate() {
        initialState = getSnapshot(self);
        if (CommonStore.token) {
          this.authorizeUser();
        }
      },

      login: flow(function* login(values) {
        self.loading = true;
        try {
          const responseData = yield AuthService.login(values);
          const {
            data: { metadata, entity }
          } = responseData;
          CommonStore.setToken(metadata);
          self.user = entity;
          AccessControl.setUser(self.user);
          self.isLoggedIn = true;
          self.loading = false;
        } catch (error) {
          console.error("Error: ", error);
          self.loading = false;
        }
      }),

      logout: flow(function* logout() {
        self.loading = true;
        try {
          const responseData = yield AuthService.logout(CommonStore.token);
          const { data } = responseData;
          self.response = data;
          CommonStore.setToken({});
          self.isLoggedIn = false;
          self.loading = false;
          showMessage(self.response);
        } catch (error) {
          console.error("Error: ", error);
          self.loading = false;
        }
      }),

      resetPassword: flow(function* resetPassword(email) {
        self.loading = true;
        try {
          const responseData = yield AuthService.reset(email);
          const { data } = responseData;
          self.response = data;
        } catch (error) {
          console.error("Error: ", error);
        }

        self.loading = false;
      }),

      confirmResetPassword: flow(function* confirmResetPassword(values) {
        self.loading = true;
        try {
          const responseData = yield AuthService.confirmReset(values);
          const { data } = responseData;
          self.response = data;
          self.loading = false;
          return Promise.resolve();
        } catch (error) {
          console.error("Error: ", error);
          self.loading = false;
          return Promise.reject();
        }
      }),

      authorizeUser: flow(function* authorizeUser() {
        try {
          self.authorizing = true;
          const {
            data: { entity }
          } = yield AuthService.authorizeByToken();
          self.user = entity;
          AccessControl.setUser(self.user);
          self.isLoggedIn = true;
        } catch (e) {
          self.isLoggedIn = false;
        }
        self.authorizing = false;
      }),

      changeUserName(name) {
        self.user.name = name;
      },

      reset: () => {
        applySnapshot(self, initialState);
      }
    };
  });

const authStore = AuthStore.create({
  authorizing: false,
  loading: false,
  isLoggedIn: false,
  response: {}
});
export default authStore;
