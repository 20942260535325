import React, { Component, Suspense, lazy } from "react";
import { inject, observer } from "mobx-react";
import { Icon, Spin } from "antd";
import "./styles.less";
import {
  Link as ReactScrollLink,
  Element,
  animateScroll as scroll
} from "react-scroll";
import StoryInformation from "./StoryInformation";
import Platforms from "./Platforms";
import StoryAssignments from "./Assignments";
import Button from "../../common/Button";
import AccessControl from "../../models/AccessControl";
import ModalComponent from "../../common/Modal";
import { iconsSet } from "../../common/icons";
import getUrlParams from "../../helpers/getUrlParams";
import { USER_ROLE_CONTRIBUTOR } from "../../config/roles";
import ExpandableCard from "./components/ExpandableCard";
import LinkedStories from "./LinkedStories/LinkedStories";
import cardHeadButtons from "./LinkedStories/ExtraButtons";
import goBackHandler from "../../helpers/goBackHandler";
// lazy loaded component
const Chat = lazy(() => import("./components/Chat"));

class StoryView extends Component {
  state = {
    isAbleToDeleteStory: false,
    isAbleToDeleteChatMessage: false,
    isAbleToViewCompanies: false,
    showModal: false,
    chatExpandedByDefault: false
  };

  componentDidMount = async () => {
    const {
      StoryViewStore: store,
      history,
      match: {
        params: { id }
      }
    } = this.props;

    const isAbleToDeleteStory = await AccessControl.can("story:delete-story");
    const isAbleToDeleteChatMessage = await AccessControl.can(
      "chat:delete-message"
    );
    const isAbleToViewCompanies = await AccessControl.can(
      "story-view-page:view-companies"
    );
    this.setState({
      isAbleToDeleteStory,
      isAbleToDeleteChatMessage,
      isAbleToViewCompanies
    });

    store.load(id).catch(() => {
      history.push("/story-list");
    });
    store.loadStatuses();

    const urlParams = getUrlParams();
    if (urlParams.taskId) {
      scroll.scrollTo(urlParams.taskId);
    }
  };

  componentWillUpdate(nextProps) {
    const {
      match: {
        params: { id: prevId }
      },
      StoryViewStore: store
    } = this.props;
    const {
      match: {
        params: { id: nextId }
      },
      history
    } = nextProps;
    if (nextId !== prevId) {
      store.load(nextId).catch(() => {
        history.push("/story-list");
      });
    }
  }

  componentWillUnmount() {
    const { StoryViewStore: store } = this.props;
    store.reset();
  }

  handleSingleStoryStatusChange = row => status => {
    const { StoryViewStore } = this.props;
    row.changeStatus(StoryViewStore.statusPatch(status));
  };

  downloadAttachment = s3_link => () => {
    window.location.href = s3_link;
  };

  onDeleteButtonClick = () => {
    this.setState({
      showModal: true
    });
  };

  deleteStory = () => {
    const {
      StoryViewStore,
      match: {
        params: { id }
      },
      history
    } = this.props;
    StoryViewStore.deleteStory(id).then(() => {
      history.push("/story-list");
    });
  };

  onPopUpCancelClick = () => {
    this.setState({
      showModal: false
    });
  };

  chatLinkClickHandler = () => {
    this.setState({
      chatExpandedByDefault: true
    });
  };

  onLinkedStoryRowHandler = record => {
    const { history } = this.props;
    return {
      onClick: () => {
        return history.push(`/story/${record.id}`);
      }
    };
  };

  render() {
    const {
      isAbleToDeleteStory,
      isAbleToDeleteChatMessage,
      showModal,
      chatExpandedByDefault,
      isAbleToViewCompanies
    } = this.state;
    const isTaskList = getUrlParams().taskId;
    const { StoryViewStore: store, history, location, AuthStore } = this.props;
    const { story } = store;
    const userRole = AuthStore?.user?.roles?.[0]?.name;
    if (story === null) {
      return <Spin />;
    }
    const cardContent = () => (
      <Suspense fallback={<Spin spinning className="spin-centered" />}>
        <Chat
          history={history}
          isAbleToDeleteChatMessage={isAbleToDeleteChatMessage}
          location={location}
        />
      </Suspense>
    );

    const linkedContent = () => {
      return (
        <LinkedStories
          story={story}
          onRow={this.onLinkedStoryRowHandler}
          store={store}
        />
      );
    };

    return (
      <Spin spinning={store.loading}>
        <div className="links-container">
          <div
            className="back-link"
            onClick={goBackHandler.bind(
              this,
              history,
              isTaskList ? "task-list" : "story-list"
            )}
          >
            <Icon type="arrow-left" />
            Back to the {isTaskList ? "Task List" : "Story List"}
          </div>

          <div className="chat-link-wrapper">
            <Icon component={iconsSet.message} className="message-icon" />
            <ReactScrollLink
              activeClass="active"
              to="chat-destination"
              className="chat-link"
              spy
              smooth
              duration={500}
              onClick={this.chatLinkClickHandler}
            >
              Story Chat
            </ReactScrollLink>
          </div>
        </div>
        <h1 className="story-title">
          {story.title} {isAbleToViewCompanies && `(${story.company.name})`}
        </h1>

        <StoryInformation
          story={story}
          statuses={store.statuses}
          handleSingleStoryStatusChange={this.handleSingleStoryStatusChange(
            story
          )}
          downloadAttachment={this.downloadAttachment}
        />

        <StoryAssignments
          story={story}
          store={store}
          statuses={store.assignmentStatuses || []}
        />

        <Platforms story={story} />

        <ExpandableCard
          cardContent={linkedContent()}
          header="Linked Stories"
          cardContentClass="linked-stories-card"
          extra={cardHeadButtons(story.id, story.company.id)}
        />

        <Element id="chat-destination" name="chat-destination">
          <ExpandableCard
            cardContent={cardContent()}
            header="Chat"
            cardContentClass="chat"
            expandedByDefault={chatExpandedByDefault}
          />
        </Element>

        <div className="buttons-container">
          {isAbleToDeleteStory && (
            <Button
              htmlType="button"
              text="Delete"
              theme="black-outline"
              onClick={this.onDeleteButtonClick}
              className="delete-button"
              disabled={store.chatLoading}
            />
          )}
          {userRole !== USER_ROLE_CONTRIBUTOR && (
            <>
              <Button
                htmlType="link"
                href={`/story/${story.id}/edit`}
                text="Edit"
                theme="black-outline"
                disabled={store.chatLoading}
              />
              <Button
                onClick={this.handleSubmit}
                text="Save"
                disabled
                theme="solid-blue"
              />
            </>
          )}
        </div>
        <ModalComponent
          onSubmit={this.deleteStory}
          onCancel={this.onPopUpCancelClick}
          showModal={showModal}
          disabled={store.loading}
        />
      </Spin>
    );
  }
}

export default inject("StoryViewStore", "AuthStore")(observer(StoryView));
