import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, Col, Form, Icon, Row, Select } from "antd";
import { inject, observer } from "mobx-react";
import EditableStatus from "../../common/EditableStatus";
import Field from "../../common/FieldContainer";
import { toOptionArray } from "../../helpers/array-utils";
import { filterOptionsForMultipleEntities } from "../../helpers/filterOptions";

function CommissionDetails({
  store: { createCommissionData },
  store: { options },
  store,
  authStore,
  commissionId
}) {
  const commissionRight = store.commission.commission_right;
  const [right, setRight] = useState("All Rights");
  useEffect(() => {
    setRight(
      commissionId && commissionRight ? commissionRight.name : "All Rights"
    );
  }, [commissionId, commissionRight]);
  const onRightsSelectChange = (value, option) => {
    setRight(option.props.children);
  };

  const rightsProperty = property => {
    const targetObj = options.commission_rights.find(
      option => option.name === right
    );
    return targetObj && targetObj[`${property}`];
  };

  const editInProgressStatus = {
    name: "Edit in Progress"
  };
  return (
    <Card
      className="story-panel commission-details"
      bordered={false}
      title="Commission Details"
    >
      <Row gutter={70}>
        <Col md={8} xs={24}>
          <Form.Item colon={false} style={{ width: "100%" }} label="Status">
            <EditableStatus
              context="commission"
              status={
                commissionId
                  ? editInProgressStatus
                  : createCommissionData.commission_status
              }
              editable={false}
            />
          </Form.Item>

          <div className="label-description-wrapper">
            <span className="label">Contributor Email</span>
            <div className="description">
              {createCommissionData.contributor_user.email}
            </div>
          </div>

          <Field name="commission_right_id" style={{ width: "100%" }}>
            <Select
              suffixIcon={<Icon type="caret-down" theme="filled" />}
              onSelect={onRightsSelectChange}
            >
              {toOptionArray(options.commission_rights).map(
                ({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                )
              )}
            </Select>
          </Field>

          <div className="rights-document">
            {rightsProperty("name") && <Icon type="file" />}
            <a
              href={rightsProperty("file_url")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="rights-text-file-name">
                {rightsProperty("name")}
              </span>
            </a>
          </div>
        </Col>

        <Col md={8} xs={24}>
          <div className="label-description-wrapper creator-email">
            <span className="label">Creator Email</span>
            <div className="description">
              {createCommissionData.creator_user.email}
            </div>
          </div>

          <Field name="approver_user_id" style={{ width: "100%" }}>
            <Select
              suffixIcon={<Icon type="caret-down" theme="filled" />}
              allowClear
              showSearch
              dropdownMatchSelectWidth={false}
              filterOption={filterOptionsForMultipleEntities}
            >
              {toOptionArray(options.approvers, "email").map(
                ({ label, value, email }) => (
                  <Select.Option key={value} value={value}>
                    {label} {email}
                  </Select.Option>
                )
              )}
            </Select>
          </Field>
        </Col>

        <Col md={8} xs={24}>
          <Row className="automated-story-details-row">
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Date</span>
                <div className="description">
                  {moment(createCommissionData.deadline).format("DD.MM.YYYY")}
                </div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Assignment Type</span>
                <div className="description">
                  {createCommissionData.assignment_type.name}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="automated-story-details-row">
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Commissioner</span>
                <div className="description">{authStore.user.name}</div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Brand</span>
                {createCommissionData.brands &&
                  createCommissionData.brands.map(brand => {
                    return (
                      <div className="description" key={brand.name}>
                        {brand.name}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Story Name</span>
                <div className="description">
                  {createCommissionData.story_name}
                </div>
              </div>
            </Col>
            <Col md={12} xs={12}>
              <div className="label-description-wrapper">
                <span className="label">Category</span>
                {createCommissionData.story_category &&
                  createCommissionData.story_category.map(category => {
                    return (
                      <div className="description" key={category.name}>
                        {category.name}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>

          {createCommissionData.terms_and_conditions && (
            <Row>
              <div className="rights-document">
                <Icon type="file" />
                <a
                  href={createCommissionData.terms_and_conditions?.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rights-text-file-name"
                >
                  <span>{createCommissionData.terms_and_conditions?.name}</span>
                </a>
              </div>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default inject(stores => ({
  store: stores.CommissionStore,
  authStore: stores.AuthStore
}))(observer(CommissionDetails));
