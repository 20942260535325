import { types } from "mobx-state-tree";
import Brand from "../common/brand";
import IntegrationType from "./IntegrationType";

const IntegrationData = types.model("IntegrationData", {
  integration_types: types.array(IntegrationType),
  brands: types.array(Brand)
});

export default IntegrationData;
