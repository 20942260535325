import React from "react";
import { Spin } from "antd";

import FooterContainer from "../Footer";
import AuthLogotype from "../../common/AuthLogotype";

import "./styles.less";

export default () => (
  <div className="login-page-wrapper">
    <div className="login-page-container">
      <AuthLogotype />
      <Spin size="large" />
    </div>
    <FooterContainer />
  </div>
);
