/* eslint-disable */
import React from "react";
import { Modal } from "antd";
import * as PropTypes from "prop-types";
import Button from "../Button";
import "./styles.less";

function ModalComponent({ title, showModal, onCancel, onSubmit, disabled }) {
  return (
    <Modal
      title={title}
      visible={showModal}
      centered
      className="modal-container approve-modal"
      footer={
       <>
         <Button
            theme="blue-outline"
            text="No"
            size="large"
            onClick={onCancel}
            disabled={disabled}
          />
         <Button
            theme="solid-blue"
            text="Yes"
            size="large"
            onClick={onSubmit}
            className="modal-edit-button"
            disabled={disabled}
          />
       </>
      }
      onCancel={onCancel}
    />
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  disabled: PropTypes.bool
};

ModalComponent.defaultProps = {
  title: "Are you sure?",
  showModal: false,
  onCancel: () => {},
  onSubmit: () => {},
  disabled: false
};

export default ModalComponent;
