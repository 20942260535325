import React from "react";
import { Modal } from "antd";
import Button from "../../../../common/Button";
import "./styles.less";

export default function NewBatchModal({
  showModal,
  onSubmit,
  onCancel,
  disabled
}) {
  const footerContent = (
    <div className="footer-buttons-container">
      <Button
        text="No"
        theme="blue-outline"
        size="large"
        onClick={onCancel}
        disabled={disabled}
      />
      <Button
        text="Yes"
        theme="solid-blue"
        size="large"
        onClick={onSubmit}
        disabled={disabled}
      />
    </div>
  );
  return (
    <Modal
      title="Generate New Batch?"
      centered
      visible={showModal}
      footer={footerContent}
      onCancel={onCancel}
      className="batch-modal"
    >
      <div className="modal-text">
        Are you sure you want to create a new Batch?
        <br />
        Please note: new Batch will include all Commissions in a status of
        &quot;Payment approved&quot;
      </div>
    </Modal>
  );
}
