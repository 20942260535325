import { types } from "mobx-state-tree";
import Pivot from "./Pivot";

const BrandAccess = types.model("BrandAccess", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  icon: types.optional(types.string, ""),
  icon_url: types.optional(types.string, ""),
  pivot: types.frozen(Pivot)
});

export default BrandAccess;
