import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const mobileMenuClose = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18.3848L18.3848 -1.03712e-05L19.799 1.4142L1.41421 19.799L0 18.3848Z"
      fill="#9CA1AC"
    />
    <path
      d="M18.3848 19.799L-8.82149e-06 1.4142L1.4142 -1.0252e-05L19.799 18.3848L18.3848 19.799Z"
      fill="#9CA1AC"
    />
  </svg>
);
