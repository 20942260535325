import React, { Component } from "react";
import { Form, Input } from "antd";
import { observer } from "mobx-react";
import Button from "../../common/Button";

const passwordRules = [
  {
    required: true,
    message: "Please input the password! "
  },
  {
    max: 20,
    min: 8,
    type: "string",
    message: "The length of the password must be between 8 and 20. "
  }
];

class ChangePassword extends Component {
  state = {
    inEdit: false
  };

  buttonClickHandler = event => {
    const { inEdit } = this.state;
    const { form } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        inEdit: !inEdit
      },
      () => {
        form.validateFields();
      }
    );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        onChange(values);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("new_password")) {
      callback("Field 'Confirm Password' isn't equal to the field 'Password'.");
    } else {
      callback();
    }
  };

  handleCancel = () => {
    const { inEdit } = this.state;
    this.setState({
      inEdit: !inEdit
    });
  };

  render() {
    const { inEdit } = this.state;
    const { form } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;

    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const newPasswordError =
      isFieldTouched("new_password") && getFieldError("new_password");
    const confirmPasswordError =
      isFieldTouched("new_password_confirmation") &&
      getFieldError("new_password_confirmation");

    return (
      <>
        {inEdit && (
          <div className="custom-editable-form-wrapper change-password-form">
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              className="editable-form-wrapper"
            >
              <div className="label-description-wrapper">
                <span className="label">Current password</span>
                <Form.Item
                  validateStatus={passwordError ? "error" : ""}
                  help={passwordError || ""}
                >
                  {getFieldDecorator("password", {
                    rules: passwordRules
                  })(<Input placeholder="current password" />)}
                </Form.Item>
              </div>
              <div className="label-description-wrapper">
                <span className="label">New password</span>
                <Form.Item
                  validateStatus={newPasswordError ? "error" : ""}
                  help={newPasswordError || ""}
                >
                  {getFieldDecorator("new_password", {
                    rules: passwordRules
                  })(<Input placeholder="new password" />)}
                </Form.Item>
              </div>
              <div className="label-description-wrapper">
                <span className="label">Confirm new password</span>
                <Form.Item
                  validateStatus={confirmPasswordError ? "error" : ""}
                  help={confirmPasswordError || ""}
                >
                  {getFieldDecorator("new_password_confirmation", {
                    rules: [
                      ...passwordRules,
                      {
                        validator: this.compareToFirstPassword
                      }
                    ]
                  })(<Input placeholder="confirm new password" />)}
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  text="Change Password"
                  htmlType="submit"
                  disabled={this.hasErrors(getFieldsError())}
                  theme="solid-blue"
                  size="small"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  text="Cancel"
                  htmlType="button"
                  onClick={this.handleCancel}
                  theme="blue-outline"
                  size="small"
                />
              </Form.Item>
            </Form>
          </div>
        )}

        {!inEdit && (
          <div className="change-password-form">
            <Button
              text="Change Password"
              theme="black-outline"
              size="small"
              className="change-password-button"
              htmlType="link"
              href="/password/reset"
              onClick={this.buttonClickHandler}
            />
          </div>
        )}
      </>
    );
  }
}

export default Form.create()(observer(ChangePassword));
