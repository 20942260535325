import { types } from "mobx-state-tree";

const COALevel = types.model("COALevel", {
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  code: types.optional(types.string, ""),
  level: types.optional(types.number, 0),
  company_id: types.optional(types.number, 0)
});

export default COALevel;
