import { applyPatch, flow, types } from "mobx-state-tree";
import StoryStatus from "../common/story-status";
import BatchPaymentService from "../../services/api/batch-payment";

const BatchPayment = types
  .model("BatchPayment", {
    id: types.maybeNull(types.number),
    generated_date: types.maybeNull(types.string),
    status: types.maybeNull(StoryStatus),
    commissions_in_batch_payment: types.maybeNull(types.number),
    link_to_download: types.maybeNull(types.string)
  })
  .actions(self => ({
    changeStatus: flow(function* changeStatus(
      batchPaymentId,
      batchPaymentStatusId
    ) {
      try {
        const status = yield BatchPaymentService.changeBatchPaymentStatus(
          batchPaymentId,
          batchPaymentStatusId
        );
        const patch = {
          op: "replace",
          path: "/status",
          value: status
        };
        applyPatch(self, patch);
        return Promise.resolve();
      } catch (error) {
        console.error("Error: ", error);
        return Promise.reject();
      }
    })
  }));
export default BatchPayment;
