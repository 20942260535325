import React, { Component } from "react";
import { Form, Icon, Input, Spin } from "antd";
import Button from "../../common/Button";
import { iconDelete } from "./icons/delete";
import AddLinkButton from "./AddLinkButton";
import ContentLinks from "./ContentLinks";

const titleRules = [
  {
    required: true,
    message: "Please input the title! "
  },
  {
    min: 1,
    max: 200,
    type: "string",
    message: "The length of the title must be between 1 and 200. "
  }
];

const textAreaRules = [
  {
    required: true,
    message: "Please input the text! "
  },
  {
    min: 5,
    type: "string",
    message: "The length of the text must be more than 5 characters. "
  }
];

class Text extends Component {
  state = {
    inEdit: false
  };

  addTextButtonHandler = event => {
    const { inEdit } = this.state;
    const { form } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        inEdit: !inEdit
      },
      () => {
        form.validateFields();
      }
    );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    const { form, uploadFileToTheServer } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        uploadFileToTheServer(values);
      }
    });
  };

  render() {
    const { inEdit } = this.state;
    const {
      form,
      assignment,
      downloadFile,
      deleteFile,
      loading,
      uploadLink,
      completed
    } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;

    const linksAttached = assignment.links.length !== 0;

    const { TextArea } = Input;

    const titleError = isFieldTouched("title") && getFieldError("title");
    const textAreaError =
      isFieldTouched("textArea") && getFieldError("textArea");

    const validationFailed = this.hasErrors(getFieldsError());
    const background = validationFailed ? "" : "validation-success-background";

    const textFilesAttached = assignment.files.length !== 0;

    return (
      <Spin spinning={loading}>
        {textFilesAttached && (
          <div className="label-description-wrapper">
            <span className="label">Files</span>
            <div className="attached-files">
              {assignment.files.map(file => {
                return (
                  <div className="uploaded-text-file" key={file.id}>
                    <Icon type="file" />
                    <span
                      className="uploaded-text-file-name"
                      onClick={downloadFile(file.s3_link)}
                    >
                      {`${file.filename}.txt`}
                    </span>
                    {!completed && (
                      <span
                        className="uploaded-text-file-delete"
                        onClick={deleteFile(file.id)}
                      >
                        {iconDelete}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {linksAttached && (
          <ContentLinks
            assignment={assignment}
            completed={completed}
            deleteFile={deleteFile}
          />
        )}
        {!inEdit && !completed && (
          <>
            <Button
              text={textFilesAttached ? "Add Another Text" : "Add text"}
              theme="blue-outline"
              size="small"
              onClick={this.addTextButtonHandler}
              className="add-text-content-button"
              disabled={loading}
            />
            <AddLinkButton
              uploadLink={uploadLink}
              text={linksAttached ? "Add Another Text Link" : "Add Text Link"}
              loading={loading}
            />
          </>
        )}
        {inEdit && !completed && (
          <div className="custom-editable-form-wrapper add-text-content-form">
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              className="editable-form-wrapper"
            >
              <div className="label-description-wrapper">
                <span className="label">Title</span>
                <Form.Item
                  validateStatus={titleError ? "error" : ""}
                  help={titleError || ""}
                >
                  {getFieldDecorator("title", {
                    rules: titleRules
                  })(
                    <Input
                      placeholder="Insert Title Here"
                      className={`add-text-content-form-title-input ${background}`}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="label-description-wrapper">
                <span className="label">Text Area</span>
                <Form.Item
                  validateStatus={textAreaError ? "error" : ""}
                  help={textAreaError || ""}
                >
                  {getFieldDecorator("textArea", {
                    rules: textAreaRules
                  })(
                    <TextArea
                      placeholder="Insert Text Here"
                      className={`add-text-content-form-textarea ${background}`}
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item className="add-text-content-form-item-button-wrapper">
                <Button
                  text="Save"
                  htmlType="submit"
                  disabled={validationFailed}
                  theme="solid-blue"
                  size="small"
                  className="add-text-content-form-submit-button"
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </Spin>
    );
  }
}

export default Form.create()(Text);
