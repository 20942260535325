import React, { Component } from "react";
import { observer } from "mobx-react";
import { Modal, Form, Input } from "antd";
import * as PropTypes from "prop-types";
import Button from "../../../../common/Button";

const status_commentRules = [
  {
    required: true,
    message: "Please input the comment! "
  }
];

class ApproveRejectModal extends Component {
  componentDidMount() {
    const { form, validation } = this.props;
    if (validation) {
      form.validateFields();
    }
  }

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleCancel = () => {
    const { onCancel, form, validation } = this.props;
    onCancel();
    form.resetFields();
    if (validation) {
      form.validateFields();
    }
  };

  handleSubmit = e => {
    const { form, onSubmit, type } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (type === "approve") {
          values.statusSlug = "manager_approved";
        } else {
          values.statusSlug = "manager_rejected";
        }
        onSubmit(values);
      }
    });
  };

  render() {
    const { TextArea } = Input;
    const { text, showModal, form, validation, type, loading } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;
    const status_commentError =
      isFieldTouched("status_comment") && getFieldError("status_comment");
    let validationFailed = false;
    if (validation) {
      validationFailed = this.hasErrors(getFieldsError());
    }
    return (
      <Modal
        title={text}
        visible={showModal}
        centered
        className="modal-container"
        footer={
          // eslint-disable-next-line
          <>
            <Button
              theme="blue-outline"
              text="No"
              size="large"
              onClick={this.handleCancel}
              disabled={loading}
            />
            <Button
              theme="solid-blue"
              text="Yes"
              size="large"
              onClick={this.handleSubmit}
              className="modal-edit-button"
              disabled={validationFailed || loading}
            />
          </>
        }
        onCancel={this.handleCancel}
      >
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          className="editable-form-wrapper"
        >
          <div className="label-description-wrapper">
            <span className="label">Text Comment</span>
            <Form.Item
              validateStatus={validation && status_commentError ? "error" : ""}
              help={(validation && status_commentError) || ""}
            >
              {getFieldDecorator("status_comment", {
                rules: type === "approve" ? [] : status_commentRules
              })(
                <TextArea
                  placeholder="Put a Text Comment Here"
                  className="add-text-content-form-textarea"
                />
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

ApproveRejectModal.propTypes = {
  text: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  validation: PropTypes.bool,
  type: PropTypes.oneOf(["approve", "reject"]),
  loading: PropTypes.bool
};

ApproveRejectModal.defaultProps = {
  text: "Are you sure?",
  showModal: false,
  onCancel: () => {},
  onSubmit: () => {},
  validation: false,
  type: "approve",
  loading: false
};

export default Form.create()(observer(ApproveRejectModal));
