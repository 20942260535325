import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router";
import HeaderContainer from "../Header";
import "./styles.less";
import Dashboard from "../Dashboard";
import StoryLists from "../StoryList";
import StoryView from "../StoryViewPage";
import Schedule from "../Schedule";
import Commissions from "../Commissions";
import NotFoundPage from "../NotFoundPage";
import FooterContainer from "../Footer";
import CreateStoryPage from "../CreateStoryPage";
import UserProfile from "../UserProfile";
import ManageContent from "../ManageContent";
import ActionLog from "../ActionLog";
import LogDetails from "../LogDetails";
import CommissionView from "../CommissionView";
import Settings from "../Settings";
import BatchPaymentDetails from "../BatchPaymentDetails";
import BatchPaymentsListPage from "../BatchPayments";
import CommissionAcceptedRejected from "../CommissionAcceptedRejected";
import AssignmentAcceptedRejected from "../AssignmentAcceptedRejected";
import TasksList from "../TasksList";
import HelpPage from "../HelpPage";

function Container() {
  const { Content, Footer } = Layout;
  return (
    <Layout className="layout">
      <HeaderContainer />
      <Content>
        <div className="content-container">
          <Switch>
            <Route path="/create-story" component={CreateStoryPage} />
            <Route path="/help" component={HelpPage} />
            <Route
              path="/create-linked-story/:storyId"
              component={CreateStoryPage}
            />
            <Route path="/story/:id/edit" component={CreateStoryPage} />
            <Route path="/story/:id" component={StoryView} />
            <Route path="/story-list/:id" component={StoryLists} />
            <Route path="/story-list" component={StoryLists} />
            <Route path="/tasks-list" component={TasksList} />
            <Route path="/schedule" component={Schedule} />
            <Route
              path="/assignment/:assignmentId/commission/:commissionId/edit"
              component={Commissions}
            />
            <Route
              path="/assignment/:assignmentId/commission/:commissionId"
              component={CommissionView}
            />
            <Route
              path="/assignment/:assignmentId/create-commission"
              component={Commissions}
            />
            <Route
              path="/assignment/:assignmentId/:actionType/:assignment_status_id"
              component={AssignmentAcceptedRejected}
            />
            <Route
              path="/commission/:commissionId/:actionType"
              component={CommissionAcceptedRejected}
            />
            <Route path="/manage-content/:id" component={ManageContent} />
            <Route path="/profile" component={UserProfile} />
            <Route path="/settings" component={Settings} />
            <Route path="/action-log" component={ActionLog} />
            <Route path="/log-details/:actionId" component={LogDetails} />
            <Route path="/batch-payments" component={BatchPaymentsListPage} />
            <Route
              path="/payment-details/:paymentId"
              component={BatchPaymentDetails}
            />
            <Route path="/" exact component={Dashboard} />
            <Route path="/" component={NotFoundPage} />
          </Switch>
        </div>
      </Content>
      <Footer>
        <FooterContainer />
      </Footer>
    </Layout>
  );
}

export default Container;
