import React, { useEffect, useState } from "react";
import showErrorMessage from "../../helpers/showErrorMessage";
import HelpPageService from "../../services/api/help-page";
import "./styles.less";

const HelpPage = () => {
  const [htmlContent, setHtmlContent] = useState();

  useEffect(() => {
    HelpPageService.GetHtmlContent()
      .then(res => {
        setHtmlContent(res.content);
      })
      .catch(e => {
        showErrorMessage(e);
      });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent || "" }} />;
};

export default HelpPage;
