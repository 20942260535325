/**
 * split given array into chunks
 * @param array
 * @param chunkSize
 * @returns {Array}
 */
export const arrayToChunks = (array = [], chunkSize = 2) => {
  const chunks = [];
  let index = 0;

  while (index < array.length) {
    chunks.push(array.slice(index, chunkSize + index));
    index += chunkSize;
  }

  return chunks;
};

/**
 * morph a given array with objects to array of objects which could be used for select or dropdown components
 * @param array
 * @param customField
 * @param keyName
 * @param labelName
 * @returns {{label: *, value: *}[]}
 */
export const toOptionArray = (
  array = [],
  customField = "",
  keyName = "id",
  labelName = "name"
) => {
  return array.map(entry =>
    customField
      ? {
          value: entry[keyName],
          label: entry[labelName],
          [customField]: entry[customField]
        }
      : {
          value: entry[keyName],
          label: entry[labelName]
        }
  );
};

/**
 * morph an given array to array of objects which could be used for tree select component
 * @param array
 * @param keyName
 * @param labelName
 * @param childrenKey
 * @returns {{children, title: *, value: *}[]}
 */
export const toTreeSelectOptions = (
  array = [],
  keyName = "id",
  labelName = "name",
  childrenKey = "child"
) => {
  return array.map(entry => {
    return {
      // field should be disabled if it has children (disable level 1)
      disabled: !!(entry[childrenKey] && !!entry[childrenKey].length),
      value: entry[keyName],
      title: entry[labelName],
      children: toTreeSelectOptions(
        entry[childrenKey],
        keyName,
        labelName,
        childrenKey
      )
    };
  });
};

export const toTreeSelectOptionsWithoutDisabled = (
  array = [],
  keyName = "id",
  labelName = "name",
  childrenKey = "child"
) => {
  return array.map(entry => {
    return {
      value: entry[keyName],
      title: entry[labelName],
      company_id: entry.company_id,
      children: toTreeSelectOptionsWithoutDisabled(
        entry[childrenKey],
        keyName,
        labelName,
        childrenKey
      )
    };
  });
};

export const toCascaderOptions = (
  array = [],
  keyName = "id",
  labelName = "name",
  childrenKey = "child"
) => {
  if (array.length === 0) {
    return [];
  }
  // eslint-disable-next-line consistent-return
  return array.map(entry => {
    return {
      value: entry[keyName],
      label: entry[labelName],
      children: toCascaderOptions(
        entry[childrenKey],
        keyName,
        labelName,
        childrenKey
      )
    };
  });
};

export const getRoleTitle = entry => {
  const allRoles = [];
  entry.roles.forEach(role => allRoles.push(role.title));
  if (allRoles.length > 1) {
    return allRoles.join(",");
  }
  return allRoles[0];
};

export const toOptionArrayWithEmailAndUserType = (
  array = [],
  keyName = "id",
  labelName = "name",
  email = "email"
) => {
  return array.map(entry => ({
    value: entry[keyName],
    label: entry[labelName],
    email: entry[email],
    role: getRoleTitle(entry)
  }));
};

export default {
  arrayToChunks,
  toOptionArray,
  toTreeSelectOptions,
  toTreeSelectOptionsWithoutDisabled,
  toOptionArrayWithEmailAndUserType,
  toCascaderOptions,
  getRoleTitle
};
