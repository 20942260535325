import { message } from "antd";

function showErrorMessage(error) {
  if (error.response && error.response.data) {
    if (typeof error.response.data.message === "string") {
      message.error(error.response.data.message);
      console.error("Error: ", error);
      return;
    }
    Object.values(error.response.data.message).forEach(arr => {
      const messageText = arr.join(" ");
      message.error(`${messageText}`);
    });
  }
  console.error("Error: ", error);
}

export default showErrorMessage;
