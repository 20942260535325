import { types } from "mobx-state-tree";
import Entity from "./Entity";
import Event from "./Event";

const Filters = types.model("Filters", {
  entities: types.array(Entity),
  events: types.array(Event)
});

export default Filters;
