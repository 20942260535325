const getAddress = address => {
  if (!address) {
    return "No info";
  }

  const modyfiedAdress = Object.keys(address).reduce((acc, key) => {
    if (!address[key]) {
      return { ...acc, [key]: "" };
    }
    return { ...acc, [key]: `${address[key]}, ` };
  }, {});

  return `${modyfiedAdress.address1}${modyfiedAdress.address2}${
    modyfiedAdress.postcode
  }${modyfiedAdress.city}${modyfiedAdress.country.slice(0, -2)}`;
};

export default getAddress;
