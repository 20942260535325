import { types } from "mobx-state-tree";

const StoryStatus = types.model("StoryStatus", {
  id: types.maybeNull(types.number),
  slug: types.optional(types.string, "no_status"),
  name: types.optional(types.string, "No Status"),
  company_id: types.maybeNull(types.number),
  priority: types.maybeNull(types.number),
  color_code: types.optional(types.string, "")
});

export default StoryStatus;
