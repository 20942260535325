import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const mobileMenuBurger = (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H26V2H0V0Z" fill="#9CA1AC" />
    <path d="M0 9H26V11H0V9Z" fill="#9CA1AC" />
    <path d="M0 18H26V20H0V18Z" fill="#9CA1AC" />
  </svg>
);
