import React, { Component } from "react";
import "./styles.less";
import { Card, Icon, Spin } from "antd";
import { inject, observer } from "mobx-react";
import AccessControl from "../../models/AccessControl";
import Integrations from "./Integration";

class Settings extends Component {
  state = {
    isAbleToView: false
  };

  componentDidMount = async () => {
    const { SettingsStore: store } = this.props;
    const isAbleToView = await AccessControl.can("settings:view-settings");
    this.setState({
      isAbleToView
    });
    if (isAbleToView) {
      store.loadIntegrationsData();
      store.loadIntegrationList();
    }
  };

  onBackButtonClick = () => {
    const { history } = this.props;
    if (history.length > 1) {
      history.goBack();
    }
  };

  render() {
    const { isAbleToView } = this.state;
    const {
      SettingsStore: { loadingIntegrationsData, integrationData },
      SettingsStore: store
    } = this.props;
    return (
      <div className="settings-wrapper">
        {isAbleToView && (
          <>
            <div className="back-link" onClick={this.onBackButtonClick}>
              <Icon type="arrow-left" />
              Back
            </div>
            <h1 className="page-heading">Settings</h1>
            {loadingIntegrationsData && (
              <div className="filters-spinner">
                <Spin size="large" />
              </div>
            )}
            {!loadingIntegrationsData && (
              <Card
                className="story-panel"
                bordered={false}
                title="Integrations"
              >
                {integrationData.brands.map(brand => {
                  return (
                    <Integrations
                      brand={brand}
                      key={brand.name}
                      store={store}
                    />
                  );
                })}
              </Card>
            )}
          </>
        )}
        {!isAbleToView && (
          <div>
            Sorry, you don&prime;t have the permission to view this page.
          </div>
        )}
      </div>
    );
  }
}

export default inject("SettingsStore")(observer(Settings));
