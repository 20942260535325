import { flow, types } from "mobx-state-tree";
import { message } from "antd";
import IntegrationData from "./IntegrationData";
import Integration from "./Integration";
import SettingsService from "../../services/api/settings";
import showErrorMessage from "../../helpers/showErrorMessage";

const initialSnapshot = {
  loadingIntegrationsData: false,
  loadingIntegrationList: false,
  savingIntegration: false,
  integrationData: { integration_types: [], brands: [] },
  integrationList: []
};

const SettingsStore = types
  .model("SettingsStore", {
    loadingIntegrationsData: types.optional(types.boolean, false),
    loadingIntegrationList: types.optional(types.boolean, false),
    savingIntegration: types.optional(types.boolean, false),
    checkConnectionInProgress: types.map(types.maybeNull(types.boolean)),
    checkConnectionSuccess: types.map(types.maybeNull(types.boolean)),
    integrationData: types.frozen(IntegrationData),
    integrationList: types.array(Integration)
  })
  .actions(self => {
    return {
      loadIntegrationsData: flow(function* loadIntegrationsData() {
        self.loadingIntegrationsData = true;
        try {
          const responseData = yield SettingsService.getIntegrationsInfo();
          self.integrationData = responseData;
          self.loadingIntegrationsData = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingIntegrationsData = false;
        }
      }),

      loadIntegrationList: flow(function* loadIntegrationList(params) {
        self.loadingIntegrationList = true;
        try {
          const responseData = yield SettingsService.getIntegrationList(params);
          self.integrationList = responseData;
          self.loadingIntegrationList = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingIntegrationList = false;
        }
      }),

      saveIntegration: flow(function* saveIntegration(params) {
        self.savingIntegration = true;
        try {
          const response = yield SettingsService.saveIntegration(params);
          self.integrationList.push(response);
          self.savingIntegration = false;
          message.success("The Integration was successfully saved.");
        } catch (error) {
          showErrorMessage(error);
          self.savingIntegration = false;
        }
      }),

      updateIntegration: flow(function* updateIntegration(id, params) {
        self.savingIntegration = true;
        try {
          const response = yield SettingsService.updateIntegration(id, params);
          const target = self.integrationList.find(obj => obj.id === id);
          target.changeSettings(response.settings);
          self.savingIntegration = false;
          message.success("The Integration was successfully updated.");
        } catch (error) {
          showErrorMessage(error);
          self.savingIntegration = false;
        }
      }),

      switchIntegration: flow(function* switchIntegration(id, params) {
        self.savingIntegration = true;
        try {
          yield SettingsService.switchIntegration(id, params);
          self.savingIntegration = false;
          message.success("The Integration was successfully updated.");
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.savingIntegration = false;
          message.error(`${error}`);
        }
      }),

      checkConnection: flow(function* checkConnection(id) {
        self.checkConnectionInProgress.set(id, true);
        try {
          yield SettingsService.checkConnection(id);
          self.checkConnectionSuccess.set(id, true);
          message.success("The connection was successfully checked.");
          self.checkConnectionInProgress.set(id, false);
        } catch (error) {
          self.checkConnectionSuccess.set(id, false);
          console.error("Error: ", error);
          message.error("The connection check failed.");
          self.checkConnectionInProgress.set(id, false);
        }
      })
    };
  });

const settingsStore = SettingsStore.create(initialSnapshot);

export default settingsStore;
