import React, { useEffect, useState } from "react";
import "./styles.less";
import { inject, observer } from "mobx-react";
import { Card, Col, Icon, Row, Spin } from "antd";
import AccessControl from "../../models/AccessControl";
import goBackHandler from "../../helpers/goBackHandler";

function LogDetails({
  match,
  history,
  LogDetailsStore: store,
  LogDetailsStore: { logDetailsInfo }
}) {
  const [isAbleToViewPage, setIsAbleToViewPage] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const isAbleToView = await AccessControl.can(
        "action-log:view-action-log"
      );
      setIsAbleToViewPage(isAbleToView);
      if (isAbleToView) {
        store.loadLogDetailsInfo(match.params.actionId);
      }
    }
    fetchData();
    return store.reset();
  }, [match.params.actionId, store]);

  const getReference = () => {
    const urlFirstPart = `${window.location.protocol}//${window.location.host}`;
    switch (logDetailsInfo.entity) {
      case "App\\Models\\BatchPayment":
        return `${urlFirstPart}/payment-details/${logDetailsInfo.entity_id}`;
      case "App\\Models\\Commission":
        return `${urlFirstPart}/assignment/${logDetailsInfo.assignment_id}/commission/${logDetailsInfo.entity_id}`;
      case "App\\Models\\Stories\\Story":
        return `${urlFirstPart}/story/${logDetailsInfo.entity_id}`;
      default:
        return null;
    }
  };

  return (
    <>
      {isAbleToViewPage && (
        <div className="create-story-container">
          <div
            className="back-link"
            onClick={goBackHandler.bind(this, history, "action-log")}
          >
            <Icon type="arrow-left" />
            Back to the Actions Log List
          </div>
          <Row>
            <Col xl={24}>
              <h2>Log Item Details</h2>
            </Col>
          </Row>
          <Spin spinning={store.loadingLogDetailsInfo}>
            <Card
              className="story-panel commission-details log-details"
              bordered={false}
              title={`Log Item #${match.params.actionId}`}
            >
              <Row className="log-details-row">
                <div className="log-details-row-title">Date</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.created_at}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Event</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.event}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Entity ID</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.entity_id}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Entity</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.entity}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Agent ID</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.agent_id}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Agent Email</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.agent_email}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Agent IP</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.agent_ip}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">WEB Agent</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.agent_web}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Request URL</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.request_url}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">Old Values</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.old_values}
                </div>
              </Row>

              <Row className="log-details-row">
                <div className="log-details-row-title">New Values</div>
                <div className="log-details-row-description">
                  {logDetailsInfo.new_values}
                </div>
              </Row>
              {getReference() && (
                <Row className="log-details-row">
                  <div className="log-details-row-title">Reference</div>
                  <div className="log-details-row-description">
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href={getReference()} target="_blank">
                      {getReference()}
                    </a>
                  </div>
                </Row>
              )}
            </Card>
          </Spin>
        </div>
      )}
      {!isAbleToViewPage && (
        <div>Sorry, you don&prime;t have the permission to view this page.</div>
      )}
    </>
  );
}

export default inject("LogDetailsStore")(observer(LogDetails));
