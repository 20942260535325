import React from "react";
import { Spin } from "antd";
import DescriptionForm from "./DescriptionForm";
import Button from "../../common/Button";
import { iconClose } from "./icons/cross";
import { iconDownload } from "./icons/download";
import AddLinkButton from "./AddLinkButton";
import ContentLinks from "./ContentLinks";

function Image({
  ManageContentStore,
  assignment,
  uploadFileToTheServer,
  downloadFile,
  deleteFile,
  uploadLink,
  completed,
  loading
}) {
  const inputOpenFileRef = React.createRef();

  const handleDescriptionSubmit = values => {
    ManageContentStore.setDescriptionToAssignment(
      assignment.id,
      values.description
    );
  };

  const addFileButtonHandler = () => {
    inputOpenFileRef.current.click();
  };

  const handleAddFile = async fileList => {
    const keys = Object.keys(fileList);

    // we are using await inside for of loop to avoid issue with uploading multiple images
    /* eslint-disable-next-line no-restricted-syntax */
    for (const key of keys) {
      /* eslint-disable-next-line no-await-in-loop */
      await uploadFileToTheServer(fileList[key]);
    }
  };

  const filesAttached = assignment.files.length !== 0;
  const linksAttached = assignment.links.length !== 0;

  return (
    <Spin spinning={loading}>
      <DescriptionForm
        handleDescriptionSubmit={handleDescriptionSubmit}
        assignment={assignment}
        completed={completed}
      />
      {filesAttached && (
        <div className="label-description-wrapper">
          <span className="label">Files</span>
          <div className="attached-images">
            {assignment.files.map(image => {
              return (
                <div className="uploaded-image-file" key={image.id}>
                  <div className="image-container">
                    <div className="overlay">
                      <div
                        className="icon-upload"
                        onClick={downloadFile(image.s3_link)}
                      >
                        {iconDownload}
                      </div>
                      {!completed && (
                        <div
                          className="icon-cross"
                          onClick={deleteFile(image.id)}
                        >
                          {iconClose}
                        </div>
                      )}
                    </div>
                    <img
                      src={image.s3_link}
                      alt={image.filename}
                      className="uploaded-image"
                    />
                  </div>
                  <span className="uploaded-image-file-name">
                    {image.filename}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {linksAttached && (
        <ContentLinks
          assignment={assignment}
          completed={completed}
          deleteFile={deleteFile}
        />
      )}
      {!completed && (
        <>
          <Button
            text={filesAttached ? "Add Another Image" : "Add Image"}
            theme="blue-outline"
            size="small"
            onClick={addFileButtonHandler}
            className="add-text-content-button"
            disabled={loading}
          />
          <AddLinkButton
            uploadLink={uploadLink}
            text={linksAttached ? "Add Another Image Link" : "Add Image Link"}
            loading={loading}
          />
        </>
      )}
      <input
        ref={inputOpenFileRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={e => handleAddFile(e.target.files)}
      />
    </Spin>
  );
}

export default Image;
