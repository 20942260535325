import React from "react";
import { Link } from "react-router-dom";
import { Col, Form, Icon, Row, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import CommissionFormState from "../../models/forms/CommissionFormState";
import FormContext from "../../models/FormContext";
import Button from "../../common/Button";
// import sub-components
import CommissionDetails from "./CommissionDetails";
import CostAllocation from "./CostAllocation";
import Costs from "./Costs";
import SpecialTerms from "./SpecialTerms";
// styles
import "./styles.less";
import AccessControl from "../../models/AccessControl";
import ContributorView from "../CommissionView/ContributorView";

const API_ROOT = process.env.REACT_APP_ASSETS_BASE_BATH;

class CreateCommissionPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = new CommissionFormState(
      { ...props.CommissionStore.formConfig },
      { options: { validateOnInit: false, showErrorsOnReset: false } }
    );
    this.state = {
      customValidationFailed: false,
      canCreateOrEditCommission: false,
      costsToRemove: [],
      costAllocationsToRemove: []
    };
  }

  componentDidMount = async () => {
    const {
      CommissionStore: store,
      match: {
        params: { assignmentId, commissionId }
      }
    } = this.props;
    const canCreateOrEditCommission = await AccessControl.can(
      "commission:read-write-commission"
    );
    this.setState({
      canCreateOrEditCommission
    });
    if (canCreateOrEditCommission) {
      store.load(assignmentId, commissionId).then(() => {
        this.form.init(toJS(store.commission));
      });
    }
  };

  componentWillUnmount() {
    const { CommissionStore: store } = this.props;
    store.clear();
  }

  addCostToRemoveList = costId => {
    const { costsToRemove } = this.state;
    const removeList = [...costsToRemove];
    removeList.push(costId);
    this.setState({
      costsToRemove: removeList
    });
  };

  addCostAllocationToRemoveList = costAllocationId => {
    const { costAllocationsToRemove } = this.state;
    const removeList = [...costAllocationsToRemove];
    removeList.push(costAllocationId);
    this.setState({
      costAllocationsToRemove: removeList
    });
  };

  handleSubmit = () => {
    const {
      match: {
        params: { assignmentId, commissionId }
      },
      CommissionStore,
      CommissionStore: { commission },
      history
    } = this.props;

    const { costsToRemove, costAllocationsToRemove } = this.state;

    // custom validation for percentage or amount fields
    let costSum = 0;
    let amountSum = 0;

    this.form.$("costs").fields.forEach(cost => {
      costSum += +cost.$("cost").value;
    });
    if (costSum) {
      this.form.$("cost_allocations").fields.forEach(allocation => {
        amountSum += +allocation.$("amount").value;
      });
      const customValidationFails =
        +costSum.toFixed(4) !== +amountSum.toFixed(4);
      if (customValidationFails) {
        this.setState({
          customValidationFailed: true
        });
        return;
      }
      this.setState({
        customValidationFailed: false
      });
    }

    // mobx-react-form validation
    this.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        CommissionStore.saveCommission(
          {
            ...this.form.values(),
            costs_to_remove: costsToRemove,
            cost_allocations_to_remove: costAllocationsToRemove
          },
          assignmentId
        ).then(() => {
          if (commissionId) {
            history.push(
              `/assignment/${assignmentId}/commission/${commissionId}`
            );
          } else {
            history.push(
              `/assignment/${assignmentId}/commission/${commission.id}`
            );
          }
        });
      }
    });
  };

  openFile = link => () => {
    window.open(`${API_ROOT}${link}`, "_blank");
  };

  handleCancel = e => {
    const {
      history,
      CommissionStore: { createCommissionData },
      match: {
        params: { commissionId, assignmentId }
      }
    } = this.props;
    this.form.onReset(e);
    if (commissionId) {
      history.push(`/assignment/${assignmentId}/commission/${commissionId}`);
    } else {
      history.push(`/story/${createCommissionData.story_id}`);
    }
  };

  render() {
    const {
      CommissionStore: store,
      CommissionStore: { createCommissionData },
      match: {
        params: { commissionId }
      }
    } = this.props;
    const { canCreateOrEditCommission } = this.state;
    const pageTitle = commissionId ? "Edit Commission" : "Create Commission";
    const buttonText = commissionId ? "Save" : "Create";
    const { customValidationFailed } = this.state;
    return (
      <>
        {canCreateOrEditCommission && (
          <div className="create-story-container">
            <Link
              className="back-link"
              to={`/story/${createCommissionData.story_id}`}
            >
              <Icon type="arrow-left" />
              Back to the Story
            </Link>
            <Row>
              <Col xl={24}>
                <h2>{pageTitle}</h2>
              </Col>
            </Row>
            <Spin spinning={store.loading}>
              <Form hideRequiredMark>
                <FormContext.Provider value={this.form}>
                  <CommissionDetails
                    commissionId={commissionId}
                    openFile={this.openFile}
                  />
                  <ContributorView commissionInfo={createCommissionData} />
                  <SpecialTerms />
                  <Costs
                    commissionId={commissionId}
                    addCostToRemoveList={this.addCostToRemoveList}
                  />
                  <CostAllocation
                    customValidationFailed={customValidationFailed}
                    commissionId={commissionId}
                    addCostAllocationToRemoveList={
                      this.addCostAllocationToRemoveList
                    }
                  />
                </FormContext.Provider>
              </Form>
            </Spin>
            <div className="buttons-container">
              <Button
                text="Cancel"
                onClick={e => this.handleCancel(e)}
                theme="blue-outline"
                disabled={store.loading}
              />
              <Button
                text={buttonText}
                onClick={this.handleSubmit}
                theme="solid-blue"
                disabled={store.loading}
              />
            </div>
          </div>
        )}
        {!canCreateOrEditCommission && (
          <div>
            Sorry, you don&prime;t have the permission to view this page.
          </div>
        )}
      </>
    );
  }
}

export default inject("CommissionStore")(observer(CreateCommissionPage));
