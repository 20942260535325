import moment from "moment";
import { types } from "mobx-state-tree";
import User from "./user";
import StoryStatus from "./story-status";
import { getColorForAssignmentStatus } from "../../helpers/getColorForStatus";
import EditableModel from "./editable-model";
import momentDate from "../types/momentDate";
import StoryCommission from "./story-commission";
import Category from "./category";
import ContentType from "./content-type";

const Assignment = types
  .model("Assignment", {
    id: types.maybeNull(types.number),
    story_id: types.maybeNull(types.number),
    user_id: types.maybeNull(types.number),
    type_id: types.maybeNull(types.number),
    notify_user: types.maybeNull(types.boolean),
    task_brief: types.maybeNull(types.string),
    deadline: types.maybeNull(types.union(types.string, momentDate)),
    task_start_date: types.maybeNull(types.union(types.string, momentDate)),
    assignment_status_id: types.maybeNull(types.number),
    user: types.maybeNull(User),
    user_creator: types.maybeNull(User),
    assignment_status: types.optional(StoryStatus, () => StoryStatus.create()),
    type: types.maybeNull(
      types.model({
        id: types.identifierNumber,
        name: types.string,
        icon: types.maybeNull(types.string),
        subtype: types.maybeNull(types.string)
      })
    ),
    commissions: types.optional(types.array(StoryCommission), []),
    content_exist: types.maybeNull(types.boolean),
    // fields for tasks list
    story: types.maybeNull(
      types.model("story", {
        category: types.maybeNull(Category),
        title: types.maybeNull(types.string),
        content_type: types.maybeNull(ContentType),
        story_status: types.optional(StoryStatus, () => StoryStatus.create())
      })
    )
  })
  .actions(self => ({
    changeStatus(newAssignment_status, newAssignment_status_id) {
      self.assignment_status = newAssignment_status;
      self.assignment_status_id = newAssignment_status_id;
    }
  }))
  .views(self => ({
    get typeKey() {
      return self.type.name.replace(/\s/g, "_").toLowerCase();
    },
    get deadlineDate() {
      if (self.deadline) {
        return moment(self.deadline).format();
      }
      return "No date";
    },
    get statusColor() {
      return getColorForAssignmentStatus(self.assignment_status.slug);
    },
    get taskStartDate() {
      if (self.task_start_date) {
        return moment(self.task_start_date).format();
      }

      return "No date";
    }
  }));

export default types.compose(
  Assignment,
  EditableModel
);
