import { types } from "mobx-state-tree";

const PlatformType = types.model("PlatformType", {
  id: types.number,
  name: types.string
});

export default PlatformType;
/*

              "platform_type": {
                "id": 2,
                "name": "Social"
              }

 */
