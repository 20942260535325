import React from "react";
import getCorrectUrl from "../helpers/getCorrectUrl";

function BrandAndPlatformName({ platforms, row, isAbleToViewCompanies }) {
  const firstPlatform = platforms[0];
  const companyName = row?.company?.name ? `(${row.company.name})` : "";

  if (!firstPlatform) {
    return "N/A";
  }

  const { brand, platform_type } = firstPlatform.platform_name;
  return (
    <>
      <div className="brand-icon">
        <img
          src={getCorrectUrl(row.platforms[0].platform_name.brand.icon_url)}
          alt="brand-icon"
        />
      </div>
      {isAbleToViewCompanies ? (
        <span className="table-data-text">
          {`${brand.name} ${companyName} / ${platform_type.name}`}
        </span>
      ) : (
        <span className="table-data-text">
          {`${brand.name} / ${platform_type.name}`}
        </span>
      )}
    </>
  );
}

export default BrandAndPlatformName;
