import React from "react";
import { observer } from "mobx-react";
import SVG from "react-inlinesvg";
import { Card, Col, Row } from "antd";
import { asyncReactor } from "async-reactor";
import Paragraph from "antd/lib/typography/Paragraph";
import Button from "../../common/Button";
import EditableStatus from "../../common/EditableStatus";
import AccessControl from "../../models/AccessControl";
import ApproveButtonWithModal from "./components/ApproveButtonWithModal";
import { getColorForCommissionStatus } from "../../helpers/getColorForStatus";

const IsAbleToChangeAssignmentStatus = asyncReactor(
  async ({ assignment, status, statuses, store }) => {
    const isAbleToChangeAssignmentStatus = await AccessControl.can(
      "commission:change-commission-status",
      { status }
    );

    return (
      <EditableStatus
        status={assignment.assignment_status}
        type="dot"
        context="assignment"
        availableStatuses={statuses && statuses.select_options}
        editable={isAbleToChangeAssignmentStatus}
        onChange={statusId => {
          store.changeAssignmentStatus(assignment.id, statusId);
        }}
      />
    );
  }
);

const ManageContentButton = asyncReactor(async ({ assignment, status }) => {
  const isAbleToManageContent = await AccessControl.can(
    "assignments:manage-content",
    { assignment }
  );
  if (!isAbleToManageContent || status.slug === "complete") {
    return null;
  }
  return (
    <div className="assignment-button manage-content-button">
      <Button
        href={`/manage-content/${assignment.id}`}
        theme="primary-link"
        htmlType="link"
        size="small"
        text="Manage Content"
        icon="upload"
      />
    </div>
  );
});

export const ViewContentButton = ({ assignment }) => {
  return (
    <div className="assignment-button manage-content-button">
      <Button
        href={`/manage-content/${assignment.id}`}
        theme="primary-link"
        htmlType="link"
        size="small"
        text="View Content"
        icon="view"
        onClick={e => e.stopPropagation()}
      />
    </div>
  );
};

const CommissionButtons = ({ assignment }) => {
  if (assignment.commissions) {
    return assignment.commissions.map(commission => {
      const commissionCode = `0000${commission.id}`.slice(-5);
      const buttonText = (
        <div className="button-text-container">
          <span>Commission {commissionCode}</span>
          <div className="status-container">
            <span
              className="status-dot"
              style={{
                backgroundColor: getColorForCommissionStatus(
                  commission.status_slug
                )
              }}
            />
            <span className="status-name">{commission.status}</span>
          </div>
        </div>
      );
      return (
        <Button
          key={commission.id}
          text={buttonText}
          theme="black-outline"
          size="small"
          htmlType="link"
          href={`/assignment/${assignment.id}/commission/${commission.id}`}
          className="commission-button"
        />
      );
    });
  }
  return null;
};

const CreateCommissionButton = asyncReactor(async ({ assignment }) => {
  const canCreateCommission = await AccessControl.can(
    "assignments:create-commission",
    { assignment }
  );
  if (!canCreateCommission) {
    return null;
  }
  return (
    <Button
      theme="black-outline"
      size="small"
      htmlType="link"
      href={`/assignment/${assignment.id}/create-commission`}
      text="Create Commission"
      className="create-commission-button assignment-button"
    />
  );
});

const ApproveContentButton = asyncReactor(
  async ({ assignment, store, status }) => {
    const canApproveContent = await AccessControl.can(
      "assignments:approve-content",
      { assignment }
    );
    if (!canApproveContent || status.slug === "complete") {
      return null;
    }

    return <ApproveButtonWithModal store={store} assignment={assignment} />;
  }
);

function StoryAssignments({ story, store, statuses }) {
  return (
    <Card
      className="story-panel"
      bordered={false}
      title="Assignments"
      loading={store.loadingStatuses}
    >
      {story.assignments.map((assignment, i) => {
        const isCompleted = assignment.assignment_status.slug === "complete";
        const userCreator = assignment.user_creator;

        return (
          <Row
            key={`platform-description-${i}`}
            className="bordered assignments-row"
            gutter={20}
            type="flex"
          >
            <Col lg={5} xs={24} id={assignment.id}>
              <span className="label">Types of Tasks / Assignments</span>
              <div className="description">
                <div className="assignment-type">
                  <SVG className="assignment-icon" src={assignment.type.icon} />
                  <span>{assignment.type.name}</span>
                </div>
              </div>
            </Col>
            <Col lg={6} xs={24}>
              <span className="label">Assignee</span>
              <div className="description">
                <div>
                  {assignment.user.name}
                  {" /"}
                </div>
                <div>{assignment.user.primaryRole.title}</div>
              </div>
              {userCreator && (
                <div className="task-creator">
                  <span className="label">Task Creator</span>
                  <div className="description">
                    <div>
                      {assignment.user_creator.name}
                      {" /"}
                    </div>
                    <div>{assignment.user_creator.primaryRole.title}</div>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={5} xs={24}>
              <span className="label">Task Brief</span>
              <div className="description task-brief">
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {assignment.task_brief}
                </Paragraph>
              </div>
            </Col>
            <Col lg={4} xs={24}>
              <div className="task_start_date">
                <span className="label">Task Start Date</span>
                <div className="description">{assignment.taskStartDate}</div>
              </div>
              <div className="deadline">
                <span className="label">Task Deadline</span>
                <div className="description">{assignment.deadlineDate}</div>
                <div className="description status">
                  <IsAbleToChangeAssignmentStatus
                    assignment={assignment}
                    status={assignment.assignment_status}
                    store={store}
                    statuses={statuses}
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} xs={24} className="assignments-buttons-wrapper">
              {!isCompleted ? (
                <ManageContentButton
                  assignment={assignment}
                  status={assignment.assignment_status}
                />
              ) : (
                <ViewContentButton assignment={assignment} />
              )}
              {!isCompleted && (
                <ApproveContentButton
                  assignment={assignment}
                  store={store}
                  status={assignment.assignment_status}
                />
              )}
              {!isCompleted && (
                <CreateCommissionButton assignment={assignment} />
              )}
              <CommissionButtons assignment={assignment} />
            </Col>
          </Row>
        );
      })}
    </Card>
  );
}

export default observer(StoryAssignments);
