import { applySnapshot, flow, types } from "mobx-state-tree";
import moment from "moment";
import { toJS } from "mobx";
import { message } from "antd";
import Story from "./Story";
import StoryService from "../../services/api/stories";
import showMessage from "../../helpers/showMessage";
import modificationToFormData from "../../helpers/modificationToFormData";
import SignatureData from "../content/SignatureData";
import showErrorMessage from "../../helpers/showErrorMessage";

const StoryEditStore = types
  .model("StoryEditStore", {
    loadingStory: types.optional(types.boolean, false),
    loadingOptions: types.optional(types.boolean, false),
    loadingS3SignatureData: types.optional(types.boolean, false),
    uploadingFile: types.optional(types.boolean, false),
    sendingUploadedFileInfo: types.optional(types.boolean, false),
    deletingFile: types.optional(types.boolean, false),
    savingStory: types.optional(types.boolean, false),
    story: types.maybeNull(Story),
    selectOptions: types.frozen({}),
    S3SignatureData: types.frozen(SignatureData)
  })
  .actions(self => {
    return {
      /**
       * load options for dropdowns
       */
      loadMetadata: flow(function* loadMetadata(id) {
        self.loadingOptions = true;
        try {
          self.selectOptions = yield StoryService.getMetadata(id);
          // find "No Status" entry and assign it as an initial status
          const initialStatus = self.selectOptions.statuses.find(
            status => status.slug === "no_status"
          );

          if (initialStatus) {
            self.story.story_status_id = initialStatus.id;
          }
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingOptions = false;
        }
      }),
      changeStatusInternal(newStatusId) {
        const targetStatus = self.selectOptions.statuses.find(
          status => status.id === newStatusId
        );

        self.story.story_status = targetStatus;
        self.story.story_status_id = newStatusId;
      },
      /**
       * load single story by id
       */
      loadStoryData: flow(function* load(id) {
        self.loadingStory = true;

        try {
          const data = yield StoryService.load(id);
          applySnapshot(self.story, data);

          // assign a snapshot load loadedSnapshot for rollbacks
        } catch (e) {
          self.loadingStory = false;
          return Promise.reject();
        }

        self.loadingStory = false;
        return Promise.resolve();
      }),
      /**
       * load form data
       */
      load: flow(function* load(id) {
        yield self.loadMetadata(id);
        if (id) {
          yield self.loadStoryData(id);
        }
      }),
      clear: () => {
        applySnapshot(self.story, {});
      },
      /**
       * save or update single story
       */
      save: flow(function* save(values) {
        self.savingStory = true;
        if (values.id) {
          // eslint-disable-next-line no-underscore-dangle
          values._method = "PUT";
        }
        if (!values.story_status_id) {
          values.story_status_id = 6; // set status "No status"
        }

        if (self.story.story_status.id) {
          values.story_status = self.story.story_status;
        }

        if (values.attachments) {
          // we don't need to send attachments in this request, because we send it to S3
          delete values.attachments;
        }
        // we have to remove redundant fields from the request to avoid bugs
        values.platforms.forEach(platform => {
          if (self.isPrint(platform.platform_name_id)) {
            delete platform.publication_date;
            delete platform.publication_time;
          } else {
            delete platform.issue_id;
          }
          if (platform.idea) {
            delete platform.publication_date;
            delete platform.publication_time;
            delete platform.issue_id;
          }
        });
        const modifiedValues = modificationToFormData(values);
        try {
          const response = yield StoryService.save(modifiedValues);
          const { entity } = response;
          applySnapshot(self.story, entity);
          showMessage(response);
          self.savingStory = false;
          return Promise.resolve();
        } catch (e) {
          showMessage(e);
          // eslint-disable-next-line no-console
          console.warn(e);
          self.savingStory = false;
          return Promise.reject();
        }
      }),

      linkStory: flow(function* linkStory(parentId, childId) {
        self.savingStory = true;
        try {
          const response = yield StoryService.linkStory(parentId, childId);

          showMessage(response);
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.savingStory = false;
        }
      }),

      loadIssueOptions: flow(function* loadIssueOptions(platform_name_id) {
        const issues = yield StoryService.getIssuesByPlatformName(
          platform_name_id
        );
        return issues;
      }),

      /**
       * change a status of the assignment
       * @param assignment
       * @param statusId
       */
      changeAssignmentStatus(assignment, statusId) {
        const { assignmentStatuses } = self.selectOptions;
        const status = assignmentStatuses.find(
          statusEntry => statusEntry.id === statusId
        );

        if (status) {
          assignment.assignment_status_id = statusId;
          applySnapshot(assignment.assignment_status, status);
        }
      },

      loadS3SignatureData: flow(function* loadS3SignatureData(story_id) {
        self.loadingS3SignatureData = true;
        try {
          const responseData = yield StoryService.getSignatureData(story_id);
          self.S3SignatureData = responseData;
          self.loadingS3SignatureData = false;
        } catch (error) {
          self.loadingS3SignatureData = false;
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
        }
      }),

      uploadFile: flow(function* uploadFile(formData, storyId, fileInfo) {
        self.uploadingFile = true;
        try {
          yield StoryService.uploadFileToS3(formData);
          yield StoryService.sendUploadedFileInfo(storyId, fileInfo);
          message.success("The file has been uploaded successfully!");
          self.uploadingFile = false;
        } catch (error) {
          message.error("The file wasn't uploaded!");
          message.error(`${error}`);
          self.uploadingFile = false;
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
        }
      }),

      deleteFile: flow(function* deleteFile(id) {
        self.deletingFile = true;
        try {
          // eslint-disable-next-line no-unused-vars
          const responseData = yield StoryService.deleteAttachment(id);
          message.success("The file has been removed successfully!");
          self.deletingFile = false;
        } catch (error) {
          message.error(`${error}`);
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.deletingFile = false;
        }
      }),

      getDefaultEntityStatus(statuses = []) {
        const status = statuses.find(
          statusEntry => statusEntry.slug === "no_status"
        );
        return status?.id;
      }
    };
  })
  .views(self => ({
    get loading() {
      return (
        self.loadingStory ||
        self.loadingOptions ||
        self.loadingS3SignatureData ||
        self.uploadingFile ||
        self.sendingUploadedFileInfo ||
        self.deletingFile ||
        self.savingStory
      );
    },
    get isPrint() {
      return platformNameId => {
        if (!platformNameId) {
          return false;
        }

        const printPlatform = this.selectOptions.platformTypes.find(
          platformType => platformType.name.toLowerCase() === "print"
        );

        if (!printPlatform) {
          return false;
        }

        const chosenPlatformName = this.selectOptions.platformNames.find(
          ({ id }) => id === platformNameId
        );

        return printPlatform.id === chosenPlatformName?.platform_type.id;
      };
    },
    get createdStoryId() {
      return self.story.id;
    },
    get defaultAssignmentStatus() {
      const { assignmentStatuses } = self.selectOptions;
      const status = assignmentStatuses.find(
        statusEntry => statusEntry.slug === "offered"
      );
      return status.id;
    },
    get attachments() {
      const attachedFiles = toJS(self.story.attachments);
      const fileLists = [];
      if (attachedFiles) {
        attachedFiles.map(file => {
          return fileLists.push({
            uid: file.id,
            name: file.title,
            url: file.url
          });
        });
      }
      return fileLists;
    },
    get getDigitalPlatforms() {
      const digitalPlatformsIds = new Set([]);
      self.selectOptions.platformNames.forEach(platform => {
        if (platform.platform_type.name === "Digital") {
          digitalPlatformsIds.add(platform.id);
        }
      });
      return digitalPlatformsIds;
    },
    get getPrintPlatforms() {
      const printPlatformsIds = new Set([]);
      self.selectOptions.platformNames.forEach(platform => {
        if (platform.platform_type.name === "Print") {
          printPlatformsIds.add(platform.id);
        }
      });
      return printPlatformsIds;
    },
    get formConfig() {
      return {
        fields: [
          "title",
          "category_id",
          "content_type_id",
          "note",
          "attachments",
          "story_status_id",
          "assignments[].task_brief",
          "assignments[].type_id",
          "assignments[].user_id",
          "assignments[].notify_user",
          "assignments[].user_creator",
          "assignments[].task_start_date",
          "assignments[].deadline",
          "assignments[].assignment_status_id",
          "platforms[].platform_name_id",
          "platforms[].platform_publish_type_id",
          "platforms[].idea",
          "platforms[].issue_id",
          "platforms[].publication_date",
          "platforms[].publication_time",
          "platforms[].platform_status_id"
        ],
        placeholders: {
          title: "e.g. Cannes celebrities photo",
          category_id: "Select Category or Type",
          content_type_id: "Select Content Type",
          note: "e.g. short story description",
          attachments: "Attach files (PDF, doc, jpg, png)",
          "assignments[].task_brief": "e.g. short task description",
          "assignments[].user_id": "Select User From the List",
          "assignments[].task_start_date": "e.g. 01.01.2023",
          "assignments[].deadline": "e.g. 01.01.2020",
          "platforms[].platform_name_id": "Select Platform From the List",
          "platforms[].platform_publish_type_id": "Select Publish Type"
        },
        rules: {
          title: "required|string|between:3,255",
          category_id: "required",
          content_type_id: "required",
          note: "string|between:3,1000",
          "assignments[].task_brief": "required|string|between:3,1000",
          "assignments[].user_id": "required",
          "assignments[].type_id": "required",
          "assignments[].task_start_date": "required",
          "assignments[].deadline": "required",
          "platforms[].platform_name_id": "required"
        },
        input: {
          // booleans
          "assignments[].notify_user": v => v || false,
          "platforms[].idea": v => v || false,
          // dates
          "assignments[].task_start_date": v => {
            if (self.story.id === null) {
              return moment();
            }

            return v ? moment(v) : null;
          },
          "assignments[].deadline": v => (v ? moment(v) : null),
          "platforms[].publication_date": v => (v ? moment(v) : null),
          "platforms[].publication_time": v =>
            v ? moment(v, "HH:mm:ss") : null,
          // selects
          category_id: v => {
            if (v) {
              let parentId;
              self.selectOptions.categories.forEach(category => {
                if (category.child.find(obj => obj.id === v)) {
                  parentId = category.child.find(obj => obj.id === v).parent_id;
                }
              });
              return [parentId, v];
            }
            return v || undefined;
          },
          content_type_id: v => v || undefined,
          "assignments[].user_id": v => v || undefined,
          "assignments[].user_creator": v => v || undefined,
          "platforms[].platform_name_id": v => v || undefined,
          "platforms[].platform_publish_type_id": v => v || undefined
        },
        output: {
          "platforms[].publication_date": v => v && v.format("YYYY-MM-DD"),
          "platforms[].publication_time": v => v && v.format("HH:mm"),
          "assignments[].task_start_date": v => v && v.format("YYYY-MM-DD"),
          "assignments[].deadline": v => v && v.format("YYYY-MM-DD"),
          category_id: v => {
            return v && v[v.length - 1];
          }
        },
        labels: {
          title: "Story Title",
          category_id: "Category",
          content_type_id: "Story Type",
          note: "Note",
          attachments: "Story Attachments",
          "assignments[].task_brief": "Task Brief",
          "assignments[].user_id": "Assign User",
          "assignments[].user_creator": "Task Creator",
          "assignments[].notify_user": "Notify User",
          "assignments[].task_start_date": "Task Start Date",
          "assignments[].deadline": "Task Deadline",
          "platforms[].platform_name_id": "Platforms & Platform Types",
          "platforms[].platform_publish_type_id": "Schedule",
          "platforms[].idea": "Idea",
          "platforms[].publication_date": "Date",
          "platforms[].publication_time": "Time",
          "platforms[].issue_id": "Issue"
        },
        observers: {
          "platforms[].platform_name_id": [
            {
              key: "value",
              call: async ({ field }) => {
                const isIdea = field.container().$("idea").value;
                if (!self.isPrint(field.value) || isIdea) {
                  return;
                }
                const issueField = field.container().$("issue_id");
                // we have to clear issue field every time we change platform
                issueField.clear();
                if (issueField.value === 0) {
                  issueField.set(null);
                }
                issueField.set(
                  "extra",
                  await self.loadIssueOptions(field.value)
                );
              }
            }
          ]
        },
        hooks: {
          "assignments[].assignment_status_id": {
            onInit: field => {
              if (!field.value) {
                field.value = self.defaultAssignmentStatus;
              }
            }
          },
          "assignments[].user_creator": {
            onInit: field => {
              if (!field.value) {
                field.value = undefined;
              }
            }
          },
          "platforms[].platform_status_id": {
            onInit: field => {
              if (!field.value) {
                field.value = self.getDefaultEntityStatus(
                  self.selectOptions.platformStatuses
                );
              }
            }
          },
          "platforms[].platform_name_id": {
            onInit: field => {
              if (field.value) {
                setTimeout(async () => {
                  const issueField = field.container().$("issue_id");
                  issueField.set(
                    "extra",
                    await self.loadIssueOptions(field.value)
                  );
                }, 0);
              }
            }
          }
        }
      };
    }
  }));

const storyEditStore = StoryEditStore.create({
  loadingStory: false,
  loadingOptions: false,
  savingStory: false,
  selectOptions: {},
  story: Story.create(),
  S3SignatureData: {}
});

export default storyEditStore;
