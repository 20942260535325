import React, { useContext, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Col, Icon, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import shortid from "shortid";
import Spin from "antd/es/spin";
import Field from "../../common/FieldContainer";
import { toOptionArray } from "../../helpers/array-utils";
import FormContext from "../../models/FormContext";
import Button from "../../common/Button";
import { ReactComponent as IconTrash } from "../../images/icons/trash.svg";
import { COMMISSION_COSTS_MAX_COUNT } from "../../config/constants";
import ModalComponent from "../../common/Modal/index";
import getHighestKeyFromMap from "../../helpers/getHighestKeyFromMap";

const CostForm = observer(
  ({
    store,
    store: { options },
    field,
    costs,
    formState,
    addCostToRemoveList
  }) => {
    const [showModal, setShowModal] = useState(false);

    const onRemoveCostHandler = () => {
      setShowModal(true);
    };

    const onSubmitHandler = () => {
      if (field.value.id) {
        addCostToRemoveList(+field.value.id);
      }
      costs.del(field.key);
      setShowModal(false);
    };

    const onCancelHandler = () => {
      setShowModal(false);
    };

    const getCurrencyFieldValue = () => {
      return formState.$("currency_id").value
        ? options.currencies?.find(
            currency => currency.id === formState.$("currency_id").value
          ).name
        : "EUR";
    };

    return (
      <Spin spinning={store.loading}>
        <Row gutter={20} className="cost-form-row">
          <Col lg={7} md={7}>
            <Field field={field.$("expense_type_id")}>
              <Select
                suffixIcon={<Icon type="caret-down" theme="filled" />}
                className="expense-type"
                dropdownMatchSelectWidth={false}
              >
                {toOptionArray(options.cost_expense_types).map(
                  ({ label, value }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  )
                )}
              </Select>
            </Field>
          </Col>
          <Col lg={3} md={3}>
            <Field field={field.$("cost")}>
              <Input className="cost-input" />
            </Field>
          </Col>
          <Col lg={6} md={6}>
            <Field field={field.$("vat_rate_id")}>
              <Select suffixIcon={<Icon type="caret-down" theme="filled" />}>
                {toOptionArray(options.cost_vat_rates).map(
                  ({ label, value }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  )
                )}
              </Select>
            </Field>
          </Col>
          <Col lg={2} md={2} className="cost-small-field">
            <div className="label-description-wrapper">
              <span className="label">Tax, %</span>
              <div className="description">{field.$("tax").value}</div>
            </div>
          </Col>
          <Col lg={2} md={2} className="cost-small-field">
            <div className="label-description-wrapper">
              <span className="label">VAT</span>
              <div className="description">
                {Math.floor(+field.$("vat").value * 100) / 100}
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} className="cost-small-field">
            <div className="label-description-wrapper">
              <span className="label">Total</span>
              <div className="description">
                {Math.round(+field.$("total").value * 100) / 100}
              </div>
            </div>
          </Col>
          <Col lg={2} md={2} className="cost-small-field">
            <div className="label-description-wrapper">
              <span className="label">Currency</span>
              <div className="description"> {getCurrencyFieldValue()}</div>
            </div>
          </Col>
        </Row>
        <Row className="cost-form-delete-row">
          {costs.fields.size !== 1 && (
            <Col className="delete-col">
              <Icon component={IconTrash} onClick={onRemoveCostHandler} />
            </Col>
          )}
        </Row>

        <ModalComponent
          onCancel={onCancelHandler}
          onSubmit={onSubmitHandler}
          showModal={showModal}
        />
      </Spin>
    );
  }
);

function Costs({ store, store: { options }, addCostToRemoveList }) {
  const formState = useContext(FormContext);
  const costs = formState.$("costs");

  const className =
    costs.fields.size >= COMMISSION_COSTS_MAX_COUNT ? "hidden" : "";

  const getTotalSum = () => {
    return formState
      .$("costs")
      .values()
      .reduce((sum, current) => {
        if (!current.total) {
          return sum;
        }
        return sum + +current.total;
      }, 0);
  };

  const getCardTitle = () => {
    return (
      <>
        <div>
          Costs
          <span className="costs-title-currency-total">
            {formState.$("currency_id").value
              ? options.currencies?.find(
                  currency => currency.id === formState.$("currency_id").value
                ).name
              : ""}{" "}
            {getTotalSum() ? Math.round(getTotalSum() * 100) / 100 : ""}
          </span>
        </div>
      </>
    );
  };

  return (
    <div>
      <Card
        className="special-terms-panel costs-panel"
        title={getCardTitle()}
        bordered={false}
        actions={[
          <Button
            text="New Cost"
            size="small"
            theme="green-outline"
            icon="add"
            onClick={() => {
              costs.add({
                id: shortid.generate()
              });
              // set currency to the new field if it had been set before
              if (formState.$("currency_id").value) {
                // determine the key of new cost field
                const lastFieldKey = getHighestKeyFromMap(costs.fields);
                // set the value
                costs.fields
                  .get(lastFieldKey.toString())
                  .$("currency")
                  .set("value", formState.$("currency_id").value);
              }
            }}
            className={className}
          />
        ]}
      >
        <div className="special-terms-form">
          <Row className="costs-description-row" gutter={20}>
            <Col lg={16} md={16} className="costs-description-col">
              <Field name="costs_description">
                <TextArea rows={2} />
              </Field>
            </Col>
            <Col lg={3} md={3}>
              <Field name="currency_id">
                <Select suffixIcon={<Icon type="caret-down" theme="filled" />}>
                  {toOptionArray(options.currencies).map(({ label, value }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Field>
            </Col>
          </Row>
          {costs.map((cost, i) => {
            return (
              <CostForm
                key={`cost-form-${i}`}
                field={cost}
                costs={costs}
                store={store}
                formState={formState}
                addCostToRemoveList={addCostToRemoveList}
              />
            );
          })}
        </div>
      </Card>
    </div>
  );
}

export default inject(stores => ({ store: stores.CommissionStore }))(
  observer(Costs)
);
