import { types } from "mobx-state-tree";
import IntegrationType from "./IntegrationType";
import Brand from "../common/brand";
import Settings from "./Settings";

const Integration = types
  .model("Integration", {
    id: types.optional(types.number, 0),
    integration_type: types.frozen(IntegrationType),
    brand: types.frozen(Brand),
    is_enabled: types.optional(types.boolean, false),
    is_checked: types.optional(types.boolean, false),
    settings: types.maybeNull(Settings)
  })
  .actions(self => ({
    changeSettings(newSettings) {
      self.settings = newSettings;
    }
  }));

export default Integration;
