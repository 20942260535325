import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import LinkedStoriesPopupContent from "./LinkedStoriesPopupContent/LinkedStoriesPopupContent";
import getCorrectUrl from "../../helpers/getCorrectUrl";

const PlatformsModal = ({
  PlatformsModal: {
    storyId,
    showLinkedStories,
    visible,
    list,
    setVisible,
    isLinkedStory
  },
  StoryViewStore: { story },
  StoryListStore
}) => {
  const [targetStory, setTargetStory] = useState(null);

  useEffect(() => {
    if (!storyId) {
      return;
    }
    let foundStory = null;
    if (story && isLinkedStory) {
      const stories = [
        ...story.children?.map(({ story_child }) => story_child),
        ...story.parent?.map(({ story_parent }) => story_parent)
      ];
      foundStory = stories.find(linkedStory => storyId === linkedStory.id);
    } else {
      foundStory = StoryListStore.stories.find(
        linkedStory => storyId === linkedStory.id
      );
    }

    setTargetStory(foundStory);
  }, [storyId, StoryListStore.stories, story, isLinkedStory]);

  const parentStories = targetStory?.linkedParentStories?.map(linkedStory => {
    if (linkedStory) {
      return (
        <LinkedStoriesPopupContent
          story={linkedStory}
          status="Parent"
          key={linkedStory.id}
        />
      );
    }
    return null;
  });

  const childStories = targetStory?.linkedChildStories?.map(linkedStory => {
    if (linkedStory) {
      return (
        <LinkedStoriesPopupContent
          story={linkedStory}
          status="Child"
          key={linkedStory.id}
        />
      );
    }
    return null;
  });

  return (
    <>
      {visible && targetStory && (
        <Modal
          title={
            showLinkedStories
              ? `Linked to the Story "${targetStory.title}"`
              : `All Brands / Platforms Related to the Story "${targetStory.title}"`
          }
          visible={visible}
          onCancel={() => setVisible(false)}
          centered
          footer={null}
          className="story-list-modal-container"
        >
          {showLinkedStories ? (
            <div>
              {parentStories}
              {childStories}
            </div>
          ) : (
            targetStory.platforms &&
            targetStory[`${list}`]?.map((platformDescription, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="story-list-modal-content-paragraph">
                  <div className="brand-icon">
                    <img
                      src={getCorrectUrl(
                        targetStory.platforms[index].platform_name.brand
                          .icon_url
                      )}
                      alt="brand-icon"
                    />
                  </div>
                  {platformDescription}
                  {targetStory.platforms[index].idea && " - Story Idea"}
                </div>
              );
            })
          )}
        </Modal>
      )}
    </>
  );
};

export default inject("PlatformsModal", "StoryListStore", "StoryViewStore")(
  observer(PlatformsModal)
);
