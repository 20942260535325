import React from "react";
import { Table } from "antd";
import { disposeOnUnmount, inject, observer, Observer } from "mobx-react";
import { reaction } from "mobx";
import moment from "moment";
import BatchPaymentsListFilter from "./BatchPaymentsListFilter";
import EditableStatus from "../../common/EditableStatus";
import "./styles.less";
import Button from "../../common/Button";
import arrow from "../../common/paginationIcons";
import NewBatchModal from "./components/NewBatchModal";
import getUrlParams from "../../helpers/getUrlParams";
import createSearchString from "../../helpers/createSearchString";
import { toOptionArray } from "../../helpers/array-utils";
import AccessControl from "../../models/AccessControl";
import { pageSizeOptions } from "../../constants/pagination";

class BatchPaymentsListPage extends React.Component {
  state = {
    showModal: false,
    isAbleToView: false
  };

  column = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sortDirections: ["descend", "ascend"],
      sorter: true,
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.BatchPaymentStore.filtersInitialValues
        .sorting.id
    },
    {
      title: "Generated Date",
      dataIndex: "generated_date",
      key: "generated_date",
      sortDirections: ["descend", "ascend"],
      sorter: true,
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.BatchPaymentStore.filtersInitialValues
        .sorting.generatedDate,
      render: generated_date => {
        const generatedDate = moment(generated_date);
        const parsedDate = generatedDate.format("DD.MM.YY, HH:mm");
        return <p>{parsedDate}</p>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sortDirections: ["descend", "ascend"],
      sorter: true,
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.BatchPaymentStore.filtersInitialValues
        .sorting.status,
      render: (status, row) => {
        const {
          BatchPaymentStore: { options }
        } = this.props;
        return (
          <Observer>
            {() => (
              <EditableStatus
                availableStatuses={toOptionArray(options.statuses)}
                status={status}
                onChange={this.batchPaymentStatusChangeHandler(row)}
                context="batch-payment"
              />
            )}
          </Observer>
        );
      }
    },
    {
      title: "Commissions",
      dataIndex: "commissions_in_batch_payment",
      key: "commissions_in_batch_payment",
      sortDirections: ["descend", "ascend"],
      sorter: true,
      // eslint-disable-next-line react/destructuring-assignment
      defaultSortOrder: this.props.BatchPaymentStore.filtersInitialValues
        .sorting.countCommissions
    },
    {
      title: "Download",
      dataIndex: "link_to_download",
      render: link_to_download => {
        return (
          <Button
            htmlType="link"
            text="Download"
            icon="download"
            size="small"
            theme="primary-link"
            onClick={this.downloadFileHandler(link_to_download)}
          />
        );
      }
    }
  ];

  componentDidMount = async () => {
    const { BatchPaymentStore } = this.props;
    const isAbleToView = await AccessControl.can(
      "batch-payments:view-batch-payments"
    );
    this.setState({ isAbleToView });
    if (isAbleToView) {
      BatchPaymentStore.loadFilters();
      BatchPaymentStore.setAppliedFilters();
      disposeOnUnmount(
        this,
        reaction(
          () => BatchPaymentStore.filtersRequestParams,
          () => {
            BatchPaymentStore.loadBatchPayments(
              BatchPaymentStore.filtersRequestParams
            );
          },
          { fireImmediately: true }
        )
      );
    }
  };

  componentDidUpdate(prevProps) {
    const {
      BatchPaymentStore: { setAppliedFilters },
      location: { search }
    } = this.props;
    if (prevProps.location.search !== search) {
      setAppliedFilters();
    }
  }

  downloadFileHandler = downloadLink => event => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = downloadLink;
  };

  batchPaymentStatusChangeHandler = row => status => {
    row.changeStatus(row.id, status);
  };

  paginationItemRender = (page, type) => {
    if (type === "prev") {
      return (
        <span className="pagination-arrow-back">
          <span>{arrow}</span>
          Back
        </span>
      );
    }
    if (type === "next") {
      return (
        <span className="pagination-arrow-next">
          Next
          <span>{arrow}</span>
        </span>
      );
    }
    return null;
  };

  showTotal = (totalItems, fromToArr) => {
    const {
      BatchPaymentStore: { total }
    } = this.props;
    return `${fromToArr[0]}-${fromToArr[1]} of ${total}`;
  };

  createNewBatchHandler = () => {
    this.setState({ showModal: true });
  };

  onSubmitHandler = () => {
    const { BatchPaymentStore, history } = this.props;
    BatchPaymentStore.createNewBatch().then(() => {
      this.setState({ showModal: false });
      history.push(`/payment-details/${BatchPaymentStore.batchPaymentId}`);
    });
  };

  onCancelHandler = () => {
    this.setState({ showModal: false });
  };

  rowTableClickHandler = record => {
    const { history } = this.props;
    return {
      onClick: () => {
        history.push({
          pathname: `/payment-details/${record.id}`,
          from: "batch-payments"
        });
      }
    };
  };

  onShowSizeChange = (currentPage, newSize) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    history.push(createSearchString({ ...urlParams, limit: newSize }));
  };

  tableChangeHandler = (pagination, tableFilter, sorter) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    urlParams.page = pagination.current;
    const namesMap = new Map([
      ["id", "id"],
      ["generated_date", "generatedDate"],
      ["status", "status"],
      ["commissions_in_batch_payment", "countCommissions"]
    ]);
    switch (sorter.order) {
      case "ascend":
        urlParams.sortByAsc = namesMap.get(sorter.field);
        delete urlParams.sortByDesc;
        break;
      case "descend":
        urlParams.sortByDesc = namesMap.get(sorter.field);
        delete urlParams.sortByAsc;
        break;
      default:
        delete urlParams.sortByAsc;
        delete urlParams.sortByDesc;
    }
    history.push(createSearchString(urlParams));
  };

  render() {
    const {
      BatchPaymentStore: {
        currentPage,
        perPage,
        total,
        filtersInitialValues,
        options,
        loading
      },
      BatchPaymentStore: store,
      history
    } = this.props;
    const { showModal, isAbleToView } = this.state;
    return (
      <div>
        {isAbleToView && (
          <div>
            <div className="filters-and-new-batch-button-section">
              <BatchPaymentsListFilter
                history={history}
                filtersInitialValues={filtersInitialValues}
                options={options}
              />
              <Button
                text="New Batch"
                icon="add"
                theme="solid-blue"
                size="large"
                className="new-batch-button"
                onClick={this.createNewBatchHandler}
                disabled={loading}
              />
            </div>
            <NewBatchModal
              showModal={showModal}
              onSubmit={this.onSubmitHandler}
              onCancel={this.onCancelHandler}
              disabled={loading}
            />
            <Table
              columns={this.column}
              dataSource={store.batchPayments}
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: pageSizeOptions.low,
                onShowSizeChange: this.onShowSizeChange,
                size: "large",
                pageSize: perPage,
                total,
                current: currentPage,
                showTotal: this.showTotal,
                itemRender: this.paginationItemRender
              }}
              onChange={this.tableChangeHandler}
              onRow={this.rowTableClickHandler}
              loading={loading}
            />
          </div>
        )}
        {!isAbleToView && (
          <div>
            Sorry, you don&prime;t have the permissions to view this page
          </div>
        )}
      </div>
    );
  }
}
export default inject("BatchPaymentStore")(observer(BatchPaymentsListPage));
