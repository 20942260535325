import React from "react";
import { Tabs } from "antd";
import "./styles.less";
import BrandCardsList from "./components/BrandCardsList";

function Dashboard() {
  const { TabPane } = Tabs;
  return (
    <div>
      <div className="tabs-container">
        <Tabs defaultActiveKey="brands" type="card">
          <TabPane tab="Platforms" key="brands">
            <BrandCardsList />
          </TabPane>
          {/* <TabPane tab="Categories" key="categories"> */}
          {/*  Categories tab content */}
          {/* </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
}

export default Dashboard;
