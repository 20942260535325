import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const iconDownload = (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999306 10.6953V22.0621C0.999306 23.1988 1.91999 24.146 3.02482 24.146H24.0165C25.1213 24.146 26.042 23.1988 26.042 22.0621V10.6953"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13.5181 -0.00135587L13.5181 15.7227"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.7785 11.3644L13.5178 16.6689L7.25708 11.3644"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);
