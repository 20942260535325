import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const iconDelete = (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.88818 8.96729V17.0771"
      stroke="#9CA1AC"
      strokeWidth="1.1795"
      strokeMiterlimit="10"
    />
    <path
      d="M12.4893 8.96729V17.0771"
      stroke="#9CA1AC"
      strokeWidth="1.1795"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5132 21.3459H4.99572C4.33837 21.3459 3.8125 20.7768 3.8125 20.0654V4.69922H16.8279V19.9231C16.6964 20.7768 16.1705 21.3459 15.5132 21.3459Z"
      stroke="#9CA1AC"
      strokeMiterlimit="10"
    />
    <path
      d="M14.7247 4.69904H5.65332V2.13816C5.65332 1.42681 6.1792 1 6.70508 1H13.5415C14.1989 1 14.5933 1.56908 14.5933 2.13816V4.69904H14.7247Z"
      stroke="#9CA1AC"
      strokeMiterlimit="10"
    />
    <path d="M0 4.69922H20.3776" stroke="#9CA1AC" strokeMiterlimit="10" />
  </svg>
);
