import { flow, types } from "mobx-state-tree";
import { message } from "antd";
import UserProfileService from "../../services/api/user-profile";
import Details from "./Details";
import Settings from "./Settings";
import showErrorMessage from "../../helpers/showErrorMessage";

const UserProfileStore = types
  .model("UserProfileStore", {
    loadingUserInfo: types.optional(types.boolean, false),
    changingUserInfo: types.optional(types.boolean, false),
    details: types.maybeNull(Details),
    settings: types.maybeNull(Settings)
  })
  .actions(self => ({
    load: flow(function* load(id) {
      self.loadingUserInfo = true;
      try {
        const { profile } = yield UserProfileService.loadUserInfo(id);
        self.details = profile.Details;
        self.settings = profile.Settings;
        self.loadingUserInfo = false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error: ", error);
        self.loadingUserInfo = false;
      }
    }),
    changeUserInfo: flow(function* changeUserInfo(params) {
      self.changingUserInfo = true;
      try {
        // eslint-disable-next-line no-unused-vars
        const { profile } = yield UserProfileService.updateUser(params);
        self.details = profile.Details;
        self.settings = profile.Settings;
        message.success("The info has been changed successfully!");
        self.changingUserInfo = false;
      } catch (error) {
        showErrorMessage(error);
        self.changingUserInfo = false;
      }
    })
  }))
  .views(self => ({
    get additionalPermissions() {
      if (!self.details["Additional Permissions"]?.length) {
        return "No information";
      }
      return self.details["Additional Permissions"].join(", ");
    },
    get jobRole() {
      if (!self.details[`Job Role`]) {
        return "No information";
      }
      return self.details[`Job Role`];
    },
    get loading() {
      return self.loadingUserInfo || self.changingUserInfo;
    }
  }));

const userProfileStore = UserProfileStore.create({
  loadingUserInfo: false,
  changingUserInfo: false,
  details: { [`Brand Access`]: [] },
  settings: {}
});

export default userProfileStore;
