import { AccessControlPlus } from "accesscontrol-plus";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_CLIENT_ADMIN,
  USER_ROLE_CONTRIBUTOR,
  USER_ROLE_FREELANCER,
  USER_ROLE_EMPLOYEE
} from "../config/roles";

const contentCouldBeApproved = ({ user }) => {
  return user.allPermissions.includes("assignment-approve-content");
};

const canManageContent = ({ assignment: { user: assignee }, user }) => {
  if (user.primaryRole.name !== USER_ROLE_CONTRIBUTOR) {
    return true;
  }
  return assignee.id === user.id;
};

const commissionCanBeCreated = ({
  assignment: { user, commissions, assignment_status }
}) => {
  if (assignment_status.slug === "complete") {
    return false;
  }
  return user.primaryRole.name === USER_ROLE_CONTRIBUTOR && !commissions.length;
};

const canUseSettings = ({ user }) => {
  return user.primaryRole.name === USER_ROLE_ADMIN;
};

const canUseActionLog = ({ user }) => {
  return (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  );
};

const canUseBatchPayments = ({ user }) => {
  return (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  );
};

// commissions user abilities
const canReadWriteCommission = ({ user }) => {
  return user.primaryRole.name !== USER_ROLE_CONTRIBUTOR;
};

const canChangeCommissionStatus = ({ user, commissionInfo }) => {
  if (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  ) {
    return true;
  }
  if (
    user.primaryRole.name === USER_ROLE_EMPLOYEE ||
    user.primaryRole.name === USER_ROLE_FREELANCER
  ) {
    return commissionInfo.approver_user.email === user.email;
  }
  return false;
};

const canApproveCommission = ({ user, commissionInfo }) => {
  if (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  ) {
    return true;
  }
  if (
    user.primaryRole.name === USER_ROLE_EMPLOYEE ||
    user.primaryRole.name === USER_ROLE_FREELANCER
  ) {
    return (
      commissionInfo.approver_user.email === user.email &&
      user.allPermissions.includes("commission-approve")
    );
  }
  return false;
};

const canRejectCommission = ({ user, commissionInfo }) => {
  if (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  ) {
    return true;
  }
  if (
    user.primaryRole.name === USER_ROLE_EMPLOYEE ||
    user.primaryRole.name === USER_ROLE_FREELANCER
  ) {
    return (
      commissionInfo.approver_user.email === user.email &&
      user.allPermissions.includes("commission-reject")
    );
  }
  return false;
};

const canRejectSentToContributorCommission = ({ user }) => {
  return user.primaryRole.name === USER_ROLE_ADMIN;
};

const canAcceptSentToContributorCommission = ({ user }) => {
  return user.primaryRole.name === USER_ROLE_CONTRIBUTOR;
};

const canApproveManagerApprovedCommission = ({ user }) => {
  return (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  );
};

// stories user abilities
const canDeleteStory = ({ user }) => {
  return (
    user.primaryRole.name === USER_ROLE_ADMIN ||
    user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
  );
};

const canCreateNewStory = ({ user }) => {
  return user.primaryRole.name !== USER_ROLE_CONTRIBUTOR;
};

const canChangeAssignmentStatus = ({ user, status }) => {
  if (status?.slug === "complete") {
    return (
      user.primaryRole.name === USER_ROLE_ADMIN ||
      user.primaryRole.name === USER_ROLE_CLIENT_ADMIN
    );
  }
  return user.primaryRole.name !== USER_ROLE_CONTRIBUTOR;
};

// chat
const canDeleteChatMessage = ({ user }) => {
  return user.primaryRole.name === USER_ROLE_ADMIN;
};

// companies
const canViewCompanies = ({ user }) => {
  return user.primaryRole.name === USER_ROLE_ADMIN;
};

class AccessControl {
  acl;

  constructor() {
    this.acl = new AccessControlPlus();
    this.configure();
  }

  setUser(user) {
    this.user = user;
  }

  configure() {
    this.configureAdminRole();
    this.configureClientAdminRole();
    this.configureContributorRole();
    this.configureFreelancerRole();
    this.configureEmployeeRole();
  }

  configureAdminRole() {
    this.acl
      .grant(USER_ROLE_ADMIN)
      .inherits(USER_ROLE_CLIENT_ADMIN)
      .inherits(USER_ROLE_CONTRIBUTOR)
      .resource("action-log")
      .action("view-action-log")
      .where(canUseActionLog)
      .resource("commission")
      .action("reject-commission")
      .where(canRejectCommission)
      .action("reject-sent-to-contributor-commission")
      .where(canRejectSentToContributorCommission)
      .resource("story")
      .action("delete-story")
      .where(canDeleteStory)
      .resource("chat")
      .action("delete-message")
      .where(canDeleteChatMessage)
      .resource("story-list")
      .action("view-companies")
      .where(canViewCompanies)
      .resource("story-view-page")
      .action("view-companies")
      .where(canViewCompanies)
      .resource("create-story-page")
      .action("view-companies")
      .where(canViewCompanies)
      .resource("brands")
      .action("view-companies")
      .where(canViewCompanies);
  }

  configureClientAdminRole() {
    this.acl
      .grant(USER_ROLE_CLIENT_ADMIN)
      .resource("settings")
      .action("view-settings")
      .where(canUseSettings)
      .resource("batch-payments")
      .action("view-batch-payments")
      .where(canUseBatchPayments)
      .resource("assignments")
      .action("approve-content")
      .where(contentCouldBeApproved)
      .action("manage-content")
      .where(canManageContent)
      .action("create-commission")
      .where(commissionCanBeCreated)
      .action("change-status")
      .where(canChangeAssignmentStatus)
      .resource("commission")
      .action("change-commission-status")
      .where(canChangeCommissionStatus)
      .action("approve-commission")
      .where(canApproveCommission)
      .action("reject-commission")
      .where(canRejectCommission)
      .action("read-write-commission")
      .where(canReadWriteCommission)
      .action("approve-manager-approved-commission")
      .where(canApproveManagerApprovedCommission)
      .resource("story")
      .action("create-new-story")
      .where(canCreateNewStory)
      .action("delete-story")
      .where(canDeleteStory)
      .resource("action-log")
      .action("view-action-log")
      .where(canUseActionLog);
  }

  configureContributorRole() {
    this.acl
      .grant(USER_ROLE_CONTRIBUTOR)
      .resource("assignments")
      .action("manage-content")
      .where(canManageContent)
      .resource("commission")
      .action("accept-commission")
      .where(canAcceptSentToContributorCommission);
  }

  configureFreelancerRole() {
    this.acl
      .grant(USER_ROLE_FREELANCER)
      .resource("assignments")
      .action("manage-content")
      .where(canManageContent)
      .action("approve-content")
      .where(contentCouldBeApproved)
      .action("change-status")
      .where(canChangeAssignmentStatus)
      .action("create-commission")
      .where(commissionCanBeCreated)
      .resource("commission")
      .action("read-write-commission")
      .where(canReadWriteCommission)
      .action("change-commission-status")
      .where(canChangeCommissionStatus)
      .action("approve-commission")
      .where(canApproveCommission)
      .action("reject-commission")
      .where(canRejectCommission)
      .resource("story")
      .action("create-new-story")
      .where(canCreateNewStory);
  }

  configureEmployeeRole() {
    this.acl.grant(USER_ROLE_EMPLOYEE).inherits(USER_ROLE_FREELANCER);
  }

  async can(...args) {
    const [permissionRequest, context] = args;
    const permission = await this.acl.can(
      this.user.primaryRole.name,
      permissionRequest,
      { ...context, user: this.user }
    );
    return Boolean(permission.granted);
  }
}

export default new AccessControl();
