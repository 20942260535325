import React from "react";
import { observer } from "mobx-react";
import { Card, Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import EditableStatus from "../../common/EditableStatus";

function StoryInformation({
  story,
  handleSingleStoryStatusChange,
  statuses,
  downloadAttachment
}) {
  return (
    <Card className="story-panel" bordered={false} title="Story information">
      <Row gutter={20} type="flex">
        <Col lg={8} xs={24}>
          <span className="label">Story Title</span>
          <div className="description">{story.title}</div>
        </Col>
        <Col lg={8} xs={24}>
          <span className="label">Category</span>
          <div className="description">{story.categoryName}</div>
        </Col>
        <Col lg={8} xs={24}>
          <span className="label">Story Type</span>
          <div className="description">{story.content_type.name}</div>
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col lg={8} xs={24}>
          <span className="label">Story Note</span>
          <div className="description">
            <Paragraph ellipsis={{ rows: 3, expandable: true }}>
              {story.note}
            </Paragraph>
          </div>
        </Col>
        <Col lg={8} xs={24}>
          {!!story.attachments.length && (
            <>
              <span className="label">Story Attachments</span>
              {story.attachments.map((attachment, index) => {
                return (
                  <div
                    className="description story-attachments"
                    key={attachment.id}
                    onClick={downloadAttachment(attachment.s3_link)}
                  >
                    {attachment.filename || `Attachment ${index + 1}`}
                  </div>
                );
              })}
            </>
          )}
        </Col>
        <Col lg={8} xs={24}>
          <span className="label">Status</span>
          <div className="description">
            <EditableStatus
              context="story"
              status={story.story_status}
              availableStatuses={statuses && statuses.select_options}
              onChange={handleSingleStoryStatusChange}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default observer(StoryInformation);
