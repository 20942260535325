import "../styles/variables.less";

const COLOR_AMBER = "#F2A22F";
const COLOR_GREEN = "#37D6A6";
const COLOR_RED = "#FF666F";
const COLOR_GRAY = "#C5C9DC";
const COLOR_WHITE = "#FFFFFF";

const storyStatusColorMap = new Map([
  ["published", COLOR_GRAY],
  ["top_story", COLOR_GREEN],
  ["accepted", COLOR_GREEN],
  ["proposed", COLOR_AMBER],
  ["on_hold", COLOR_AMBER],
  ["cancelled", COLOR_RED],
  ["no_status", COLOR_GRAY] // white color only for type "dot"
]);

const assignmentStatusColorMap = new Map([
  ["offered", COLOR_GRAY],
  ["accepted", COLOR_AMBER],
  ["declined", COLOR_RED],
  ["content_required", COLOR_AMBER],
  ["content_received", COLOR_GREEN],
  ["complete", COLOR_GRAY]
]);

const commissionStatusColorMap = new Map([
  ["creation_in_progress", COLOR_GRAY],
  ["sent_to_manager", COLOR_AMBER],
  ["manager_approved", COLOR_GREEN],
  ["manager_rejected", COLOR_RED],
  ["sent_to_contributor", COLOR_AMBER],
  ["contributor_approved", COLOR_GREEN],
  ["contributor_rejected", COLOR_RED],
  ["awaiting_invoice", COLOR_AMBER],
  ["invoice_approved", COLOR_GREEN],
  ["invoice_rejected", COLOR_RED],
  ["payment_approved", COLOR_GREEN],
  ["payment_requested", COLOR_AMBER],
  ["complete", COLOR_GREEN]
]);

const batchPaymentStatusesColorMap = new Map([
  ["no_status", COLOR_GRAY],
  ["sent", COLOR_AMBER],
  ["accepted", COLOR_GREEN],
  ["rejected", COLOR_RED]
]);

function getColorForStoryStatus(statusSlug, type = "tag") {
  if (type === "dot" && statusSlug === "no_status") {
    return COLOR_WHITE;
  }
  return storyStatusColorMap.get(statusSlug) || COLOR_GRAY;
}

function getColorForAssignmentStatus(statusSlug) {
  return assignmentStatusColorMap.get(statusSlug) || COLOR_GRAY;
}

function getColorForCommissionStatus(statusSlug) {
  return commissionStatusColorMap.get(statusSlug) || COLOR_GRAY;
}

function getColorForBatchStatus(statusSlug) {
  return batchPaymentStatusesColorMap.get(statusSlug) || COLOR_GRAY;
}

function getColorForStatus(statusSlug, context, type) {
  switch (context) {
    case "story":
      return getColorForStoryStatus(statusSlug, type);
    case "platform":
      return getColorForStoryStatus(statusSlug);
    case "assignment":
      return getColorForAssignmentStatus(statusSlug);
    case "batch-payment":
      return getColorForBatchStatus(statusSlug);
    case "commission":
      return getColorForCommissionStatus(statusSlug);
    default:
      return COLOR_GRAY;
  }
}

export {
  getColorForStoryStatus,
  getColorForAssignmentStatus,
  getColorForCommissionStatus,
  getColorForBatchStatus,
  getColorForStatus,
  COLOR_AMBER,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_GRAY,
  COLOR_WHITE
};
