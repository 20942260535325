import axios from "axios";

const Tasks = {
  getTasks: params => {
    return axios
      .get("/assignments", { params })
      .then(({ data: { entity } }) => entity);
  },
  getDataForTaskListFilters: () => {
    return axios
      .get(`/assignments/list/data/scope`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  approveContent: assignmentId => {
    return axios
      .post(`/content/assignment/${assignmentId}/approve`)
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  changeAssignmentStatus: (assignmentId, assignment_status_id) => {
    return axios
      .patch(`/story/assignment/update/${assignmentId}`, {
        assignment_status_id
      })
      .then(({ data: { entity } }) => {
        return entity;
      });
  },
  changeStoryStatus: (storyId, status) => {
    const statusId = Number.isInteger(status) ? status : status.id;
    return axios
      .patch(`/story/update/${storyId}/status`, {
        story_status_id: statusId
      })
      .then(({ data: { entity } }) => entity);
  },
  getStatuses: () => {
    return axios.get(`/story/edit/data/scope`).then(({ data: { entity } }) => {
      return entity;
    });
  }
};

export default Tasks;
