import React, { Component } from "react";
import { Form, Input } from "antd";
import { inject, observer } from "mobx-react";
import Button from "../../common/Button";
import ConnectionCheck from "./ConnectionCheck";

const urlRules = [
  {
    required: true,
    message: "Please input URL/API!"
  },
  {
    min: 1,
    max: 100,
    message: "The length must be between 1 and 100 chars."
  }
];
const portRules = [
  {
    required: true,
    message: "Please input port!"
  },
  {
    min: 1,
    max: 5,
    message: "The length must be between 1 and 5 chars."
  }
];
const catalogRules = [
  {
    required: true,
    message: "Please input folder!"
  },
  {
    min: 1,
    max: 100,
    message: "The length must be between 1 and 100 chars."
  }
];
const loginRules = [
  {
    required: true,
    message: "Please input login!"
  },
  {
    min: 1,
    max: 100,
    message: "The length must be between 1 and 100 chars."
  }
];
const passwordRules = [
  {
    required: true,
    message: "Please input password!"
  },
  {
    min: 1,
    max: 100,
    message: "The length must be between 1 and 100 chars."
  }
];

class SftpForm extends Component {
  state = {
    inEdit: false
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { form } = this.props;
    const { inEdit } = this.state;
    if (prevState.inEdit !== inEdit) {
      form.validateFields();
    }
  }

  hasErrors = fieldsError => {
    return Object.keys(fieldsError.settings).some(
      field => fieldsError.settings[field]
    );
  };

  handleSubmit = e => {
    const { form, onSubmit } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        onSubmit("sftp", values);
      }
    });
  };

  handleCancel = () => {
    const { inEdit } = this.state;
    this.setState({
      inEdit: !inEdit
    });
  };

  handleEdit = () => {
    this.setState({
      inEdit: true
    });
  };

  render() {
    const { inEdit } = this.state;
    const {
      form,
      getSftpIntegrationObj,
      handleCheckConnection,
      SettingsStore: { checkConnectionInProgress },
      checkConnectionSuccess,
      sftpChecked
    } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;

    const apiEndpointError =
      isFieldTouched("settings[url]") && getFieldError("settings[url]");
    const apiPortError =
      isFieldTouched("settings[port]") && getFieldError("settings[port]");
    const catalogError =
      isFieldTouched("settings[catalog]") && getFieldError("settings[catalog]");
    const loginError =
      isFieldTouched("settings[login]") && getFieldError("settings[login]");
    const passwordError =
      isFieldTouched("settings[password]") &&
      getFieldError("settings[password]");

    const connectionCheckedSuccessfully = checkConnectionSuccess.get(
      getSftpIntegrationObj().id
    );

    const connectionCheckInProgress = checkConnectionInProgress.get(
      getSftpIntegrationObj().id
    );
    return (
      <>
        {inEdit && (
          <Form
            onSubmit={this.handleSubmit}
            className="wordpress-form"
            hideRequiredMark
          >
            <div className="label-description-wrapper two-fields-wrapper">
              <div className="label-description-wrapper-part url-field">
                <span className="label">URL/IP</span>
                <Form.Item
                  validateStatus={apiEndpointError ? "error" : ""}
                  help={apiEndpointError || ""}
                >
                  {getFieldDecorator("settings[url]", {
                    rules: urlRules,
                    initialValue: getSftpIntegrationObj().settings
                      ? getSftpIntegrationObj().settings.url
                      : ""
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="label-description-wrapper-part port-field">
                <span className="label">Port</span>
                <Form.Item
                  validateStatus={apiPortError ? "error" : ""}
                  help={apiPortError || ""}
                >
                  {getFieldDecorator("settings[port]", {
                    rules: portRules,
                    initialValue: getSftpIntegrationObj().settings
                      ? getSftpIntegrationObj().settings.port
                      : ""
                  })(<Input />)}
                </Form.Item>
              </div>
            </div>

            <div className="label-description-wrapper">
              <span className="label">Folder</span>
              <Form.Item
                validateStatus={catalogError ? "error" : ""}
                help={catalogError || ""}
              >
                {getFieldDecorator("settings[catalog]", {
                  rules: catalogRules,
                  initialValue: getSftpIntegrationObj().settings
                    ? getSftpIntegrationObj().settings.catalog
                    : ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="label-description-wrapper">
              <span className="label">API login</span>
              <Form.Item
                validateStatus={loginError ? "error" : ""}
                help={loginError || ""}
              >
                {getFieldDecorator("settings[login]", {
                  rules: loginRules,
                  initialValue: getSftpIntegrationObj().settings
                    ? getSftpIntegrationObj().settings.login
                    : ""
                })(<Input />)}
              </Form.Item>
            </div>

            <div className="label-description-wrapper">
              <span className="label">Password</span>
              <Form.Item
                validateStatus={passwordError ? "error" : ""}
                help={passwordError || ""}
              >
                {getFieldDecorator("settings[password]", {
                  rules: passwordRules
                })(<Input.Password />)}
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                text="Cancel"
                htmlType="button"
                onClick={this.handleCancel}
                theme="blue-outline"
                size="small"
              />
              <Button
                text="Save"
                htmlType="submit"
                disabled={this.hasErrors(getFieldsError())}
                theme="solid-blue"
                size="small"
                className="form-save-button"
              />
            </Form.Item>
          </Form>
        )}
        {!inEdit && (
          <div className="integration-view">
            <div className="label-description-wrapper two-fields-wrapper">
              <div className="label-description-wrapper-part url-field">
                <span className="label">URL/IP</span>
                <div className="description">
                  {getSftpIntegrationObj().settings
                    ? getSftpIntegrationObj().settings.url
                    : "N/D"}
                </div>
              </div>
              <div className="label-description-wrapper-part port-field">
                <span className="label">Port</span>
                <div className="description">
                  {getSftpIntegrationObj().settings
                    ? getSftpIntegrationObj().settings.port
                    : "N/D"}
                </div>
              </div>
            </div>

            <div className="label-description-wrapper">
              <span className="label">Folder</span>
              <div className="description">
                {getSftpIntegrationObj().settings
                  ? getSftpIntegrationObj().settings.catalog
                  : "N/D"}
              </div>
            </div>

            <div className="label-description-wrapper">
              <span className="label">Login</span>
              <div className="description">
                {getSftpIntegrationObj().settings
                  ? getSftpIntegrationObj().settings.login
                  : "N/D"}
              </div>
            </div>

            <div className="integration-view-button-wrapper">
              <Button
                text="Edit"
                htmlType="button"
                onClick={this.handleEdit}
                theme="black-outline"
                size="small"
              />
              <ConnectionCheck
                handleCheckConnection={handleCheckConnection}
                checkConnectionInProgress={connectionCheckInProgress}
                checked={sftpChecked}
                connectionCheckedSuccessfully={connectionCheckedSuccessfully}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Form.create()(inject("SettingsStore")(observer(SftpForm)));
