import { types } from "mobx-state-tree";

const Vendor = types.model("Vendor", {
  currency: types.string,
  title: types.string,
  vat: types.boolean,
  full_address: types.model({
    address1: types.string,
    address2: types.maybeNull(types.string),
    city: types.string,
    country: types.string,
    postcode: types.string
  }),
  vendor_id: types.string
});

export default Vendor;
