import { types } from "mobx-state-tree";
import moment from "moment";

class CustomDate {
  date;

  constructor(str) {
    this.date = Date.parse(str);
    if (Number.isNaN(this.date)) {
      throw new Error("invalid date");
    }
  }

  toISOString() {
    return new Date(this.date).toISOString();
  }
}
const momentDate = types.custom({
  name: "momentDate",
  fromSnapshot(value) {
    return moment(value);
  },
  toSnapshot(value) {
    console.log({ value: value.format("YYYY-MM-DD") });
    return value.format("YYYY-MM-DD");
  },
  isTargetType(v) {
    return v instanceof CustomDate;
  },
  getValidationMessage(v) {
    try {
      // eslint-disable-next-line no-new
      new CustomDate(v);
      return "";
    } catch (e) {
      return e.message;
    }
  }
});

export default momentDate;
