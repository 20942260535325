import React, { Component } from "react";
import { Card, Row, Col, Icon } from "antd";
import { inject, observer } from "mobx-react";
import TextForm from "./Text";
import Video from "./Video";
import Image from "./Image";
import OtherTypes from "./OtherTypes";
// import helpers
import { mimeExtensionMap } from "../../helpers/mimeExtensionMap";
import getFileExtension from "../../helpers/getFileExtension";
import getFileNameWithHash from "../../helpers/getFileNameWithHash";
// import styles
import "./styles.less";

class ManageContent extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      ManageContentStore
    } = this.props;
    ManageContentStore.loadAssignment(id);
  }

  backToStoryHandler = () => {
    const { history } = this.props;
    const {
      ManageContentStore: {
        assignment: { story }
      }
    } = this.props;
    history.push(`/story/${story.id}`);
  };

  uploadFileToTheServer = async values => {
    const {
      match: {
        params: { id }
      },
      ManageContentStore,
      ManageContentStore: {
        assignment: { story, type }
      }
    } = this.props;

    let file;

    if (type.name === "Text") {
      // convert values to a file
      file = new Blob([`${values.textArea}`], {
        type: "text/plain;charset=utf-8"
      });
    } else {
      file = values;
    }

    // load signature
    await ManageContentStore.loadS3SignatureData(id);

    // create form data for upload request and upload file
    const formData = new FormData();
    const credentials = ManageContentStore.S3SignatureData.inputs;
    Object.keys(credentials).forEach(key => {
      if (key === "key") {
        return;
      }
      formData.append(key, credentials[key]);
    });

    let name;

    if (type.name === "Text") {
      name = getFileNameWithHash(values.title);
      formData.append("key", `content/${story.id}/${id}/${name}`);
    } else {
      name = getFileNameWithHash(values.name);
      formData.append("key", `content/${story.id}/${id}/${name}`);
    }
    formData.append("file", file); // all fields after this will be ignored

    await ManageContentStore.uploadFile(formData);

    // send uploaded file information to the server
    const fileInfo = {};
    fileInfo.s3_path = `content/${story.id}/${id}/`;
    fileInfo.mime = file.type;
    fileInfo.size = file.size;
    if (type.name === "Text") {
      fileInfo.filename = name;
      fileInfo.s3_filename = name;
    } else {
      fileInfo.filename = name;
      fileInfo.s3_filename = name;
    }
    fileInfo.extension = mimeExtensionMap.get(file.type);
    if (!fileInfo.extension) {
      fileInfo.extension = getFileExtension(fileInfo.filename);
    }

    await ManageContentStore.sendFileInfoToTheServer(fileInfo, id);
  };

  uploadLink = async link => {
    const {
      match: {
        params: { id }
      },
      ManageContentStore
    } = this.props;

    // send link information to the server
    const fileInfo = {};
    fileInfo.assignment_id = id;
    fileInfo.link = link;

    await ManageContentStore.sendFileInfoToTheServer(fileInfo, id, "link");
  };

  downloadFile = s3_link => () => {
    window.location.href = s3_link;
  };

  openVideoLink = link => () => {
    window.open(link, "_blank");
  };

  deleteFile = (fileId, type = "file") => async () => {
    const {
      ManageContentStore,
      match: {
        params: { id }
      }
    } = this.props;
    await ManageContentStore.deleteFile(fileId, type);
    // show updated info on the page
    await ManageContentStore.loadAssignment(id);
  };

  renderComponent = () => {
    const {
      ManageContentStore: {
        assignment: { type }
      },
      ManageContentStore: { assignment, loading },
      ManageContentStore
    } = this.props;

    const isCompleted = assignment.assignment_status.slug === "complete";

    switch (type.subtype) {
      case "Text":
        return (
          <TextForm
            assignment={assignment}
            uploadFileToTheServer={this.uploadFileToTheServer}
            uploadLink={this.uploadLink}
            downloadFile={this.downloadFile}
            deleteFile={this.deleteFile}
            completed={isCompleted}
            loading={loading}
          />
        );
      case "Video":
        return (
          <Video
            assignment={assignment}
            ManageContentStore={ManageContentStore}
            uploadFileToTheServer={this.uploadFileToTheServer}
            uploadLink={this.uploadLink}
            downloadFile={this.downloadFile}
            openVideoLink={this.openVideoLink}
            deleteFile={this.deleteFile}
            completed={isCompleted}
            loading={loading}
          />
        );
      case "Image":
        return (
          <Image
            assignment={assignment}
            ManageContentStore={ManageContentStore}
            uploadFileToTheServer={this.uploadFileToTheServer}
            downloadFile={this.downloadFile}
            uploadLink={this.uploadLink}
            deleteFile={this.deleteFile}
            completed={isCompleted}
            loading={loading}
          />
        );
      default:
        return (
          <OtherTypes
            assignment={assignment}
            ManageContentStore={ManageContentStore}
            uploadFileToTheServer={this.uploadFileToTheServer}
            uploadLink={this.uploadLink}
            downloadFile={this.downloadFile}
            deleteFile={this.deleteFile}
            completed={isCompleted}
            loading={loading}
          />
        );
    }
  };

  render() {
    const {
      ManageContentStore: {
        assignment: { story },
        loadingAssignmentInfo
      }
    } = this.props;

    return (
      <>
        {!loadingAssignmentInfo && (
          <React.Fragment>
            <div className="back-link" onClick={this.backToStoryHandler}>
              <Icon type="arrow-left" />
              Back to the Story
            </div>
            <h1 className="page-heading">Manage Content</h1>
            <Card className="story-panel manage-content" bordered={false}>
              <Row gutter={20} type="flex" className="profile-details-row">
                <Col xs={24}>
                  <div className="label-description-wrapper">
                    <span className="label">Story Name</span>
                    <div className="description">{story.title}</div>
                  </div>
                  {this.renderComponent()}
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default inject("ManageContentStore")(observer(ManageContent));
