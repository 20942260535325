import React, { useContext } from "react";
import { Form } from "antd";
import { observer } from "mobx-react";
import FormContext from "../models/FormContext";

export default observer(({ name, field, children, label: formItemLabel }) => {
  const formState = useContext(FormContext);
  let fieldState = field;
  if (!fieldState) {
    fieldState = formState.$(name);
  }
  const { label, hasError, error, touched, isValid, value } = fieldState;
  const fieldProps = fieldState.bind();
  const onChange = val => {
    fieldProps.onChange(val);
  };
  return (
    <Form.Item
      colon={false}
      label={label || formItemLabel}
      validateStatus={hasError && (touched || !isValid) ? "error" : ""}
      help={error}
    >
      {React.Children.map(children, child => {
        let className = value ? "hasValue" : "";
        if (Array.isArray(value)) {
          // specially for category_id field
          className = value && value.length ? "hasValue" : "";
        }
        if (field) {
          // do not highlight Types of Tasks/Assignments and Task creator field
          className =
            value && field.name !== "type_id" && field.name !== "user_creator"
              ? "hasValue"
              : "";
        }
        return React.cloneElement(child, {
          ...fieldProps,
          ...child.props,
          onChange,
          value,
          className
        });
      })}
    </Form.Item>
  );
});
