import React from "react";
import { disposeOnUnmount, inject, observer } from "mobx-react";
import { Spin, Col, Row, Empty } from "antd";
import { reaction } from "mobx";
import { withRouter } from "react-router";
import { arrayToChunks } from "../../../../helpers/array-utils";
import BrandCard from "../BrandCard";
import "./styles.less";
import Button from "../../../../common/Button";
import BrandCardsFilters from "../BrandCardsFilters";
import AccessControl from "../../../../models/AccessControl";

class BrandCardsList extends React.Component {
  state = {
    isAbleToCreateStory: false,
    isAbleToViewCompanies: false
  };

  componentDidMount = async () => {
    const { DashboardStore } = this.props;
    const isAbleToCreateStory = await AccessControl.can(
      "story:create-new-story"
    );
    const isAbleToViewCompanies = await AccessControl.can(
      "brands:view-companies"
    );
    this.setState({
      isAbleToCreateStory,
      isAbleToViewCompanies
    });
    DashboardStore.loadStatuses();
    DashboardStore.loadFilters();
    DashboardStore.setAppliedFilters();
    disposeOnUnmount(
      this,
      reaction(
        () => DashboardStore.filtersRequestParams,
        () => {
          DashboardStore.loadBrands(DashboardStore.filtersRequestParams);
        },
        {
          fireImmediately: true
        }
      )
    );
  };

  componentDidUpdate(prevProps) {
    const {
      DashboardStore: { setAppliedFilters },
      location: { search }
    } = this.props;
    if (prevProps.location.search !== search) {
      setAppliedFilters();
    }
  }

  get brandData() {
    const {
      DashboardStore: { brands }
    } = this.props;
    if (!brands.length) {
      return false;
    }
    return arrayToChunks(brands.toJSON()).map((chunk, chunkIndex) => {
      return (
        <Row className="brand-card-list-row" key={`brands-row-${chunkIndex}`}>
          {chunk.map((brand, brandIndex) => {
            return (
              <Col key={`brand-cell-${brandIndex}`} xl={12} lg={24}>
                <BrandCard
                  key={brand.id}
                  brand={brand}
                  className={
                    brandIndex % 2 === 0 ? "container-with-right-space" : ""
                  }
                />
              </Col>
            );
          })}
        </Row>
      );
    });
  }

  renderBrandCardListContainer = () => {
    if (!this.brandData) {
      return <Empty />;
    }
    return <div className="brand-cards-list-container">{this.brandData}</div>;
  };

  render() {
    const { isAbleToCreateStory, isAbleToViewCompanies } = this.state;
    const {
      DashboardStore: {
        loading,
        filtersInitialValues,
        filters,
        loadingFilters
      },
      history,
      location
    } = this.props;
    return (
      <div className="brand-card-list-wrapper">
        {loading && <Spin size="large" />}
        {!loading && (
          <div>
            <div className="filter-story-creation-option-container">
              <BrandCardsFilters
                history={history}
                filtersInitialValues={filtersInitialValues}
                filters={filters}
                loadingFilters={loadingFilters}
                isAbleToViewCompanies={isAbleToViewCompanies}
              />
              {isAbleToCreateStory && (
                <Button
                  pathname="/create-story"
                  from={location.pathname}
                  htmlType="link"
                  text="New Story"
                  size="large"
                  icon="add"
                />
              )}
            </div>
            {this.renderBrandCardListContainer()}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(inject("DashboardStore")(observer(BrandCardsList)));
