import React, { Component } from "react";
import { Form, Input } from "antd";
import Button from "../../common/Button";

const descriptionRules = [
  {
    max: 1000,
    type: "string",
    message: "The length of the description must be less than 1000 characters. "
  }
];

class DescriptionForm extends Component {
  state = {
    saved: false,
    changed: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, handleDescriptionSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        handleDescriptionSubmit(values);
        this.setState({
          saved: true
        });
      }
    });
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  onTextAreaChange = () => {
    this.setState({
      saved: false,
      changed: true
    });
  };

  render() {
    const { form, assignment, completed } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;
    const { saved, changed } = this.state;

    const { TextArea } = Input;

    const descriptionError =
      isFieldTouched("description") && getFieldError("description");

    const validationFailed = this.hasErrors(getFieldsError());
    const background =
      isFieldTouched("description") &&
      (validationFailed ? "" : "validation-success-background");

    const showSaveButton = !saved && changed;

    if (completed) {
      return (
        <div className="label-description-wrapper add-text-content-form">
          <span className="label">Description</span>
          <div>{assignment.description}</div>
        </div>
      );
    }

    return (
      <div className="custom-editable-form-wrapper add-text-content-form">
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          className="editable-form-wrapper"
        >
          <div className="label-description-wrapper">
            <span className="label">Description</span>
            <Form.Item
              validateStatus={descriptionError ? "error" : ""}
              help={descriptionError || ""}
            >
              {getFieldDecorator("description", {
                rules: descriptionRules,
                initialValue: assignment.description
              })(
                <TextArea
                  placeholder="Insert Text Here"
                  className={`add-text-content-form-textarea ${background}`}
                  onChange={this.onTextAreaChange}
                />
              )}
            </Form.Item>
          </div>
          {showSaveButton && (
            <Form.Item className="add-description-content-form-item-button-wrapper">
              <Button
                text="Save"
                htmlType="submit"
                disabled={validationFailed}
                theme="solid-blue"
                size="small"
                className="add-text-content-form-submit-button"
              />
            </Form.Item>
          )}
        </Form>
      </div>
    );
  }
}

export default Form.create()(DescriptionForm);
