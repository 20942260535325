/* eslint react/no-danger: 0 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.less";
import highlightTextParts from "../../helpers/highlightTextParts";

function ReadMore({ text, mode, length, highlightFunc }) {
  const [showAll, setShowAll] = useState(false);
  if (!text) {
    return <>No info</>;
  }
  if (text.includes("<script>")) {
    return <span>{text}</span>;
  }
  if (text.length <= length || text.length - length <= 5) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: highlightFunc(text)
        }}
      />
    );
  }
  const onMoreClickHandler = event => {
    event.stopPropagation();
    setShowAll(true);
  };
  const onLessClickHandler = event => {
    event.stopPropagation();
    setShowAll(false);
  };
  let more = (
    <span className="more-link" onClick={onMoreClickHandler}>
      more...
    </span>
  );
  const less = (
    <span className="more-link" onClick={onLessClickHandler}>
      less
    </span>
  );

  if (mode === "cutEnd") {
    more = (
      <span className="more-link" onClick={onMoreClickHandler}>
        ...more
      </span>
    );
    return (
      <>
        {!showAll && (
          <>
            <span
              className="read-more-text"
              dangerouslySetInnerHTML={{
                __html: highlightFunc(text.slice(0, length))
              }}
            />
            {more}
          </>
        )}

        {showAll && (
          <>
            <span dangerouslySetInnerHTML={{ __html: highlightFunc(text) }} />
            {less}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {!showAll && (
        <>
          {more}
          <span
            dangerouslySetInnerHTML={{
              __html: highlightFunc(text.slice(-length))
            }}
          />
        </>
      )}

      {showAll && (
        <>
          {less}
          <span dangerouslySetInnerHTML={{ __html: highlightFunc(text) }} />
        </>
      )}
    </>
  );
}

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["cutStart", "cutEnd"]),
  length: PropTypes.number,
  highlightFunc: PropTypes.func
};

ReadMore.defaultProps = {
  mode: "cutStart",
  length: 11,
  highlightFunc: highlightTextParts
};

export default ReadMore;
