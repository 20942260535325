import React, { useEffect, useState } from "react";
import { Col, Icon, Row, Switch } from "antd";
import { observer } from "mobx-react";
import { ReactComponent as iconArrow } from "../../images/icons/arrow.svg";
import WordpressForm from "./WordpressForm";
import SftpForm from "./SftpForm";

function Integrations({ brand, store }) {
  const [isExpanded, setExpanded] = useState(false);
  const [wordpressSwitchEnabled, setWordpressSwitch] = useState(false);
  const [sftpSwitchEnabled, setSftpSwitch] = useState(false);
  const [sftpChecked, setSftpChecked] = useState(false);
  const [wordpressChecked, setWordpressChecked] = useState(false);

  useEffect(() => {
    // define whether wordpress and sftp switches are enabled or not
    store.integrationList.forEach(integration => {
      if (
        integration.brand.id === brand.id &&
        integration.integration_type.slug === "wordpress" &&
        integration.is_enabled
      ) {
        setWordpressSwitch(true);
      }
      if (
        integration.brand.id === brand.id &&
        integration.integration_type.slug === "sftp" &&
        integration.is_enabled
      ) {
        setSftpSwitch(true);
      }
    });
  }, [store.integrationList, brand.id]);

  const onArrowClickHandler = () => {
    setExpanded(!isExpanded);
  };

  const getWordPressIntegrationObj = () => {
    const integrationsForBrandArr = [];
    store.integrationList.forEach(integration => {
      if (integration.brand.id === brand.id) {
        integrationsForBrandArr.push(integration);
      }
    });
    const wordpressIntegrationObj = integrationsForBrandArr.find(
      integration => integration.integration_type.slug === "wordpress"
    );
    return wordpressIntegrationObj || {};
  };

  const getSftpIntegrationObj = () => {
    const integrationsForBrandArr = [];
    store.integrationList.forEach(integration => {
      if (integration.brand.id === brand.id) {
        integrationsForBrandArr.push(integration);
      }
    });
    const sftpIntegrationObj = integrationsForBrandArr.find(
      integration => integration.integration_type.slug === "sftp"
    );
    return sftpIntegrationObj || {};
  };

  const onSwitchChange = (slug, checked) => {
    if (slug === "sftp") {
      if (getSftpIntegrationObj().id) {
        store.switchIntegration(getSftpIntegrationObj().id, {
          is_enabled: checked
        });
      }
      setSftpSwitch(checked);
    }
    if (slug === "wordpress") {
      if (getWordPressIntegrationObj().id) {
        store.switchIntegration(getWordPressIntegrationObj().id, {
          is_enabled: checked
        });
      }
      setWordpressSwitch(checked);
    }
  };

  const onFormSave = async (slug, values) => {
    let creatingMode;
    let integrationId;
    // check whether we are changing existing or creating new integration
    // and find the id of the integration
    if (slug === "sftp") {
      creatingMode = Object.entries(getSftpIntegrationObj()).length === 0;
      integrationId = getSftpIntegrationObj().id;
    }
    if (slug === "wordpress") {
      creatingMode = Object.entries(getWordPressIntegrationObj()).length === 0;
      integrationId = getWordPressIntegrationObj().id;
    }
    // find id of integration type
    const targetIntegrationTypeId = store.integrationData.integration_types.find(
      integrationType => integrationType.slug === slug
    ).id;
    if (creatingMode) {
      await store.saveIntegration({
        ...values,
        brand_id: brand.id,
        integration_type_id: targetIntegrationTypeId
      });
    } else {
      await store.updateIntegration(integrationId, {
        ...values,
        brand_id: brand.id,
        integration_type_id: targetIntegrationTypeId
      });
    }
  };

  const handleCheckConnection = slug => async () => {
    if (slug === "wordpress") {
      await store.checkConnection(getWordPressIntegrationObj().id);
      setWordpressChecked(true);
    }
    if (slug === "sftp") {
      await store.checkConnection(getSftpIntegrationObj().id);
      setSftpChecked(true);
    }
  };

  return (
    <Row gutter={20} className="integration-row">
      <div className="arrow-brand-wrapper" onClick={onArrowClickHandler}>
        <Icon
          component={iconArrow}
          className={`brand-arrow ${isExpanded ? "expanded" : "not-expanded"}`}
        />
        <div className="brand-wrapper">
          <div className="brand-icon">
            <img width={24} height={24} src={brand.icon_url} alt="brand-icon" />
          </div>
          <span className="brand-name">{brand.name}</span>
        </div>
      </div>
      {!store.loadingIntegrationList && isExpanded && (
        <div className="forms-wrapper">
          <Col md={12} sm={24}>
            <div className="settings-switch switch-container">
              <Switch
                defaultChecked={wordpressSwitchEnabled}
                onChange={checked => onSwitchChange("wordpress", checked)}
                data-testid="profile-settings-wordpress-switch-button"
              />
              <span className="switch-title">Wordpress</span>
            </div>
            {wordpressSwitchEnabled && (
              <WordpressForm
                onSubmit={onFormSave}
                getWordPressIntegrationObj={getWordPressIntegrationObj}
                handleCheckConnection={handleCheckConnection("wordpress")}
                wordpressChecked={wordpressChecked}
                checkConnectionSuccess={store.checkConnectionSuccess}
              />
            )}
          </Col>
          <Col md={12} sm={24}>
            <div className="settings-switch switch-container">
              <Switch
                defaultChecked={sftpSwitchEnabled}
                onChange={checked => onSwitchChange("sftp", checked)}
                data-testid="profile-settings-sftp-switch-button"
              />
              <span className="switch-title">SFTP</span>
            </div>
            {sftpSwitchEnabled && (
              <SftpForm
                onSubmit={onFormSave}
                getSftpIntegrationObj={getSftpIntegrationObj}
                handleCheckConnection={handleCheckConnection("sftp")}
                sftpChecked={sftpChecked}
                checkConnectionSuccess={store.checkConnectionSuccess}
              />
            )}
          </Col>
        </div>
      )}
    </Row>
  );
}

export default observer(Integrations);
