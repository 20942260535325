import { types } from "mobx-state-tree";

const File = types.model("File", {
  id: types.number,
  filename: types.string,
  s3_path: types.string,
  s3_filename: types.string,
  extension: types.string,
  size: types.number,
  mime: types.string,
  s3_link: types.string
});

export default File;
