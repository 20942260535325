import { applySnapshot, flow, types } from "mobx-state-tree";
import Details from "./Details";
import LogDetailsService from "../../services/api/log-details";

const initialSnapshot = {
  loadingLogDetailsInfo: false,
  logDetailsInfo: {}
};

const LogDetailsStore = types
  .model("LogDetailsStore", {
    loadingLogDetailsInfo: types.optional(types.boolean, false),
    logDetailsInfo: types.frozen(Details)
  })
  .actions(self => {
    return {
      loadLogDetailsInfo: flow(function* loadLogDetailsInfo(id) {
        self.loadingLogDetailsInfo = true;
        try {
          self.logDetailsInfo = yield LogDetailsService.getActionPreviewData(
            id
          );
          self.loadingLogDetailsInfo = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingLogDetailsInfo = false;
        }
      }),
      reset() {
        applySnapshot(self, initialSnapshot);
      }
    };
  });

const logDetailsStore = LogDetailsStore.create(initialSnapshot);

export default logDetailsStore;
