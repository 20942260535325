import React from "react";
import InputTextButton from "../../common/InputTextButton";

const linkRules = [
  {
    required: true,
    message: "Please input the url! "
  },
  {
    max: 200,
    message: "The length of the link must be less than 200 characters!"
  }
];

const AddLinkButton = ({ uploadLink, text, loading }) => {
  const handleSubmit = values => {
    uploadLink(values.field);
  };

  return (
    <InputTextButton
      onSubmit={handleSubmit}
      text={text}
      rules={linkRules}
      placeholder="URL"
      disabled={loading}
    />
  );
};

export default AddLinkButton;
