import { flow, types } from "mobx-state-tree";
import Action from "./Action";
import Filters from "./Filters";
import ActionLogService from "../../services/api/action-log";
import getFiltersInitialValues from "../../helpers/getFiltersInitialValues";
import getUrlParams from "../../helpers/getUrlParams";
import showErrorMessage from "../../helpers/showErrorMessage";

const initialSnapshot = {
  loadingActionLogList: false,
  loadingFilters: false,
  actionLogList: [],
  filters: { entities: [], events: [] },
  filtersInitialValues: { sorting: {} },
  filtersRequestParams: {},
  total: 0,
  perPage: 25,
  currentPageNumber: 1
};

const ActionLogStore = types
  .model("ActionLogStore", {
    loadingActionLogList: types.optional(types.boolean, false),
    loadingFilters: types.optional(types.boolean, false),
    actionLogList: types.array(Action),
    filters: types.frozen(Filters),
    filtersInitialValues: types.optional(types.frozen(), {}),
    filtersRequestParams: types.optional(types.frozen(), {}),
    total: types.optional(types.number, 0),
    perPage: types.optional(types.number, 25),
    currentPageNumber: types.optional(types.number, 1)
  })
  .views(self => ({
    get hasLoadedFilterOption() {
      return self.filters.entities.length || self.filters.events.length;
    }
  }))
  .actions(self => {
    return {
      afterCreate() {
        this.setAppliedFilters();
      },
      setAppliedFilters: function setAppliedFilters() {
        self.filtersInitialValues = getFiltersInitialValues();
        self.filtersRequestParams = getUrlParams();
      },

      loadActionLogList: flow(function* loadActionLogList(params) {
        self.loadingActionLogList = true;
        try {
          const responseData = yield ActionLogService.getActions(params);
          self.actionLogList = responseData.data;
          self.total = responseData.total;
          self.currentPageNumber = responseData.current_page;
          self.perPage = responseData.per_page;
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingActionLogList = false;
        }
      }),

      loadFilters: flow(function* loadFilters() {
        if (!self.hasLoadedFilterOption) {
          self.loadingFilters = true;
          try {
            self.filters = yield ActionLogService.getFilters();
          } catch (error) {
            showErrorMessage(error);
          } finally {
            self.loadingFilters = false;
          }
        }
      })
    };
  });

const actionLogStore = ActionLogStore.create(initialSnapshot);

export { actionLogStore, ActionLogStore };
