import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Icon, Row, Spin } from "antd";
import { inject, observer } from "mobx-react";
import AccessControl from "../../models/AccessControl";

class CommissionAcceptedRejected extends Component {
  state = {
    isAbleToView: false,
    operationSuccess: false
  };

  componentDidMount = async () => {
    const { CommissionStore: store } = this.props;
    const {
      match: {
        params: { commissionId, actionType }
      }
    } = this.props;

    const isAbleToView = await AccessControl.can(
      "commission:accept-commission"
    );
    if (isAbleToView) {
      this.setState({
        isAbleToView
      });
      store
        .updateStatusForExternalUsers(commissionId, {
          statusSlug:
            actionType === "accept"
              ? "contributor_approved"
              : "contributor_rejected"
        })
        .then(() => {
          this.setState({
            operationSuccess: true
          });
        })
        .catch(() => {
          this.setState({
            operationSuccess: false
          });
        });
    }
  };

  render() {
    const { isAbleToView, operationSuccess } = this.state;
    const {
      CommissionStore: { loading },
      match: {
        params: { actionType }
      }
    } = this.props;
    return (
      <Spin spinning={loading}>
        <div className="create-story-container">
          <Link className="back-link" to="/story-list">
            <Icon type="arrow-left" />
            Back to the Story List
          </Link>
          {(!isAbleToView || !operationSuccess) && (
            <>
              <Row>
                <Col xl={24}>
                  <h2 className="page-title">
                    {" "}
                    {actionType === "accept"
                      ? "Commission Not Accepted"
                      : "Commission Not Rejected"}
                  </h2>
                </Col>
              </Row>
              <Card
                className="simple-message-card"
                bordered={false}
                title="Something went wrong. Please contact the
                administrator."
              />
            </>
          )}
          {isAbleToView && operationSuccess && (
            <>
              <Row>
                <Col xl={24}>
                  <h2 className="page-title">
                    {actionType === "accept"
                      ? "Commission Accepted"
                      : "Commission Rejected"}
                  </h2>
                </Col>
              </Row>
              <Card
                className="simple-message-card"
                bordered={false}
                title={
                  actionType === "accept"
                    ? "Thank you for accepting the commission. We have sent further instructions to your email."
                    : "The commission is successfully rejected."
                }
              />
            </>
          )}
        </div>
      </Spin>
    );
  }
}

export default inject("CommissionStore")(observer(CommissionAcceptedRejected));
