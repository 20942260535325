import React, { useEffect, useState } from "react";
import { Card, Col, Icon, Row, Spin } from "antd";
import { inject, observer, Observer } from "mobx-react";
import "./styles.less";
import moment from "moment";
import EditableStatus from "../../common/EditableStatus";
import Button from "../../common/Button";
import ReadMore from "../../common/ReadMore";
import AccessControl from "../../models/AccessControl";
import goBackHandler from "../../helpers/goBackHandler";

function BatchPaymentDetails({
  BatchDetailsStore: store,
  history,
  BatchDetailsStore: { batchDetailsInfo },
  BatchDetailsStore: { loading },
  match
}) {
  const [canViewBatchPayment, setCanViewBatchPayment] = useState(false);
  const { paymentId } = match.params;
  useEffect(() => {
    async function checkAccess() {
      const isAbleToView = await AccessControl.can(
        "batch-payments:view-batch-payments"
      );
      setCanViewBatchPayment(isAbleToView);
      if (isAbleToView) {
        store.loadBatchDetailsInfo(paymentId);
        store.loadStatusOptions();
      }
      return isAbleToView;
    }
    checkAccess();
  }, [paymentId, store]);

  const handleStatusChange = statusId => {
    const params = {
      batch_payment_status_id: statusId
    };
    store.changeBatchPaymentStatus(batchDetailsInfo.id, params);
  };

  return (
    <>
      {canViewBatchPayment && (
        <div className="create-story-container batch-payment-details-wrapper">
          <div
            className="back-link"
            onClick={goBackHandler.bind(this, history, "batch-payments")}
          >
            <Icon type="arrow-left" />
            Back to the Batch Payments Lists
          </div>

          <Row>
            <Col xl={24}>
              <h2>Batch Payment ID {paymentId}</h2>
            </Col>
          </Row>

          <Spin spinning={loading}>
            <Card
              className="story-panel commission-details payment-details"
              bordered={false}
              title="Batch Information"
            >
              <Row className="batch-information">
                <Col span={6}>
                  <Row className="payment-details-row">
                    <div className="payment-details-row-title">ID</div>
                    <div className="payment-details-row-description">
                      {batchDetailsInfo.id}
                    </div>
                  </Row>
                  <Row className="payment-details-row">
                    <div className="payment-details-row-title">
                      Generated Date
                    </div>
                    <div className="payment-details-row-description">
                      {moment(
                        batchDetailsInfo.generated_date,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("DD.MM.YY, HH:mm")}
                    </div>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row className="payment-details-row">
                    <div className="payment-details-row-title">
                      Commissions in Batch
                    </div>
                    <div className="payment-details-row-description">
                      {batchDetailsInfo.commissions_in_batch_payment}
                    </div>
                  </Row>
                  <Row className="payment-details-row">
                    <div className="payment-details-row-title">Status</div>
                    <div className="payment-details-row-description">
                      <Observer>
                        {() => (
                          <EditableStatus
                            status={batchDetailsInfo.status}
                            availableStatuses={
                              store.statuses && store.statuses.select_options
                            }
                            onChange={handleStatusChange}
                            context="batch-payment"
                          />
                        )}
                      </Observer>
                    </div>
                  </Row>
                </Col>
                <Col span={6}>
                  <div className="payment-details-row-title">
                    Invoice Amounts
                  </div>
                  {batchDetailsInfo.invoice_amounts.map(invoiceAmount => {
                    return (
                      <div
                        className="payment-details-row-description"
                        key={invoiceAmount.name_currency}
                      >
                        {invoiceAmount.name_currency} {invoiceAmount.sum_amount}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Card>
          </Spin>

          <Spin spinning={loading}>
            <Card className="story-panel" bordered={false} title="Commissions">
              {batchDetailsInfo.commissions &&
                batchDetailsInfo.commissions.map((commission, i) => {
                  const typeKey = commission.task_type.name
                    .replace(/\s/g, "_")
                    .toLowerCase();
                  return (
                    <Row
                      key={i}
                      className="bordered payment-details-commissions-row"
                      gutter={20}
                      type="flex"
                    >
                      <Col lg={5} sm={24}>
                        <span className="label">Tasks Type</span>
                        <div className="description">
                          <div className="assignment-type">
                            <div className={`assignment-icon ${typeKey}`} />
                            <span />
                            <span className="task-type-name">
                              {commission.task_type.name}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} sm={24}>
                        <span className="label">Contributor</span>
                        <div className="description">
                          <div>
                            {commission.user_name}
                            {" /"}
                          </div>
                          <div>{commission.user_role.title}</div>
                        </div>
                      </Col>
                      <Col lg={7} sm={24}>
                        <span className="label">Task Brief</span>
                        <div className="description task-brief">
                          <ReadMore
                            text={commission.task_brief}
                            mode="cutEnd"
                            length={160}
                          />
                        </div>
                      </Col>
                      <Col lg={4} sm={24}>
                        <span className="label">Amount</span>
                        <div className="description">
                          {commission.amount_currency.name}{" "}
                          {commission.amount_sum}
                        </div>
                      </Col>
                      <Col lg={4} sm={24} className="align-right-lg">
                        <Button
                          text={`Commission ${commission.id}`}
                          size="small"
                          theme="black-outline"
                          htmlType="link"
                          href={`/assignment/101/commission/${commission.id}`}
                        />
                      </Col>
                    </Row>
                  );
                })}
            </Card>
          </Spin>
        </div>
      )}
      {!canViewBatchPayment && (
        // eslint-disable-next-line react/no-unescaped-entities
        <div>Sorry, you don't have the permission to view this page.</div>
      )}
    </>
  );
}

export default inject("BatchDetailsStore")(observer(BatchPaymentDetails));
