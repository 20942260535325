import { types } from "mobx-state-tree";
import Status from "../common/story-status";
import InvoiceAmounts from "./InvoiceAmounts";
import Commission from "./Commission";

const BatchDetailsInfo = types.model("BatchDetailsInfo", {
  id: types.optional(types.number, 0),
  generated_date: types.optional(types.string, ""),
  commissions_in_batch_payment: types.optional(types.number, 0),
  status: types.frozen(Status),
  invoice_amounts: types.array(InvoiceAmounts),
  commissions: types.array(Commission)
});

export default BatchDetailsInfo;
