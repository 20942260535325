import { types } from "mobx-state-tree";
import Brand from "../common/brand";
import StoryCategory from "./commissionData/StoryCategory";
import AssignmentType from "./commissionData/AssignmentType";
import ContributorUser from "./commissionData/ContributorUser";
import CreatorUser from "./commissionData/CreatorUser";
import CommissionStatus from "./commissionData/CommissionStatus";
// imports for edit and view
import ApproverUser from "./commissionInfo/ApproverUser";
import CommissionRight from "./commissionInfo/CommissionRight";
import Cost from "./commissionInfo/Cost";
import CostAllocation from "./commissionInfo/CostAllocation";
import Currency from "./metaData/Currency";
import Vendor from "./commissionInfo/Vendor";

const CommissionInfo = types.model("CommissionInfo", {
  id: types.maybeNull(types.number),
  creator_user: types.maybeNull(CreatorUser),
  contributor_user: types.maybeNull(ContributorUser),
  deadline: types.maybeNull(types.string),
  assignment_type: types.maybeNull(AssignmentType),
  brands: types.optional(types.array(Brand), []),
  story_name: types.maybeNull(types.string),
  story_id: types.maybeNull(types.number),
  story_category: types.optional(types.array(StoryCategory), []),
  commission_status: types.maybeNull(CommissionStatus),
  commission_status_id: types.maybeNull(types.number),
  terms_and_conditions: types.maybeNull(CommissionRight),
  currency_id: types.maybeNull(types.number),
  currency: types.optional(types.frozen(Currency), {}),
  vendor: types.maybe(Vendor),
  // special for edit and view
  approver_user: types.maybeNull(ApproverUser),
  approver_user_id: types.maybeNull(types.number),
  commission_right: types.maybeNull(CommissionRight),
  commission_right_id: types.maybeNull(types.number),
  special_terms: types.maybeNull(types.boolean),
  special_terms_description: types.maybeNull(types.string),
  special_terms_reuse: types.maybeNull(types.number),
  special_terms_reuse_delay: types.maybeNull(types.number),
  costs_description: types.maybeNull(types.string),
  costs: types.optional(types.array(Cost), []),
  cost_allocations: types.optional(types.array(CostAllocation), []),
  invoice_number: types.maybeNull(types.string),
  invoice_date: types.maybeNull(types.string)
});

export default CommissionInfo;
