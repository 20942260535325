import { types } from "mobx-state-tree";

const StoryAttachment = types.model("StoryAttachment", {
  id: types.number,
  user_id: types.number,
  model_id: types.number,
  filename: types.optional(types.string, ""),
  type: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  mime: types.optional(types.string, ""),
  url: types.optional(types.string, ""),
  extension: types.maybeNull(types.string),
  s3_link: types.maybeNull(types.string)
});

export default StoryAttachment;
