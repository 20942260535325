import { types } from "mobx-state-tree";
import TopCategory from "./TopCategory";
import StoryWithStatus from "./StoryWithStatus";
import ContentType from "./ContentType";

const Brand = types.model("Brand", {
  brand_id: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  icon_url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  commissions_total_count: types.maybeNull(types.number),
  commissions_total_price: types.maybeNull(types.frozen({})),
  top_categories: types.maybeNull(types.array(TopCategory)),
  content_types: types.maybeNull(types.array(ContentType)),
  stories_total: types.maybeNull(types.number),
  stories_with_statuses: types.maybeNull(types.array(StoryWithStatus)),
  updated_at: types.maybeNull(types.string),
  story_ideas: types.maybeNull(types.number),
  company_id: types.maybeNull(types.number),
  company_name: types.maybeNull(types.string)
});

export default Brand;
