import { types } from "mobx-state-tree";
import COALevel from "./COALevel";

const CostAllocation = types.model("CostAllocation", {
  id: types.optional(types.number, 0),
  commission_id: types.optional(types.number, 0),
  expense_type_id: types.optional(types.number, 0),
  percentage: types.optional(types.string, ""),
  amount: types.optional(types.string, ""),
  created_at: types.optional(types.string, ""),
  updated_at: types.optional(types.string, ""),
  coa_levels: types.optional(types.array(COALevel), [])
});

export default CostAllocation;
