import React from "react";
import "./styles.less";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import getCorrectUrl from "../../../../helpers/getCorrectUrl";
import { getColorForStoryStatus } from "../../../../helpers/getColorForStatus";

function BrandCard({
  brand,
  className,
  statuses,
  filtersRequestParams,
  ideaId
}) {
  const getParsedDate = () => {
    // we receive UTC time from the backend response
    return moment
      .utc(brand.updated_at, "MM-DD-YYYY HH:mm:ss") // we have to specify parse format to avoid bugs related to browser inconsistency
      .local()
      .fromNow();
  };

  const getStoriesCountByStatus = id => {
    return brand.stories_with_statuses.find(item => item.status_id === id)
      ?.count;
  };

  const renderStoriesClassificationByStatuses = () => {
    if (!brand.stories_with_statuses) {
      return null;
    }

    const statusesWithoutIdea = statuses.filter(status => status.id !== ideaId);

    const brandStatuses = brand.stories_with_statuses.reduce((acc, item) => {
      const foundStatus = statusesWithoutIdea.find(
        ({ id }) => item.status_id === id
      );
      if (foundStatus) {
        return [...acc, foundStatus];
      }
      return acc;
    }, []);

    return brandStatuses.map(({ id, slug, name, color_code }, i) => {
      const linkReference =
        filtersRequestParams.dateFrom && filtersRequestParams.dateTo
          ? `/story-list?brand=${brand.brand_id}&dateFrom=${filtersRequestParams.dateFrom}&dateTo=${filtersRequestParams.dateTo}&status=${id}`
          : `/story-list?brand=${brand.brand_id}&status=${id}&page=1`;
      return (
        <li key={`stories-with-status-${i}`}>
          <span className="line">
            <span className="title semi-bold-title">
              {slug === "no_status" ? (
                <div className="outer-circle">
                  <div
                    className="inner-circle"
                    style={{
                      backgroundColor:
                        color_code || getColorForStoryStatus(slug, "dot")
                    }}
                  />
                </div>
              ) : (
                <span
                  className="circle"
                  style={{
                    backgroundColor:
                      color_code || getColorForStoryStatus(slug, "dot")
                  }}
                />
              )}
              <span className="story-status-link">
                <Link to={linkReference}>{name}</Link>
              </span>
            </span>
            <span className="value">
              {brand.stories_with_statuses ? getStoriesCountByStatus(id) : 0}
            </span>
          </span>
        </li>
      );
    });
  };

  const renderTopCategoriesSection = () => {
    if (!brand.top_categories) {
      return null;
    }
    return brand.top_categories.map(({ name, count, category_id }, index) => {
      return (
        <div className="list-item-wrapper" key={index}>
          <Link
            to={`/story-list?brand=${brand.brand_id}&category=${category_id}&page=1`}
          >
            {name}
          </Link>
          <span className="amount">{count}</span>
        </div>
      );
    });
  };
  const renderContentTypesSection = () => {
    return brand.content_types?.map(
      ({ name, count, content_type_id: id }, index) => {
        const linkReference =
          filtersRequestParams.dateFrom && filtersRequestParams.dateTo
            ? `/story-list?brand=${brand.brand_id}&dateFrom=${filtersRequestParams.dateFrom}&dateTo=${filtersRequestParams.dateTo}&contentType=${id}&page=1`
            : `/story-list?brand=${brand.brand_id}&contentType=${id}&page=1`;
        return (
          <div className="list-item-wrapper" key={index}>
            <Link to={linkReference}>{name}</Link>
            <span className="amount">{count || 0}</span>
          </div>
        );
      }
    );
  };

  const renderCommissionPriceClassificationByCurrency = () => {
    if (!brand.commissions_total_price) {
      return null;
    }
    return Object.keys(brand.commissions_total_price).map((key, i) => {
      return (
        <li key={`commission-total-price-${i}`}>
          <span className="line">
            <span className="title">{key}</span>
            <span className="value">
              {new Intl.NumberFormat().format(
                brand.commissions_total_price[key].toFixed(3)
              )}
            </span>
          </span>
        </li>
      );
    });
  };

  const isCommissionTotalCount = () => {
    return brand.commissions_total_count;
  };

  const ideaLink = () => {
    return filtersRequestParams.dateFrom && filtersRequestParams.dateTo
      ? `/story-list?brand=${brand.brand_id}&dateFrom=${filtersRequestParams.dateFrom}&dateTo=${filtersRequestParams.dateTo}&status=${ideaId}`
      : `/story-list?brand=${brand.brand_id}&status=${ideaId}&page=1`;
  };

  return (
    <div className={`brand-card-container ${className}`}>
      <div className="brand-logo-and-title-container">
        <div className="brand-logo">
          <img src={getCorrectUrl(brand.icon_url)} alt="brand-icon" />
        </div>
        <div className="brand-title-and-type-container">
          <span className="company-name">{brand.company_name}</span>
          <span className="brand-title">
            <Link to={`/story-list?brand=${brand.brand_id}`}>{brand.name}</Link>
          </span>
          <span className="brand-type">{brand.type}</span>
        </div>
        <div className="last-update-section">
          <span
            style={brand.updated_at ? { display: "flex" } : { display: "none" }}
          >
            Last Update: &nbsp;
            {getParsedDate()}
          </span>
        </div>
      </div>
      <div className="brand-details">
        <div className="stories-classification-by-statuses">
          <ul key="stories-classification-by-statuses">
            <li key="total-stories">
              <span className="line">
                <span className="title">Total Stories</span>
                <span className="value bold-value">
                  {brand.stories_total ? brand.stories_total : 0}
                </span>
              </span>
            </li>
            <li key="story-ideas">
              <span
                style={
                  Object.entries(filtersRequestParams).length
                    ? { display: "none" }
                    : { display: "flex" }
                }
                className="line"
              >
                <span className="title semi-bold-title">
                  <Link to={ideaLink()}>Story Ideas</Link>
                </span>
                <span className="value">{brand.story_ideas}</span>
              </span>
            </li>
            {renderStoriesClassificationByStatuses()}
          </ul>
        </div>
        <div className="right-card-part-wrapper">
          <div className="top-categories-and-content-types-container">
            <Row
              gutter={{ sm: 20, xs: 0 }}
              justify="space-between"
              type="flex"
              className="card-row"
            >
              <Col span={11}>
                <span
                  style={
                    brand.top_categories
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  className="title column-title"
                >
                  Top 3 Categories
                </span>
                {renderTopCategoriesSection()}
              </Col>
              <Col span={11}>
                <span className="title column-title">Story Types</span>
                {renderContentTypesSection()}
              </Col>
            </Row>
          </div>

          <div className="commissions-details-container">
            <ul key="commissions-list">
              <li key="commissions-title">
                <span className="line">
                  <span className="title commissions-title">Commissions</span>
                  <span className="value bold-value commissions-value">
                    {isCommissionTotalCount() ? isCommissionTotalCount() : 0}
                  </span>
                </span>
              </li>
              <li key="commissions-total-title">
                <span className="line">
                  <span
                    style={
                      isCommissionTotalCount()
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    className="title commissions-title"
                  >
                    Commissions Totals
                  </span>
                  <span className="value">
                    <ul
                      className="commissions-totals-list"
                      key="commission-price-classification-by-currency"
                    >
                      {renderCommissionPriceClassificationByCurrency()}
                    </ul>
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject(stores => ({
  statuses: stores.DashboardStore.statuses,
  filtersRequestParams: stores.DashboardStore.filtersRequestParams,
  ideaId: stores.DashboardStore.ideaId
}))(observer(BrandCard));
