import { types } from "mobx-state-tree";

const Category = types.model("Category", {
  id: types.number,
  name: types.string,
  parent_id: types.maybeNull(types.number),
  parent: types.maybeNull(types.late(() => Category))
});

export default Category;

/*
category": {
          "id": 9,
          "parent_id": 4,
          "name": "Leslie Carter V",
          "parent": {
            "id": 4,
            "parent_id": null,
            "name": "Ole Prohaska"
          }
        },
 */
