import { flow, types } from "mobx-state-tree";
import moment from "moment";
import BatchPayment from "./BatchPayment";
import BatchPaymentService from "../../services/api/batch-payment";
import showMessage from "../../helpers/showMessage";

const BatchPaymentStore = types
  .model("BatchPaymentStore", {
    loadingBatchPayments: types.optional(types.boolean, false),
    creatingBatchPayments: types.optional(types.boolean, false),
    options: types.frozen({}),
    batchPayments: types.array(BatchPayment),
    batchPaymentId: types.maybeNull(types.number),
    currentPage: types.optional(types.number, 1),
    perPage: types.optional(types.number, 15),
    total: types.optional(types.number, 0),
    filtersRequestParams: types.optional(types.frozen(), {}),
    filtersInitialValues: types.optional(types.frozen(), {})
  })
  .actions(self => {
    return {
      afterCreate() {
        this.setAppliedFilters();
      },
      setAppliedFilters: function setAppliedFilters() {
        const searchParams = new URLSearchParams(window.location.search);
        const filtersInitialValues = {};
        filtersInitialValues.date = [];
        filtersInitialValues.sorting = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          switch (key) {
            case "dateFrom":
              filtersInitialValues.date.push(moment(value));
              break;
            case "dateTo":
              filtersInitialValues.date.push(moment(value));
              break;
            case "sortByDesc":
              filtersInitialValues.sorting[value] = "descend";
              break;
            case "sortByAsc":
              filtersInitialValues.sorting[value] = "ascend";
              break;
            default:
              if (Number(value)) {
                filtersInitialValues[key] = [Number(value)];
              } else {
                filtersInitialValues[key] = value.split(",");
              }
              break;
          }
        });
        self.filtersInitialValues = filtersInitialValues;
        const filtersRequestParams = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          filtersRequestParams[key] = value;
        });
        self.filtersRequestParams = filtersRequestParams;
      },
      loadFilters: flow(function* loadFilters() {
        try {
          const response = yield BatchPaymentService.getMetaData();
          self.options = response;
          return Promise.resolve();
        } catch (error) {
          console.log("Error: ", error);
          return Promise.reject();
        }
      }),
      loadBatchPayments: flow(function* loadBatchPayments(params) {
        self.loadingBatchPayments = true;
        try {
          const response = yield BatchPaymentService.getBatchPayments(params);
          const { data, current_page, per_page, total } = response;
          self.batchPayments = data;
          self.currentPage = current_page;
          self.perPage = per_page;
          self.total = total;
          self.loadingBatchPayments = false;
          return Promise.resolve();
        } catch (error) {
          console.error("Error: ", error);
          self.loadingBatchPayments = false;
          return Promise.reject();
        }
      }),
      createNewBatch: flow(function* createNewBatch() {
        self.creatingBatchPayments = true;
        try {
          const response = yield BatchPaymentService.createNewBatch();
          if (!response.status) {
            showMessage(response);
            self.creatingBatchPayments = false;
            return Promise.reject();
          }
          const {
            entity: { id },
            metadata
          } = response;
          self.batchPaymentId = id;
          showMessage(metadata);
          self.creatingBatchPayments = false;
          return Promise.resolve();
        } catch (error) {
          console.error("Error: ", error);
          self.creatingBatchPayments = false;
          return Promise.reject();
        }
      })
    };
  })
  .views(self => ({
    get loading() {
      return self.loadingBatchPayments || self.creatingBatchPayments;
    }
  }));

const batchPaymentStore = BatchPaymentStore.create({
  options: {},
  batchPayments: [],
  batchPaymentId: null,
  currentPage: 1,
  perPage: 15,
  total: 0,
  filtersRequestParams: {},
  filtersInitialValues: { sorting: {} },
  loadingBatchPayments: false,
  creatingBatchPayments: false
});

export default batchPaymentStore;
