import React from "react";
import { Card, Col, Row, Spin } from "antd";
import { observer } from "mobx-react";
import userProfileStore from "../../models/user/UserProfileStore";

function Details({ UserProfileStore: { details, loading }, UserProfileStore }) {
  return (
    <Spin spinning={loading}>
      <Card className="story-panel" bordered={false} title="Details">
        <Row gutter={20} type="flex" className="profile-details-row">
          <Col lg={8} xs={24}>
            <div className="label-description-wrapper">
              <span className="label">UserType</span>
              <div className="description">{details[`User Type`]}</div>
            </div>
            <div className="label-description-wrapper">
              <span className="label">Additional Permissions</span>
              <div className="description">
                {UserProfileStore.additionalPermissions}
              </div>
            </div>
          </Col>
          <Col lg={8} xs={24}>
            <span className="label">Brand Access</span>
            <div className="description">
              {(details[`Brand Access`].length &&
                details[`Brand Access`].map(brand => {
                  return (
                    <div className="brand-wrapper" key={brand.name}>
                      <div className="brand-icon">
                        <img
                          width={24}
                          height={24}
                          src={brand.icon_url}
                          alt="brand-icon"
                        />
                      </div>
                      <span>{brand.name}</span>
                    </div>
                  );
                })) ||
                "No information"}
            </div>
          </Col>
          <Col lg={8} xs={24}>
            <div className="label-description-wrapper">
              <span className="label">Job Role</span>
              <div className="description">{userProfileStore.jobRole}</div>
            </div>
            <div className="label-description-wrapper">
              <span className="label">Job Title</span>
              <div className="description">{details[`Job Title`]}</div>
            </div>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
}

export default observer(Details);
