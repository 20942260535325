import React from "react";
import { Form, Icon, Input } from "antd";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import FooterContainer from "../Footer";
import AuthLogotype from "../../common/AuthLogotype";
import withErrorHandler from "../../hoc/withErrorHandler";
import Button from "../../common/Button";
import "./styles.less";

class ResetPasswordPage extends React.Component {
  submitHandler = event => {
    event.preventDefault();
    const {
      form: { validateFields },
      AuthStore
    } = this.props;
    validateFields((error, values) => {
      if (!error) {
        AuthStore.resetPassword(values);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      AuthStore: { loading }
    } = this.props;
    return (
      <div className="reset-password-page-wrapper">
        <div className="reset-password-page-container">
          <AuthLogotype />
          <Form
            className="reset-password-form"
            onSubmit={this.submitHandler}
            hideRequiredMark
          >
            <Link to="/login" className="back-to-login-link">
              <Icon type="arrow-left" />
              Back to Login
            </Link>
            <div className="reset-password-form-title">
              <h2>Forgot your password?</h2>
            </div>
            <Form.Item label="Email Address">
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The entered value isn't valid email"
                  },
                  {
                    required: true,
                    message: "Please input your email address"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Button
              htmlType="submit"
              text="Reset Password"
              size="large"
              onClick={this.submitHandler}
              disabled={loading}
            />
          </Form>
        </div>
        <FooterContainer />
      </div>
    );
  }
}

export default Form.create()(
  inject("AuthStore")(withErrorHandler(observer(ResetPasswordPage)))
);
