import { flow, types } from "mobx-state-tree";
import moment from "moment";
import StoryService from "../../services/api/stories";
import Story from "./Story";
import Filter, { createFilter } from "../common/filter";
import {
  toOptionArray,
  toTreeSelectOptionsWithoutDisabled
} from "../../helpers/array-utils";

const StoryListStore = types
  .model("StoryListStore", {
    loadingStories: types.optional(types.boolean, false),
    loadingFilters: types.optional(types.boolean, false),
    stories: types.array(Story),
    filtersInitialValues: types.optional(types.frozen(), {}),
    filtersRequestParams: types.optional(types.frozen(), {}),
    total: types.optional(types.number, 0),
    perPage: types.optional(types.number, 15),
    filters: types.optional(types.map(Filter), {}),
    currentPageNumber: types.optional(types.number, 1)
  })
  .views(self => ({
    get hasLoadedFilterOption() {
      return !!self.filters.size;
    }
  }))
  .actions(self => {
    return {
      afterCreate() {
        this.setAppliedFilters();
      },
      setAppliedFilters: function setAppliedFilters() {
        const searchParams = new URLSearchParams(window.location.search);
        // initial values
        const filtersInitialValues = {};
        filtersInitialValues.date = [];
        filtersInitialValues.linkedStories = [];
        filtersInitialValues.sorting = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          switch (key) {
            case "dateFrom":
              filtersInitialValues.date.push(moment(value));
              break;
            case "dateTo":
              filtersInitialValues.date.push(moment(value));
              break;
            case "sortByDesc":
              filtersInitialValues.sorting[value] = "descend";
              break;
            case "sortByAsc":
              filtersInitialValues.sorting[value] = "ascend";
              break;
            case "searchCommon":
              filtersInitialValues[key] = value.split(",");
              break;
            default:
              if (Number(value)) {
                filtersInitialValues[key] = [Number(value)];
              } else {
                filtersInitialValues[key] = value
                  .split(",")
                  .map(val => Number(val));
              }
              break;
          }
        });
        self.filtersInitialValues = filtersInitialValues;

        // request params
        const filtersRequestParams = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          filtersRequestParams[key] = value;
        });
        self.filtersRequestParams = filtersRequestParams;
      },

      loadStories: flow(function* loadStories(params) {
        self.loadingStories = true;
        try {
          const responseData = yield StoryService.getStories(params);
          const { data, total, current_page, per_page } = responseData;
          self.stories = data;
          self.total = total;
          self.currentPageNumber = current_page;
          self.perPage = per_page;
          self.loadingStories = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingStories = false;
        }
      }),

      loadFilters: flow(function* loadFilters() {
        self.loadingFilters = true;
        try {
          const response = yield StoryService.getDataForStoryListFilters();
          self.filters.set(
            "companies",
            createFilter("Company", toOptionArray(response.companies))
          );
          self.filters.set(
            "brands",
            createFilter("Brand", toOptionArray(response.brands, "company_id"))
          );
          self.filters.set(
            "platforms",
            createFilter("Platform Type", toOptionArray(response.platformTypes))
          );
          self.filters.set(
            "categories",
            createFilter(
              "Category / Sub-Category",
              toTreeSelectOptionsWithoutDisabled(response.categories)
            )
          );
          self.filters.set(
            "publishTypes",
            createFilter("Schedule", toOptionArray(response.publishTypes))
          );
          self.filters.set(
            "content_types",
            createFilter(
              "Story Type",
              toOptionArray(response.contentTypes, "company_id")
            )
          );
          self.filters.set(
            "statuses",
            createFilter(
              "Status",
              toOptionArray(response.statuses, "company_id"),
              response.statuses
            )
          );
          self.filters.set(
            "links",
            createFilter(
              "Linked Stories",
              toOptionArray(response.links),
              response.links
            )
          );

          self.loadingFilters = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingFilters = false;
        }
      })
    };
  })
  .views(self => ({
    get statusPatch() {
      return statusId => {
        const statusFilter = self.filters.get("statuses");
        const { raw_values } = statusFilter;
        return raw_values.find(statusEntry => statusEntry.id === statusId);
      };
    }
  }));

const storyListStore = StoryListStore.create({
  loadingStories: false,
  loadingFilters: false,
  stories: [],
  filters: {},
  filtersInitialValues: {},
  filtersRequestParams: {},
  total: 0,
  perPage: 15,
  currentPageNumber: 1
});

export default storyListStore;
