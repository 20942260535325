import React from "react";
import "./styles.less";
import PropTypes from "prop-types";
import { Form, Icon, Input } from "antd";
import { observer } from "mobx-react";
import { ReactComponent as Pencil } from "../../images/icons/pencil.svg";
import Button from "../Button";

class EditableField extends React.Component {
  state = {
    inEdit: false
  };

  iconClickHandler = event => {
    const { inEdit } = this.state;
    const { form } = this.props;
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        inEdit: !inEdit
      },
      () => {
        form.validateFields();
      }
    );
  };

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  handleSubmit = e => {
    const { onChange, form } = this.props;
    const { inEdit } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          inEdit: !inEdit
        });
        onChange(values);
      }
    });
  };

  handleCancel = () => {
    const { inEdit } = this.state;
    this.setState({
      inEdit: !inEdit
    });
  };

  render() {
    const { text, rules, form, fieldClassName } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = form;
    const { inEdit } = this.state;
    const fieldError = isFieldTouched("field") && getFieldError("field");

    return (
      <>
        {inEdit && (
          <Form
            layout="inline"
            onSubmit={this.handleSubmit}
            className="custom-editable-form-wrapper"
          >
            <Form.Item
              validateStatus={fieldError ? "error" : ""}
              help={fieldError || ""}
              className={fieldClassName}
            >
              {getFieldDecorator("field", {
                rules,
                initialValue: text
              })(<Input placeholder="email" />)}
            </Form.Item>
            <Form.Item>
              <Button
                text="Save"
                htmlType="submit"
                disabled={this.hasErrors(getFieldsError())}
                theme="solid-blue"
                size="small"
              />
            </Form.Item>
            <Form.Item>
              <Button
                text="Cancel"
                htmlType="button"
                onClick={this.handleCancel}
                theme="blue-outline"
                size="small"
              />
            </Form.Item>
          </Form>
        )}
        {!inEdit && (
          <div className="custom-editable-field-wrapper">
            <div className="description">{text}</div>
            <Icon
              className="hoverable"
              onClick={this.iconClickHandler}
              component={Pencil}
            />
          </div>
        )}
      </>
    );
  }
}

EditableField.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rules: PropTypes.arrayOf(PropTypes.object),
  fieldClassName: PropTypes.string
};

EditableField.defaultProps = {
  rules: [],
  fieldClassName: ""
};

export default Form.create()(observer(EditableField));
