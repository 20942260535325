import { types } from "mobx-state-tree";

const Action = types.model("Action", {
  id: types.optional(types.number, 0),
  user_type: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
  event: types.optional(types.string, ""),
  auditable_type: types.optional(types.string, ""),
  auditable_id: types.optional(types.number, 0),
  origin_old_values: types.optional(types.string, ""),
  origin_new_values: types.optional(types.string, ""),
  url: types.optional(types.string, ""),
  ip_address: types.optional(types.string, ""),
  user_agent: types.optional(types.string, ""),
  tags: types.maybeNull(types.string),
  created_at: types.optional(types.string, ""),
  updated_at: types.optional(types.string, ""),
  user_email: types.maybeNull(types.string)
});

export default Action;
