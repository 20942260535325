import { types } from "mobx-state-tree";
import moment from "moment";
import MentionUser from "./MentionUser";

const ChatMessage = types
  .model("ChatMessage", {
    id: types.number,
    user: types.frozen(MentionUser),
    message: types.string,
    created_at: types.string,
    mention_users: types.array(MentionUser),
    first_page_url: types.optional(types.string, ""),
    from: types.optional(types.number, 0),
    last_page: types.optional(types.number, 0),
    last_page_url: types.maybeNull(types.string),
    next_page_url: types.maybeNull(types.string),
    path: types.maybeNull(types.string),
    per_page: types.optional(types.number, 0),
    prev_page_url: types.maybeNull(types.string),
    to: types.optional(types.number, 0),
    total: types.optional(types.number, 0)
  })
  .views(self => ({
    get date() {
      return (
        moment
          .utc(self.created_at, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("DD-MM-YYYY LT") || ""
      );
    }
  }));

export default ChatMessage;
