import AuthStore from "./AuthStore";
import DashboardStore from "./dashboard/DashboardStore";
import BatchPaymentStore from "./batch-payments/BatchPaymentStore";
import StoryListStore from "./story/StoryListStore";
import StoryViewStore from "./story/StoryViewStore";
import StoryEditStore from "./story/StoryEditStore";
import PlatformsModal from "./modals/platformsModal";
import ManageContentStore from "./content/ManageContentStore";
import UserProfileStore from "./user/UserProfileStore";
import { actionLogStore as ActionLogStore } from "./action-log/ActionLogStore";
import LogDetailsStore from "./log-details/LogDetailsStore";
import CommissionStore from "./commission/CommissionStore";
import CommissionViewStore from "./commission/CommissionViewStore";
import SettingsStore from "./settings/SettingsStore";
import { batchDetailsStore as BatchDetailsStore } from "./batchDetails/BatchDetailsStore";
import TasksListStore from "./tasks-list/TasksListStore";

export default {
  AuthStore,
  DashboardStore,
  BatchPaymentStore,
  StoryListStore,
  StoryViewStore,
  ManageContentStore,
  UserProfileStore,
  StoryEditStore,
  ActionLogStore,
  LogDetailsStore,
  CommissionStore,
  CommissionViewStore,
  SettingsStore,
  BatchDetailsStore,
  TasksListStore,
  PlatformsModal
};
